<template>
  <a-form :form="form">
    <mcma-card title="Taux Réduit TICGN" class="mcma-card-in-tab transparent-bg">
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="12"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="switchExonerationTicgn"
          getFlow="name::sites"
          getPanel="name::Données techniques (gaz)"
          getField="type::switchExonerationTicgn"
          :defaultValue="site.switchExonerationTicgn"
        />
        <mcma-field
          v-if="form.getFieldValue('switchExonerationTicgn')"
          :colSpan="6"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          extraLabel="A renseigner uniquement en cas de taux réduit (indiquer la valeur applicable en €/MWh)"
          fieldName="exonerationTicgn"
          getFlow="name::sites"
          getPanel="name::Données techniques (gaz)"
          getField="type::exonerationTicgn"
          :defaultValue="site.exonerationTicgn ? site.exonerationTicgn.toString() : null"
          :required="form.getFieldValue('switchExonerationTicgn')"
        />
        <div>
          <div v-if="form.getFieldValue('switchExonerationTicgn')" class="upload-box">
            <mcma-button
              :colSpan="12"
              name="Téléversement du Cerfa"
              :color="this.site.cerfa || this.checkCerfa || cerfaUploadedFile ? 'success' : 'purple'"
              :leftIcon="this.site.cerfa || this.checkCerfa || cerfaUploadedFile ? 'check' : 'upload'"
              :isFaIcon="true"
              @click="$refs.documentUploaded.click()"
              :required="true"
            />
            <div class="file-name">
              {{ cerfaUploadedFile ? cerfaUploadedFile.name : "" }}
            </div>
            <input class="ninja" type="file" ref="documentUploaded" @change="processFile" />
          </div>
          <legend v-if="!(this.checkCerfa || cerfaUploadedFile) && form.getFieldValue('switchExonerationTicgn')">
            Veuillez téléverser votre Cerfa au format PDF.
          </legend>
        </div>
      </a-row>
    </mcma-card>
    <a-row type="flex" :gutter="12" class="mt-0 mb-0">
      <mcma-field
        :colSpan="24"
        type="switch"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="bioGaz"
        getFlow="name::sites"
        getPanel="name::Données techniques (gaz)"
        getField="type::bioGaz"
        :defaultValue="site.bioGaz"
        :switchColors="['green', 'red']"
      />
      <mcma-field
        :colSpan="24"
        type="select"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="bioGazValue"
        getFlow="name::sites"
        getPanel="name::Données techniques (gaz)"
        getField="type::bioGazList"
        :disabled="activeENRUnique"
        :defaultValue="defaultValueEnr || 0"
      />
    </a-row>

    <mcma-card title="Option Tarifaire, Profil et CAR" class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12">
        <a-col :span="12">
          <div class="field-block-title">Carac. tech. actuelles</div>
          <a-row type="flex" :gutter="12">
            <a-col :span="24">
              <div class="field-list">
                <div class="field-item">
                  <span class="field-label">Option Tarifaire Initial</span>
                  <span class="field-value">{{ $getValueByKey(site.optionTarifaireInitial, "non renseigné") }}</span>
                </div>
                <div class="field-item">
                  <span class="field-label">Profil Initial</span>
                  <span class="field-value">{{ $getValueByKey(site.profilInitial, "non renseigné") }}</span>
                </div>
                <div class="field-item">
                  <span class="field-label">CAR (kWh) Initial</span>
                  <span class="field-value">{{ $getValueByKey(site.carInitial, "non renseigné") }}</span>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12" v-if="showCarcTech">
          <div class="field-block-title">Carac. tech. qui seront mises en place</div>
          <a-row type="flex" :gutter="12" class="mtb4">
            <mcma-field
              :colSpan="24"
              type="select"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="optionTarifaire"
              getFlow="name::sites"
              getPanel="name::Données techniques (gaz)"
              getField="type::optionTarifaire"
              :defaultValue="site.optionTarifaire"
            />
            <mcma-field
              :colSpan="24"
              type="select"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="profil"
              getFlow="name::sites"
              getPanel="name::Données techniques (gaz)"
              getField="type::profil"
              :defaultValue="site.profil"
            />
          </a-row>
          <a-row type="flex" :gutter="12" class="mtb4">
            <mcma-field
              :colSpan="form.getFieldValue('optionTarifaire') === 'T_4' ? 12 : 24"
              type="input"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="car"
              :defaultValue="site.car ? site.car.toString() : null"
              label="CAR (kWh)"
              getFlow="name::sites"
              getPanel="name::Données techniques (gaz)"
              getField="type::car"
              :required="false"
              numberOnly="decimal"
            />
            <mcma-field
              v-if="form.getFieldValue('optionTarifaire') === 'T_4'"
              :colSpan="12"
              type="input"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="cja"
              :defaultValue="site.cja ? site.cja.toString() : null"
              label="CJA (MWh)"
              :required="true"
              numberOnly="decimal"
            />
          </a-row>
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="12" class="mt0">
        <mcma-field
          :colSpan="24"
          type="textarea"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="commentaire"
          :height="120"
          label="Commentaires"
        />
      </a-row>
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <div class="button-group-inline">
            <mcma-button
              name="Réinitialiser"
              color="primary"
              leftIcon="undo-alt"
              :isFaIcon="true"
              @click="initialize"
            />
            <mcma-button
              name="Valider les informations"
              class="ml12"
              color="success"
              leftIcon="save"
              :isFaIcon="true"
              :disabled="!canUpdate(form.getFieldsValue()) && showCarcTech"
              @click="submit"
            />
          </div>
        </a-col>
      </a-row>
    </mcma-card>
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import { mapState } from "vuex"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import { RV_RESULT, RV_GAZ } from "./RG_GAZ"

export default {
  name: "etape_2_gaz",
  props: ["site"],
  components: {
    McmaCard,
    McmaField,
    McmaButton,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      cerfaUploadedFile: null,
      checkCerfa: null,
      cerfaUploaded: false,
    }
  },
  computed: {
    ...mapState(["operation", "membre"]),
    canUpdate() {
      return (fieldsValue) => {
        return !!(
          fieldsValue.optionTarifaire &&
          fieldsValue.profil &&
          fieldsValue.car &&
          (fieldsValue.optionTarifaire === "T_4" ? fieldsValue.cja : true)
        )
      }
    },
    showCarcTech() {
      return this.showCarcTechM()
    },

    activeENRUnique() {
      const enrUniqueSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Localisation",
        "type::enrUniqueSite"
      )
      return enrUniqueSite ? enrUniqueSite.displayed : false
    },
    defaultValueEnr() {
      let enr = 0
      enr = this.activeENRUnique && this.membre.enrUniqueSite ? this.membre.enrUniqueSite : this.site.bioGazValue
      return enr
    },
  },
  methods: {
    processFile(event) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const fileCerfa = event.target.files[0]

      if (fileCerfa.size > sizeMax) {
        this.$message.warning(`Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`)
      } else if (fileCerfa.type !== "application/pdf") {
        this.$message.error(`Le fichier n'est pas au format PDF`)
      } else {
        this.cerfaUploadedFile = fileCerfa
        this.checkCerfa = true
      }
    },
    addCerfa() {
      if (this.checkCerfa && this.cerfaUploadedFile) {
        const formData = new FormData()
        formData.append("file", this.cerfaUploadedFile)
        this.cerfaUploaded = true
        Axios(
          "post",
          "/file/operation/" + this.operation.id + "/membre/" + this.membre.id + "/perimetrefacturation/piece",
          formData,
          null,
          {
            "Content-Type": "multipart/form-data",
          }
        ).catch((error) => {
          console.error(error)
          this.$notification.error({
            message: `Erreur lors du téléversement de l'iban`,
            description: `Une erreur s'est produite lors du téléversement de la pièce, veuillez contacter un administrateur.`,
          })
          this.cerfaUploaded = false
        })
      }
    },
    showCarcTechM() {
      const typeOption = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (gaz)",
        "type::optionTarifaire"
      )
      const typeProfil = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (gaz)",
        "type::profil"
      )
      const typeCar = getDynamiqueFields(this.operation, "name::sites", "name::Données techniques (gaz)", "type::car")
      return typeOption && typeProfil && typeCar
        ? typeOption.displayed || typeProfil.displayed || typeCar.displayed
        : true
    },
    initialize() {
      this.form.getFieldDecorator("bioGaz")
      this.form.getFieldDecorator("pce")
      this.form.getFieldDecorator("optionTarifaire")
      this.form.getFieldDecorator("profil")
      this.form.getFieldDecorator("car")
      this.form.getFieldDecorator("cja")
      this.form.getFieldDecorator("commentaire")
      this.form.setFieldsValue({
        bioGaz: this.site.bioGaz,
        optionTarifaire: this.site.optionTarifaire,
        profil: this.site.profil,
        car: this.site.car ? this.site.car.toString() : null,
        cja: this.site.cja ? this.site.cja.toString() : null,
        commentaire: this.site.commentaire,
      })
    },
    submit() {
      this.form.validateFields((errors, fieldsValue) => {
        if (!this.showCarcTechM()) {
          this.process(fieldsValue)
        } else {
          if (errors || !this.canUpdate(fieldsValue)) {
            this.$notification.warning({
              message: "Formulaire invalide",
              description: `Veuillez-vous assurez de remplir tous les champs requis.`,
            })
          } else {
            this.validate(fieldsValue)
          }
        }
      })
      // console.groupEnd()
    },
    validate(fieldsValue) {
      const errors = []
      const derogables = []
      let valid = true

      for (const rv in RV_GAZ) {
        const result = RV_GAZ[rv](fieldsValue.optionTarifaire, fieldsValue.profil)
        if (result.state === RV_RESULT.KO) {
          errors.push(result.message)
          valid = false
        } else if (result.state === RV_RESULT.DEROGABLE) {
          derogables.push(result.message)
        }
      }

      if (valid && derogables.length) {
        let message = "Les paramètres de votre site ne respectent pas les règles suivantes :\n\n"
        for (const derogable of derogables) {
          message += "  - " + derogable + "\n"
        }
        message += "\nVoulez-vous vraiment déroger à ces règles?"

        this.confirmDerogation(message, fieldsValue)
      } else if (!valid && errors.length) {
        this.$notification.warning({
          message: "Formulaire invalide",
          description: `Veuillez-vous assurez de remplir tous les champs requis.`,
        })
      } else {
        this.process(fieldsValue)
      }
    },

    confirmDerogation(message, fieldsValue) {
      const that = this
      this.$confirm({
        title: `Déroger à la règle ?`,
        content: message,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          that.process(fieldsValue)
        },
        onCancel() {
          console.log(
            `%c Cancel dérogation rule`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
          )
          that.$notification.warning({
            message: "Correction des règles",
            description: `Veuillez corriger les règles non respecté pour finir l'operation correctement.`,
          })
        },
      })
    },
    process(fieldsValue) {
      let exonerationTicgn = fieldsValue.exonerationTicgn
      if (fieldsValue.switchExonerationTicgn === true) {
        if (fieldsValue.exonerationTicgn && fieldsValue.exonerationTicgn.includes(",")) {
          exonerationTicgn = exonerationTicgn.replace(",", ".")
        }
      } else {
        exonerationTicgn = null
      }
      this.addCerfa()
      const cerfaValidate =
        fieldsValue.switchExonerationTicgn && (this.cerfaUploaded || this.site.cerfa)
          ? true
          : !fieldsValue.switchExonerationTicgn
          ? true
          : false
      if (cerfaValidate) {
        Axios("patch", "api/sites/" + this.site.id, {
          switchExonerationTicgn: fieldsValue.switchExonerationTicgn,
          exonerationTicgn: exonerationTicgn,
          cerfa: this.cerfaUploaded === false ? this.site.cerfa : this.cerfaUploaded,
          ongletParametre: true,
          bioGaz: fieldsValue.bioGaz,
          bioGazValue: fieldsValue.bioGazValue,
          pce: fieldsValue.pce,
          optionTarifaire: fieldsValue.optionTarifaire,
          profil: fieldsValue.profil,
          car: fieldsValue.car,
          cja: fieldsValue.optionTarifaire === "T_4" ? fieldsValue.cja : null,
          commentaire: fieldsValue.commentaire,
        })
          .then((response) => {
            this.$emit("save", response.data.id)
            this.$emit("update", response.data)
            this.$notification.success({
              message: "Paramètres sauvegardées !",
              description: `Les paramètres ont bien été sauvegardées`,
            })
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: "Erreur serveur",
              description: "Une erreur server est survenue, veuillez contacter un administrateur.",
            })
          })
      } else {
        this.$notification.warning({
          message: "Formulaire invalide",
          description: `Veuillez-vous assurez d'ajouter le cerfa (en cas de réduction TICGN) ainsi que de remplir tous les champs requis.`,
        })
      }
    },
  },
  watch: {
    site() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";
.upload-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 45vw;
  margin: 20px 0;
  padding-right: 10px;
  border-radius: 4px;
  background: rgba($grey, 0.1);
  border: 1px solid rgba($grey, 0.3);
  transition: all 0.3s ease;
  .file-name {
    color: $grey;
  }
  &.has-file {
    background: hsla(var(--success-color-h), var(--success-color-s), var(--success-color-l), 0.1);
    .file-name {
      color: var(--text-color, $text-color);
    }
  }
}
</style>
