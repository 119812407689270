<template>
  <main>
    <place-holder>
      <section class="container" v-if="selected">
        <div class="row">
          <div class="col s12">
            <div class="card">
              <article class="card-content">
                <span class="card-title"> Lien membre / Sites </span>
                <blockquote>
                  Attribuez massivement vos sites au membre "{{ selected.nom }}" via le bouton
                  <i class="material-icons light-green-text">add</i>. Vous pouvez aussi les détacher via le bouton
                  <i class="material-icons deep-orange-text">remove</i>
                </blockquote>
              </article>
              <article class="card-action center-align">
                <button
                  type="button"
                  class="waves-effect waves-light btn light-green"
                  @click="submit"
                  id="editMemberLinksValidSites"
                >
                  <i class="material-icons">save</i>
                  Valider
                </button>

                <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
                  <i class="material-icons">remove</i>
                  Réinitialiser
                </button>
              </article>
            </div>
          </div>
        </div>
      </section>
    </place-holder>

    <section class="row">
      <article class="col s6">
        <place-holder :notReady="!sites" id="editMemberLinksSitesToAdd">
          <grid
            v-if="sites"
            name="Sites disponibles"
            :rows="sites"
            :columns="['rae', 'nom', 'membreNom', 'status', 'removed', 'Actions']"
            :search="true"
            :columnHeader="{
              rae: 'RAE/PCE',
              nom: 'Nom',
              membreNom: 'Membre',
              removed: 'Désactivé',
              status: 'Statut',
            }"
            :columnsNoSearch="['Actions']"
            :multiSelect="true"
            @select="(value) => (sitesSelected = value)"
          >
            <template slot="header">
              <a
                href
                @click.prevent="add(sitesSelected)"
                class="waves-effect waves-light btn light-green"
                id="editMemberLinksSiteCheck"
              >
                <i class="material-icons">add</i>
                Ajouter les lignes sélectionnées.
              </a>
            </template>

            <center slot="Actions" slot-scope="props">
              <a href @click.prevent="add([props.row])" class="editMemberLinksSiteAdd">
                <i class="material-icons light-green-text">add</i>
              </a>
            </center>
          </grid>
        </place-holder>
      </article>
      <article class="col s6">
        <place-holder :notReady="!sitesGrouped" id="editMemberLinksSitesToRemove">
          <grid
            v-if="sitesGrouped"
            name="Sites associés"
            :rows="sitesGrouped"
            :columns="['rae', 'nom', 'membreNom', 'status', 'removed', 'Actions']"
            :search="true"
            :columnHeader="{
              rae: 'RAE/PCE',
              nom: 'Nom',
              membreNom: 'Membre',
              removed: 'Désactivé',
              status: 'Statut',
            }"
            :columnsNoSearch="['Actions']"
            :multiSelect="true"
            @select="(value) => (sitesGroupedSelected = value)"
          >
            <template slot="header">
              <a
                href
                @click.prevent="remove(sitesGroupedSelected)"
                class="waves-effect waves-light btn deep-orange"
                id="editMemberLinksSiteRemCheck"
              >
                <i class="material-icons">remove</i>
                Supprimer les lignes sélectionnées.
              </a>
            </template>

            <template slot="Actions" slot-scope="props">
              <center>
                <a href @click.prevent="remove([props.row])" class="editMemberLinksSiteRem">
                  <i class="material-icons deep-orange-text">remove</i>
                </a>
              </center>
            </template>
          </grid>
        </place-holder>
      </article>
    </section>
  </main>
</template>

<script>
import Axios from "@/util/Axios"
import { mapState, mapMutations, mapActions } from "vuex"
import _ from "lodash"
import Grid from "@/components/common/Grid"
import Materialize from "materialize-css"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "site-link",
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      selected: null,

      sites: null,
      sitesSelected: [],

      sitesGroupedInitial: null,
      sitesGrouped: null,
      sitesGroupedSelected: [],
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    ...mapState(["user", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["requestMembreReconcil"]),
    add(sites) {
      sites.forEach((site) => {
        site.selected = false
      })
      this.sites = [..._.pullAll(this.sites, sites)]
      this.sitesGrouped.push(...sites)
      if (sites === this.sitesSelected) {
        this.sitesSelected = []
      } else {
        this.sitesGroupedSelected = [..._.pullAll(this.sitesSelected, sites)]
      }
    },
    remove(sites) {
      sites.forEach((site) => {
        site.selected = false
      })
      this.sitesGrouped = [..._.pullAll(this.sitesGrouped, sites)]
      this.sites.push(...sites)
      if (sites === this.sitesGroupedSelected) {
        this.sitesGroupedSelected = []
      } else {
        this.sitesSelected = [..._.pullAll(this.sitesGroupedSelected, sites)]
      }
    },
    initialize() {
      console.group("initialize@indexSiteLink")
      this.selected = null
      Axios("get", "api/membres/" + this.$route.params.id).then((response) => {
        this.selected = response.data
        this.setBreadcrumbAdd({
          name: "Liens pour : " + this.selected.nom,
          action: () => {},
        })
      })
      Axios("get", "api/sites/search/findByOperationIdEquals?operationId=" + this.operation.id).then((response) => {
        console.log("findByOperationIdEquals")
        console.log(response)
        this.sites = _.filter(_.flatMap(response.data._embedded, (value) => value))
        this.recadrage()
      })
      Axios("get", "api/membres/" + this.$route.params.id + "/sites").then((response) => {
        this.sitesGrouped = _.filter(_.flatMap(response.data._embedded, (value) => value))

        this.sitesGroupedInitial = _.map(this.sitesGrouped, (site) => {
          return site.id
        })
        this.recadrage()
      })
      console.groupEnd()
    },
    recadrage() {
      if (this.sites && this.sitesGrouped) {
        this.sites = _.differenceBy(this.sites, this.sitesGrouped, "id")
      }
    },
    async submit() {
      //On récupère le membre de l'opé 'A_RECONCILIER' pour lier les sites déliés à celui-ci et éviter les membre = null
      var membre = await this.requestMembreReconcil()
      for (const site of this.sites) {
        if (this.sitesGroupedInitial.includes(site.id)) {
          Axios("patch", "api/sites/" + site.id, {
            membre: process.env.VUE_APP_API_URL + "/api/membres/" + membre[0].id,
            groupement: null,
          })
        }
      }
      for (const site of this.sitesGrouped) {
        if (!this.sitesGroupedInitial.includes(site.id)) {
          Axios("patch", "api/sites/" + site.id, {
            membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.selected.id,
            groupement: null,
          })
        }
      }

      Materialize.toast("Liaison membre / sites sauvegardée", 4000, "lime")
      this.$router.push({
        name: "membres",
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
