<template>
  <section>
    <div class="textInput">
      <label>
        <font-awesome-icon icon="asterisk" class="red-text" />
        Nom de l'opération :
      </label>
      <input type="text" v-model="operation.name" required />
    </div>
    <div class="textInput">
      <label>
        <font-awesome-icon icon="asterisk" class="red-text" />
        Groupement :
      </label>
      <input type="text" v-model="operation.groupement" required />
    </div>
    <div class="textInput">
      <label>
        <font-awesome-icon icon="asterisk" class="red-text" />
        Nom du contact :
      </label>
      <input type="text" v-model="operation.contactLastName" required />
    </div>
    <div class="textInput">
      <label>
        <font-awesome-icon icon="asterisk" class="red-text" />
        Prénom du contact :
      </label>
      <input type="text" v-model="operation.contactFirstName" required />
    </div>
    <div class="textInput">
      <label>
        <font-awesome-icon icon="asterisk" class="red-text" />
        Adresse mail de contact :
      </label>
      <input type="email" v-model="operation.contactMail" required />
    </div>
    <div class="textInput">
      <label
        ><font-awesome-icon icon="asterisk" class="red-text" />
        Adresse mail d'export :
      </label>
      <input type="email" v-model="operation.exportMail" required />
    </div>
    <date-field v-model="operation.dateDebut" label="Date de début" required></date-field>
    <date-field v-model="operation.dateFin" label="Date de fin" required></date-field>
    <date-field v-model="operation.dateBascule" label="Date d'activation des contrats" required></date-field>
    <date-field v-model="operation.dateFinContrat" label="Date de fin des contrats" required></date-field>

    <div class="switch">
      <label>
        <input type="checkbox" v-model="operation.active"  />
        <span class="lever"></span>
        Désactiver l'opération courante
      </label>
    </div>
  </section>
</template>

<script>
import DateField from "@/components/common/Fields/Date"

export default {
  name: "general",
  components: {
    DateField,
  },
  props: ["value"],
  computed: {
    operation: {
      get() {
        console.log(this.props)
        return this.value
      },
      set(value) {
        console.log("datefin")
        console.log(this.operation.dateFin)
        console.log(value)
        Date.now() >= new Date(this.operation.dateFin)
          ? (this.operation.active = false)
          : (this.operation.active = true)
        this.$emit("input", value)
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
