<template>
  <section>
    <mcma-spin-box :spinning="loading" :full="true" :size="45">
      <div
        class="container"
        v-if="
          (groupement && groupement.state !== Constants.STATUS.CERTIFIED.value && membre.certified !== true && membre.approuved !== true) || $route.params.action === 'duplique'
        "
      >
        <a-row type="flex" :gutter="12">
          <a-col :span="24">
            <groupements-detail-card-header :groupement="groupement">
              <mcma-spin-box :spinning="loading">
                <groupements-edit :groupement="groupement" ref="groupementsEdit" @loading="changeLoadingState" />
                <groupements-interlocuteur-form
                  :groupement="groupement"
                  ref="groupementsInterlocuteurForm"
                  @save="submitAll"
                  @reset="resetAll"
                />
              </mcma-spin-box>
            </groupements-detail-card-header>
          </a-col>
        </a-row>
      </div>
      <div class="container" v-else v-show="groupement">
        <mcma-empty
          :inCard="true"
          image="in_validation"
          :center="true"
          :imageSize="300"
          message="Ce périmètre de facturation ne peux pas être modifié car il a déjà été envoyé en validation."
        >
          <mcma-button name="Revenir aux périmètre de facturation" class="mt24" @click="goBack()" />
        </mcma-empty>
      </div>
    </mcma-spin-box>
  </section>
</template>

<script>
import GroupementsDetailCardHeader from "@/components/mcma/steps/groupements/components/GroupementsDetailCardHeader"
import GroupementsInterlocuteurForm from "@/components/mcma/steps/groupements/components/GroupementsInterlocuteurForm"
import GroupementsEdit from "@/components/mcma/steps/groupements/components/GroupementsEdit"
import McmaEmpty from "@/components/mcma/common/McmaEmpty"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"
import { mapMutations, mapState } from "vuex"

export default {
  name: "GroupementsDetail",
  components: {
    GroupementsEdit,
    GroupementsDetailCardHeader,
    GroupementsInterlocuteurForm,
    McmaEmpty,
    McmaButton,
    McmaSpinBox,
  },
  data() {
    return {
      loading: true,
      groupement: null,
    }
  },
  computed: {
    ...mapState(["groupementEditing","membre"]),
    Constants() {
      return Constants
    },
  },
  mounted() {
    this.$root.$on("ROOT_updateGroupementEditing", (groupement) => {
      this.updateGroupementEditing(groupement)
    })
  },
  beforeDestroy() {
    this.$root.$off("ROOT_updateGroupementEditing")
  },
  methods: {
    ...mapMutations(["setState"]),
    updateGroupementEditing(groupement) {
      if (!this.groupement.id) {
        this.setState({
          stateName: "groupementEditing",
          value: groupement,
        })
      }
    },
    changeLoadingState(value) {
      this.loading = value
    },
    submitAll({ interlocuteurId, interlocuteurFieldsValue }) {
      console.log("interlocuteurId", interlocuteurId)
      console.log("interlocuteurFieldsValue", interlocuteurFieldsValue)
      const interId = interlocuteurId || this.$refs.groupementsInterlocuteurForm.getSelectedInterlocuteurId()
      this.$refs.groupementsEdit.submit(interId, interlocuteurFieldsValue)
    },
    resetAll() {
      this.$refs.groupementsEdit.initialize()
    },
    goBack() {
      this.$router.push({
        name: "groupements",
      })
    },
  },
  created() {
    if (this.$route.params.groupementId) {
      Axios("get", "api/groupements/" + this.$route.params.groupementId)
        .then((response) => {
          this.loading = false
          this.groupement = response.data
          this.setState({
            stateName: "groupementEditing",
            value: null,
          })
        })
        .catch((error) => {
          this.loading = false
          console.error("error", error)
          this.$notification.error({
            message: `Erreur récuperation périmètre de facturation`,
            description: `Une erreur est survenue lors de la recupération du périmètre de facturation.`,
          })
        })
    } else {
      this.loading = false
      this.groupement = { ...this.groupementEditing }
    }
  },
}
</script>

<style lang="scss" scoped></style>
