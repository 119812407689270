<template>
  <a-form :form="form">
    <mcma-card v-if="showTypeSite" title="Type de site" class="mcma-card-in-tab transparent-bg">
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="24"
          type="radio"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="typeSite"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::typeSite"
          :defaultValue="site.typeSite"
        />
      </a-row>
    </mcma-card>

    <mcma-card class="mcma-card-in-tab transparent-bg">
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :tips="true"
          data="Les sites à usage Professionnel sont les sites raccordés entre <strong>37 et 250 kVA</strong> relevant des 
catégories suivantes :
• Les établissements publics industriels et commerciaux (EPIC)
• Les établissements d’enseignement <strong>publics</strong> (maternelles et élémentaires, collèges, 
lycées, centres de formation des apprentis, universités et facultés d’enseignements)
• Les hôpitaux généraux, spécialisés et universitaires, les établissements d’hébergement 
pour personnes âgées ou dépendantes, les maisons d’accueil pour personnes âgées ou 
dépendantes et plus généralement les maisons de retraites.
• Les cinémas, théâtres, salles de sport, les centres de loisirs, les colonies de vacances.
• Les déchetteries, stations d’épuration, bassins d’assainissement.
Tous les autres sites sont considérés comme « Non-professionnel », comme par exemple : 
établissements publics administratifs gérés par les collectivités locales/territoriales, les mairies, 
les chambres de commerce, les chambres de métiers et de l’artisanat, les bibliothèques <strong>gérées 
par les collectivités</strong> (usage professionnel sinon), les copropriétés et les bailleurs sociaux, <strong>ainsi 
que l’ensemble des sites raccordés en tarif < 36 kVA.</strong>"
          :colSpan="12"
          type="radio"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="typeTcfe"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::typeTcfe"
          :defaultValue="defaultTypeTcfe"
          extraLabel="Pour en savoir plus sur la TCFE cliquez sur https://event.entreprises-collectivites.engie.fr/l/932713/2021-11-04/gw8p"
        />
        <mcma-field
          :colSpan="12"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="confirmeTcfe"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::confirmeTcfe"
          :defaultValue="site.confirmeTcfe"
        />
        <mcma-field
          v-if="form.getFieldValue('confirmeTcfe')"
          :colSpan="12"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :disabled="!form.getFieldValue('confirmeTcfe')"
          fieldName="codeApeSite"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::codeApeSite"
          :required="form.getFieldValue('confirmeTcfe')"
          :defaultValue="site.codeApeSite"
          rule="isApe"
        />
      </a-row>
    </mcma-card>

    <mcma-card title="Taux Réduit CSPE" class="mcma-card-in-tab transparent-bg">
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="12"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="switchExonerationCspe"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::switchExonerationCspe"
          :defaultValue="site.switchExonerationCspe"
        />
        <mcma-field
          v-if="form.getFieldValue('switchExonerationCspe')"
          :colSpan="6"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          extraLabel="A renseigner uniquement en cas de taux réduit (indiquer la valeur applicable en €/MWh)"
          fieldName="exonerationCspe"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::exonerationCspe"
          :defaultValue="site.exonerationCspe ? site.exonerationCspe.toString() : null"
          :required="form.getFieldValue('switchExonerationCspe')"
        />
        <div>
          <div
            v-if="form.getFieldValue('switchExonerationCspe')"
            class="upload-box"
          >
            <mcma-button
              :colSpan="12"
              name="Téléversement du Cerfa"
              :color="this.site.cerfa || this.checkCerfa || cerfaUploadedFile  ? 'success' : 'purple'"
              :leftIcon="this.site.cerfa || this.checkCerfa || cerfaUploadedFile ? 'check' : 'upload'"
              :isFaIcon="true"
              @click="$refs.documentUploaded.click()"
            />
            <div class="file-name">
              {{ cerfaUploadedFile ? cerfaUploadedFile.name : "" }}
            </div>
            <input class="ninja" type="file" ref="documentUploaded" @change="processFile" />
          </div>
          <legend v-if="!(this.checkCerfa || cerfaUploadedFile) && form.getFieldValue('switchExonerationCspe')">
            Veuillez téléverser votre Cerfa au format PDF.
          </legend>
        </div>
      </a-row>
    </mcma-card>

    <mcma-card title="Exonération CEE" class="mcma-card-in-tab transparent-bg">
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="24"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="exonerationCee"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::exonerationCee"
        />
        <mcma-field
          v-if="form.getFieldValue('exonerationCee')"
          :colSpan="12"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :disabled="!form.getFieldValue('exonerationCee')"
          fieldName="codeNafSite"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::codeNafSite"
          :required="form.getFieldValue('exonerationCee')"
          :defaultValue="site.codeNafSite"
          rule="isNaf"
        />
      </a-row>
    </mcma-card>

    <mcma-card v-if="showENR" title="Énergie renouvelable" class="mcma-card-in-tab transparent-bg" :inline="true">
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="24"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :disabled="activeENRUnique"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::enr"
          @change="resetHve"
          :defaultValue="defaultValueEnr || 0"
        >
          <template #information class="flex-column">
            <div
              v-if="getSitesFlow && getSitesFlow.enrDescribe"
              class="html-render-content"
              v-html="getSitesFlow.enrDescribe"
              :class="{ 'can-edit': $isAdmin() }"
              @click="setEditorModal('sites', 'enrDescribe')"
            />
            <span class="action" @click="openEnrInfosModal">Cliquer ici</span>
            pour en savoir plus.
          </template>
        </mcma-field>
        <mcma-field
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="hve"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::hve"
          :disabled="form.getFieldValue('enr') !== '100'"
          :defaultValue="site.hve"
        />
      </a-row>
    </mcma-card>
    <mcma-card
      title="Segment, Formule tarifaire d'acheminement (FTA) et Puissances"
      class="mcma-card-in-tab transparent-bg"
    >
      <template #describe>
        <span v-if="form.getFieldValue('segment')">
          <div
            v-if="getSitesFlow && getSitesFlow.sftapDescribe"
            class="html-render-content"
            v-html="getSitesFlow.sftapDescribe"
            :class="{ 'can-edit': $isAdmin() }"
            @click="setEditorModal('sites', 'sftapDescribe')"
          />
        </span>
        <span v-else>Vous devez dans un premier temps selectionner un segment</span>
      </template>
      <a-row type="flex" :gutter="12" class="mt25">
        <a-col :span="12" v-if="!site.added">
          <div class="field-block-title">Caractéristiques techniques initiales</div>
          <a-row type="flex" :gutter="12">
            <a-col :span="24">
              <div class="field-list">
                <div class="field-item">
                  <span class="field-label">Segment Initial</span>
                  <span class="field-value">
                    {{ $getValueByKey(site.segmentInitial, "non renseigné") }}
                  </span>
                </div>
                <div class="field-item">
                  <span class="field-label">FTA Initial</span>
                  <span class="field-value">
                    {{ $getValueByKey(site.ftaInitial, "non renseigné") }}
                  </span>
                </div>
                <div class="field-item" v-for="puissance in puissancesInitial" :key="puissance">
                  <span class="field-label">{{ puissance }}</span>
                  <span class="field-value" v-if="psInitial[puissance]">
                    {{ psInitial[puissance] }}
                    <span v-if="site.segmentInitial === 'C_2' || site.segmentInitial === 'C_3'">(kW)</span>
                    <span v-else>(kVA)</span>
                  </span>
                  <span class="field-value" v-else>Indefinie</span>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="site.added ? 24 : 12">
          <div class="field-block-title">
            <span v-if="this.showENR || this.showFTA || this.showPS || this.showSegment"
              >Caractéristiques techniques souhaitées</span
            >
          </div>
          <a-row type="flex" :gutter="12" class="mtb4">
            <mcma-field
              :colSpan="24"
              type="select"
              :form="form"
              :wasSubmit="wasSubmit"
              :class="{ mb12: !form.getFieldValue('segment') }"
              @change="onUpdateSegment($event)"
              disableValue="Sélectionnez un segment"
              getFlow="name::sites"
              getPanel="name::Données techniques (électricité)"
              getField="type::segment"
              :defaultValue="site.segment"
              :disabledOptions="!site.added && site.segment && (site.segment === 'C_2' || site.segment === 'C_3')"
              :disabled="
                !site.added &&
                site.segment &&
                site.segment !== 'C_2' &&
                site.segment !== 'C_3' &&
                site.segment !== 'C_0'
              "
            />
          </a-row>
          <a-row type="flex" :gutter="12" class="mtb4" v-if="showFTA && form.getFieldValue('segment') && ftas">
            <mcma-field
              :colSpan="24"
              v-if="ftas.length > 0"
              type="select"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="fta"
              @change="onUpdateFta($event)"
              getFlow="name::sites"
              getPanel="name::Données techniques (électricité)"
              getField="type::fta"
              :disableValue="`Sélectionnez une FTA pour le segment ${
                form.getFieldValue('segment')
                  ? $getValueByKey(form.getFieldValue('segment'))
                  : form.getFieldValue('segment')
              }`"
              :defaultValue="site.fta"
            >
              <a-select-option
                v-for="(fta, index) in ftas"
                :key="index"
                :value="fta"
                v-if="!(form.getFieldValue('typeSite') === 'ECLAIRAGE_PUBLIC' && fta === 'BTINF_MU_HP_HC')"
                >{{ $getValueByKey(fta) }}</a-select-option
              >
            </mcma-field>

            <div class="error-content" v-else>
              <p class="error">
                Aucune FTA n'est configuré pour le segment
                {{
                  form.getFieldValue("segment")
                    ? $getValueByKey(form.getFieldValue("segment"))
                    : form.getFieldValue("segment")
                }}
              </p>
            </div>
          </a-row>
          <a-row
            type="flex"
            :gutter="12"
            class="mtb4"
            v-if="showPS && showFTA && form.getFieldValue('segment') && puissances && puissances.length > 0"
          >
            <a-col :span="24">
              <ul class="red-text" v-if="invalidPs">
                Des règles ne sont pas respectés :
                <li v-for="error in invalidPs" :key="error">{{ error }}</li>
              </ul>
            </a-col>
          </a-row>
          <a-row
            type="flex"
            :gutter="12"
            class="mtb4"
            v-if="showPS && showFTA && form.getFieldValue('segment') && puissances && puissances.length > 0"
          >
            <div class="flex-column">
              <label class="mcma-label">Puissance(s)</label>
              <div>
                <mcma-field
                  v-for="puissance in puissances"
                  :key="puissance"
                  :colSpan="8"
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  @change="inputPs($event, puissance)"
                  :fieldName="`ps-${puissance}`"
                  :defaultValue="defaultValuePS(puissance)"
                  :label="`${puissance} ${
                    form.getFieldValue('segment') === 'C_2' || form.getFieldValue('segment') === 'C_3'
                      ? '(kW)'
                      : '(kVA)'
                  }`"
                  :required="true"
                  numberOnly="decimal"
                />
              </div>
            </div>
          </a-row>
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="12" class="mt0">
        <mcma-field
          :colSpan="24"
          type="textarea"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="commentaire"
          :height="120"
          label="Commentaires"
        >
          <template #information v-if="getSitesFlow && getSitesFlow.sftapComment">
            <span
              class="html-render-content"
              v-html="getSitesFlow.sftapComment"
              :class="{ 'can-edit': $isAdmin() }"
              @click="setEditorModal('sites', 'sftapComment')"
            />
          </template>
        </mcma-field>
      </a-row>
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <div class="button-group-inline">
            <mcma-button
              name="Réinitialiser"
              color="primary"
              leftIcon="undo-alt"
              :isFaIcon="true"
              @click="initialize"
            />
            <mcma-button
              name="Valider le site"
              class="ml12"
              color="success"
              leftIcon="save"
              :isFaIcon="true"
              @click="submit"
            />
          </div>
        </a-col>
      </a-row>
    </mcma-card>
    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />

    <mcma-enr-infos-modal
      :visible="openEnrInfos"
      :htmlRender="getSitesFlow && getSitesFlow.enrModalDescribe ? getSitesFlow.enrModalDescribe : null"
      @setEditorModal="setEditorModal('sites', 'enrModalDescribe')"
      @close="closeEnrInfos"
    />
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import McmaEnrInfosModal from "@/components/mcma/common/McmaEnrInfosModal"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import { pick } from "@/util/Helpers"
import { mapState } from "vuex"
import { Ftas, Segments } from "./Puissances"
import { RA_RESULT, RA_TURPE, RV_RESULT, RV_TURPE } from "./RG_TURPE"
import Tips from "@/components/mcma/help/contactUs/Tips"
export default {
  name: "etape_2_elec",
  props: ["site"],
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaEditorModal,
    McmaEnrInfosModal,
    Tips,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      openEnrInfos: false,
      ftas: [],
      puissances: [],
      invalidPs: false,
      showEditor: false,
      flowName: null,
      flowProp: null,
      cerfaUploadedFile: null,
      checkCerfa: null,
      cerfaUploaded: false,
    }
  },
  computed: {
    ...mapState(["operation", "membre"]),
    showTypeSite() {
      const typeSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::typeSite"
      )
      return typeSite ? typeSite.displayed : false
    },
    showENR() {
      const enr = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enr"
      )
      return enr ? enr.displayed : false
    },
    activeENRUnique() {
      const enrUniqueSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enrUniqueSite"
      )
      return enrUniqueSite ? enrUniqueSite.displayed : false
    },
    showFTA() {
      const fta = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::fta"
      )
      return fta ? fta.displayed : false
    },
    showPS() {
      const power = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::power"
      )
      return power ? power.displayed : false
    },
    showSegment() {
      const segment = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::segment"
      )
      return segment ? segment.displayed : false
    },
    puissancesInitial() {
      if (this.site.ftaInitial) {
        return Ftas[this.site.ftaInitial].puissances
      } else {
        return []
      }
    },
    defaultValueEnr() {
      let enr = 0
      enr = this.activeENRUnique && this.membre.enrUniqueSite ? this.membre.enrUniqueSite : this.site.enr
      return enr
    },
    defaultTypeTcfe() {
      let defaultValueTcfe = this.site.tcfe
      if (this.site.psInitial != null) {
        let puissanceSouscrite = JSON.parse(this.site.psInitial).PS
        if (puissanceSouscrite > 250 && (this.site.segment == "C_1" || this.site.segment == "C_2")) {
          defaultValueTcfe = "NON_SOUMIS"
        } else if (puissanceSouscrite > 250 && this.site.segment == "C_5") {
          defaultValueTcfe = "NON_PRO"
        }
      }
      return defaultValueTcfe
    },
    getSitesFlow() {
      return this.operation.flows.find((flow) => flow.name === "sites")
    },
    psInitial() {
      return this.site.psInitial ? JSON.parse(this.site.psInitial) : {}
    },
    canUpdate() {
      return (fieldsValue) => {
        const ps = this.getAllPsAvailable(fieldsValue, true)
        for (let key in ps) {
          if (!ps[key] || ps[key] === "0") {
            return false
          }
        }
        const site = this.site || {}
        const enr = fieldsValue.enr ? fieldsValue.enr.toString() : null
        const psSiteStringComma = site.ps ? this.$replaceAll(site.ps, ".", ",") : null
        const psStringComma = ps ? this.$replaceAll(JSON.stringify(ps), ".", ",") : null
        return !!(
          enr &&
          fieldsValue.segment &&
          fieldsValue.fta &&
          this.ftas.length > 0 &&
          ((site.enr && enr !== site.enr.toString()) ||
            fieldsValue.typeSite !== site.typeSite ||
            fieldsValue.hve !== site.hve ||
            fieldsValue.segment !== site.segment ||
            fieldsValue.fta !== site.fta ||
            fieldsValue.commentaire !== site.commentaire ||
            psSiteStringComma !== psStringComma)
        )
      }
    },
    defaultValuePS() {
      return (puissance) => {
        if (puissance === "PTE/PM") {
          return this.site.fta === "BTSUP_LU" && !this.psInitial["PTE/PM"]
            ? this.psInitial["HPH"]
            : this.psInitial["PTE/PM"]
        }
        const sitePs = this.site.ps ? JSON.parse(this.site.ps) : {}
        return sitePs[puissance] || this.psInitial[puissance] || "0"
      }
    },
  },
  methods: {
    processFile(event) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const fileCerfa = event.target.files[0]

      if (fileCerfa.size > sizeMax) {
        this.$message.warning(`Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`)
      } else if (fileCerfa.type !== "application/pdf") {
        this.$message.error(`Le fichier n'est pas au format PDF`)
      } else {
        this.cerfaUploadedFile = fileCerfa
        this.checkCerfa = true
      }
    },
    addCerfa() {
      if ( this.checkCerfa && this.cerfaUploadedFile) {
        const formData = new FormData()
        formData.append("file", this.cerfaUploadedFile)
        this.cerfaUploaded = true
        Axios(
          "post",
          "/file/operation/" + this.operation.id + "/membre/" + this.membre.id + "/perimetrefacturation/piece",
          formData,
          null,
          {
            "Content-Type": "multipart/form-data",
          }
        ).catch((error) => {
          console.error(error)
          this.$notification.error({
            message: `Erreur lors du téléversement de l'iban`,
            description: `Une erreur s'est produite lors du téléversement de la pièce, veuillez contacter un administrateur.`,
          })
          this.cerfaUploaded = false
        })
      }
    },
    initialize() {
      this.puissances = []
      let editSiteElec = pick(this.site, [
        "enr",
        "typeSite",
        "hve",
        "typeTcfe",
        "switchExonerationCspe",
        "exonerationCspe",
        "exonerationCee",
        "segment",
        "fta",
        "commentaire",
        "confirmeTcfe",
        "codeApeSite",
        "codeNafSite",
      ])
      this.$nextTick(() => {
        editSiteElec.enr = this.integerToString(editSiteElec.enr)
        editSiteElec = this.$mergeObject(this.form.getFieldsValue(), editSiteElec)
        this.form.setFieldsValue(editSiteElec)
        this.initSegment()
      })
    },
    integerToString(currentField) {
      currentField = currentField ? currentField.toString() : "0"
    },
    resetHve() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          hve: false,
        })
      })
    },
    setEditorModal(flowName, flowProp) {
      console.group("setEditorModal@Etape2_Elec")
      console.log("flowName : %O", flowName)
      console.log("flowProp : %O", flowProp)
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
      console.groupEnd()
    },
    editorHasMakeChange() {
      this.setEditorModal()
    },
    submit() {
      let valid = true
      this.form.validateFields((errors, fieldsValue) => {
        if (errors) {
          // || !this.canUpdate(fieldsValue)
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else if (this.form.getFieldValue('switchExonerationCspe') && !this.site.cerfa && (!this.checkCerfa || !this.cerfaUploadedFile) ){
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez d'ajouter votre CERFA en lien avec votre exonération CSPE.`,
          })
        } else {
          const currentFta = Ftas[fieldsValue.fta] || {}
          fieldsValue.ps = this.getAllPsAvailable(fieldsValue, true)
          if (currentFta.puissances && currentFta.puissances.length > 0) {
            for (let key in fieldsValue.ps) {
              if (!currentFta.puissances.includes(key)) {
                delete fieldsValue.ps[key]
              }
            }
          }

          valid = this.validatePs(fieldsValue) ? valid : false
          let exonerationCspe = fieldsValue.exonerationCspe
          if (fieldsValue.switchExonerationCspe === true) {
            if (fieldsValue.exonerationCspe && fieldsValue.exonerationCspe.includes(",")) {
              exonerationCspe = exonerationCspe.replace(",", ".")
            }
          } else {
            exonerationCspe = null
          }
          this.addCerfa()
          const cerfaValidate = fieldsValue.switchExonerationCspe && (this.cerfaUploaded || this.site.cerfa) ? true : !fieldsValue.switchExonerationCspe ? true : false
          if (valid && cerfaValidate) {
            Axios("patch", "api/sites/" + this.site.id, {
              ongletParametre: true,
              enr: fieldsValue.enr ? fieldsValue.enr.match(/(\d+)/)[0] : fieldsValue.enr,
              typeSite: fieldsValue.typeSite,
              typeTcfe: fieldsValue.typeTcfe,
              confirmeTcfe: fieldsValue.confirmeTcfe,
              codeApeSite: fieldsValue.codeApeSite,
              switchExonerationCspe: fieldsValue.switchExonerationCspe,
              exonerationCspe: exonerationCspe,
              exonerationCee: fieldsValue.exonerationCee,
              codeNafSite: fieldsValue.codeNafSite,
              hve: fieldsValue.hve,
              segment: fieldsValue.segment,
              fta: fieldsValue.fta,
              ps: fieldsValue.ps ? JSON.stringify(fieldsValue.ps) : fieldsValue.ps,
              commentaire: fieldsValue.commentaire,
              cerfa: this.cerfaUploaded === false ?  this.site.cerfa : this.cerfaUploaded
            })
              .then((response) => {
                this.$emit("update", response.data)
                this.$notification.success({
                  message: "Paramètres sauvegardées !",
                  description: `Les paramètres ont bien été sauvegardées`,
                })
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: "Erreur serveur",
                  description: "Une erreur server est survenue, veuillez contacter un administrateur.",
                })
              })
          } else {
            this.$notification.warning({
              message: "Formulaire invalide",
              description: `Veuillez-vous assurez de remplir tous les champs requis.`,
            })
          }
        }
      })
    },
    validatePs(fieldsValue) {
      this.invalidPs = false

      const errors = []
      const derogables = []
      let valid = true

      for (const rv in RV_TURPE) {
        const result = RV_TURPE[rv](
          fieldsValue.typeSite === "ECLAIRAGE_PUBLIC",
          fieldsValue.segment,
          fieldsValue.fta,
          fieldsValue.ps
        )
        if (result.state === RV_RESULT.KO) {
          errors.push(result.message)
          valid = false
        } else if (result.state === RV_RESULT.DEROGABLE) {
          derogables.push(result.message)
        }
      }

      if (valid && derogables.length) {
        let message = "Les puissances indiquées pour votre site ne respectent pas les règles suivantes :\n"
        for (const derogable of derogables) {
          message += "  - " + derogable + "\n"
        }
        message += "Voulez-vous vraiment déroger à ces règles?"

        if (!confirm(message)) {
          return false
        }
      } else if (!valid && errors.length) {
        this.invalidPs = errors
        return false
      }
      return true
    },
    inputPs(value, item) {
      let adjust = value
      for (const ra in RA_TURPE) {
        const result = RA_TURPE[ra](
          this.form.getFieldValue("typeSite") === "ECLAIRAGE_PUBLIC",
          this.form.getFieldValue("segment"),
          this.form.getFieldValue("fta"),
          adjust
        )
        if (result.state === RA_RESULT.ADJUSTED) {
          adjust = result.value
        }
      }
      if (adjust !== value) {
        this.form.setFieldsValue({
          [item]: adjust,
        })
      }
    },
    initSegment() {
      const segment = this.form.getFieldValue("segment")
      const dynSegment = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::segment",
        segment
      )
      if (segment && dynSegment && dynSegment.lockedValues.includes(segment || dynSegment.value)) {
        this.loadFtas(segment)
        const fta = this.form.getFieldValue("fta") || this.site.fta
        this.puissances = fta ? Ftas[this.site.fta].puissances : undefined
      } else {
        const toReset = {
          segment: undefined,
          fta: undefined,
        }
        const ps = this.getAllPsAvailable()
        ps.forEach((key) => {
          toReset[key] = undefined
        })
        this.form.setFieldsValue(toReset)
      }
    },
    getAllPsAvailable(fieldsValues, getHasObject) {
      if (!fieldsValues) {
        fieldsValues = this.form.getFieldsValue()
      }
      const fieldsValuesKeys = Object.keys(fieldsValues) || []
      const psKeys = fieldsValuesKeys.filter((item) => item.startsWith("ps-"))
      if (getHasObject) {
        const psObject = {}
        psKeys.forEach((key) => {
          const keyParse = key.replace("ps-", "")
          psObject[keyParse] = fieldsValues[key]
        })
        return psObject
      }
      return psKeys
    },
    loadFtas(segment) {
      const segmentObject = Segments[segment.toUpperCase()] || {}
      const ftasBySegment = segmentObject.fta || []
      const dynFta = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::fta",
        this.form.getFieldValue("fta")
      )
      const lockedValues = dynFta.lockedValues || []
      this.ftas = ftasBySegment.filter(function (item) {
        return lockedValues.includes(item.name)
      })
      this.ftas = this.ftas.map((fta) => fta.name)
      if (this.ftas.length > 0) {
        if (!this.ftas.includes(this.site.fta)) {
          this.emptyForm()
        }
      } else {
        this.emptyForm()
      }
    },
    emptyForm() {
      this.puissances = []
      const toReset = { fta: undefined }
      const ps = this.getAllPsAvailable()
      ps.forEach((key) => {
        toReset[key] = undefined
      })
      this.form.setFieldsValue(toReset)
    },
    onUpdateSegment(segment) {
      this.loadFtas(segment)
    },
    onUpdateFta(fta) {
      this.puissances = Ftas[fta].puissances
    },
    closeEnrInfos() {
      this.openEnrInfos = false
    },
    openEnrInfosModal() {
      this.openEnrInfos = true
    },
  },
  watch: {
    site() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.error-content {
  display: flex;
  width: 100%;
  height: 50px;
  background: #f15921;
  margin: 10px 6px;
  border-radius: 4px;
  .error {
    margin: auto;
    color: white;
    font-weight: 900;
  }
}
.upload-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 45vw;
  margin: 20px 0;
  padding-right: 10px;
  border-radius: 4px;
  background: rgba($grey, 0.1);
  border: 1px solid rgba($grey, 0.3);
  transition: all 0.3s ease;
  .file-name {
    color: $grey;
  }
  &.has-file {
    background: hsla(var(--success-color-h), var(--success-color-s), var(--success-color-l), 0.1);
    .file-name {
      color: var(--text-color, $text-color);
    }
  }
}
</style>
