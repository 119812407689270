<template>
  <mcma-card :title="getTitle" :icon="getIcon" class="mcma-card-documents-header" :inline="true">
    <template #describe>
      <span>Votre progression actuelle pour cette étape est de</span>
    </template>
    <template #headerRightSide>
      <a-progress
        :strokeColor="progression === 100 ? successColor : primaryColor"
        :percent="progression"
        :format="() => progression + '%'"
        :strokeWidth="22"
      />
    </template>
    <div
      v-if="getCurrentFlow && getCurrentFlow.describeInFlow"
      class="html-render-content sub-describe"
      v-html="getCurrentFlow.describeInFlow"
      :class="{ 'can-edit': $isAdmin() }"
      @click="setEditorModal(getCurrentFlowName, 'describeInFlow')"
    />
    <mcma-card class="mcma-card-documents-header-body">
      <div class="sentence">
        <div class="principal-message" :class="{ 'with-bottom': progression === 100 }">
          <p v-if="isDownloadPage && progression !== 100">
            Pour finir cette étape, vous devez télécharger toutes les pièces présentes ci-dessous.
          </p>
          <p v-else-if="!isDownloadPage && progression !== 100">
            Pour finir cette étape, vous devez déposer toutes vos pièces signées.
          </p>
        </div>
      </div>
      <div v-if="progression === 100">
        <div class="sentence">
          <p class="principal-message inline">
            Cette étape est terminée. <br />
            Pour savoir ce qu'il vous reste à faire :
          </p>
          <mcma-button name="Rendez-vous sur la page d'accueil" class="mt24" color="success" @click="goBack()" />
        </div>
      </div>
    </mcma-card>
    <slot></slot>
    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import { mapState } from "vuex"

export default {
  name: "DocumentsHeader",
  props: {
    documents: Array,
    documentsDownloaded: Array,
    documentsUploaded: Array,
    isDownloadPage: Boolean,
  },
  components: {
    McmaCard,
    McmaIcon,
    McmaButton,
    McmaDivider,
    McmaEditorModal,
  },
  data() {
    return {
      primaryColor: "#1890ff",
      successColor: "#4ECB74",
      showEditor: false,
      flowName: null,
      flowProp: null,
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
    getTitle() {
      return this.isDownloadPage
        ? "Liste des pièces à télécharger et mettre à signature"
        : "Liste des pièces à déposer après signature"
    },
    getIcon() {
      return this.isDownloadPage ? "download" : "upload"
    },
    progression() {
      if (this.documents && this.documents.length > 0) {
        console.log("this.documents : %O", this.documents)
        const documentsToCheck = this.isDownloadPage ? this.documentsDownloaded : this.documentsUploaded
        const documentsToDeno = this.isDownloadPage
          ? this.documents
          : this.documents.filter((e) => {
              return e.mandatory_upload === true
            })
        console.log("documentsToCheck : %O", documentsToCheck)
        console.log("documentsToDeno : %O", documentsToDeno)
        return Math.trunc((100 * documentsToCheck.length) / documentsToDeno.length)
      }
      return 100
    },
    getCurrentFlowName() {
      return this.isDownloadPage ? "documents-download" : "documents-upload"
    },
    getCurrentFlow() {
      return this.operation.flows.find((item) => {
        return item.name === this.getCurrentFlowName
      })
    },
  },
  methods: {
    setEditorModal(flowName, flowProp) {
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
    },
    editorHasMakeChange() {
      this.setEditorModal()
    },
    goTo(route) {
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    goBack() {
      this.$router.push({
        name: "steps",
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-documents-header {
  .avatar {
    height: 33px !important;
    margin-bottom: 11px !important;
  }
  .headerRightSide {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .card-body {
    // position: relative;
    // margin-top: 24px;
    // &:before{
    // content: "";
    // position: absolute;
    // top: 0;
    // left: calc(var(--padding, $padding) * -1);
    // width: calc(100% + (var(--padding, $padding) * 2) * 2);
    // height: 1px;
    // background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base,0) 0%);
    // background-position: top;
    // background-size: 15px 1px;
    // background-repeat: repeat-x;
    // }
    .mcma-card-documents-header-body {
      padding-top: 0;
      .card-body {
        padding-top: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }
      .sentence {
        display: flex;
        flex-flow: column nowrap;
        text-align: center;
        align-items: center;
        width: 100%;
        .principal-message {
          display: flex;
          flex-flow: column nowrap;
          font-size: 16px;
          color: var(--text-color, $text-color);
          font-weight: 400;
          strong {
            color: var(--primary-color, $primary-color);
          }
          &.inline {
            display: inline;
          }
          &.with-bottom {
            margin-top: var(--padding, $padding);
            margin-bottom: calc(var(--padding, $padding) * 2);
          }
          .mcma-icon {
            align-self: center;
            background: var(--success-color, $success-color);
            border-radius: 4px;
            padding: 8px 15px 7px 15px;
            margin: 20px 0;
            font-weight: 900;
            img {
              height: 22px;
            }
          }
        }
        .secondary-message {
          font-size: 18px;
          font-weight: 900;
          background: var(--secondary-color, $orange);
          color: $white;
          border-radius: 4px;
          padding: 20px;
          margin-top: var(--padding, $padding);
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: calc(var(--padding, $padding) * -1);
            left: calc(var(--padding, $padding) * -1);
            width: calc(100% + (var(--padding, $padding) * 2) * 2);
            height: 1px;
            background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base, 0) 0%);
            background-position: top;
            background-size: 15px 1px;
            background-repeat: repeat-x;
          }
          &.done {
            background: var(--success-color, $success-color);
          }
          &.pending {
            background: var(--secondary-color, $orange);
          }
        }
      }
    }
  }
  .ant-progress {
    .ant-progress-outer {
      width: calc(100% - 60px);
    }
    .ant-progress-text {
      font-size: 22px;
      margin-left: 25px;
      line-height: 25px;
      text-align: right;
      font-weight: 300;
      color: var(--text-color, $text-color);
    }
    &.ant-progress-status-success {
      .ant-progress-text {
        i {
          color: var(--success-color, $success-color);
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .button-group {
    display: flex;
    flex-flow: row nowrap;
  }
}
</style>
