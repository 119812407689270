<template>
  <div class="mcma-table">
    <div class="header">
      <div class="selected-sentence" :class="{ show: hasSelected }" v-if="dataSource.length > 0">
        <template>
          {{ selectedSentence }}
        </template>
      </div>
    </div>
    <a-table
      :rowSelection="rowSelection"
      :columns="getColumns"
      :dataSource="dataSource"
      :scroll="{ x: true }"
      :pagination="{
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
      }"
      @change="onChange"
      :locale="getLocale"
      bordered
    >
      <div
        slot="filterDropdown"
        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput[column.dataIndex] = c)"
          :placeholder="`Recherche par ${column.title.toLowerCase()}`"
          :value="selectedKeys[0]"
          @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block"
        />
        <a-button
          type="primary"
          @click="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >
        </a-button>
        <a-button
          @click="() => handleReset(column.dataIndex, clearFilters)"
          icon="sync"
          size="small"
          style="width: 90px"
        >
        </a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :class="{ 'blue-color': filtered }" />
      <template slot="nom" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="nom" :text="text" />
      </template>
      <template slot="prenom" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="prenom" :text="text" />
      </template>
      <div slot="fonction" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="fonction" :isGenericField="true" :text="text" />
      </div>
      <template slot="mail" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="mail" :text="text" />
      </template>
      <template slot="telephone" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="telephone" :text="text" />
      </template>
      <template slot="mobile" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="mobile" :text="text" />
      </template>
      <div slot="role" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="role" :isGenericField="true" :text="text" />
      </div>
      <div slot="actions" slot-scope="props">
        <div class="cell-actions">
          <div class="actions-block">
            <div class="line">
              <div
                @click.prevent="
                  goTo({
                    name: 'interlocuteurs-detail',
                    params: { interlocuteurId: props.id, action: 'edit' },
                  })
                "
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Modifier l'interlocuteur</span>
                  </template>
                  <div>
                    <mcma-icon leftImage="edit_white" color="primary" :full="true" />
                  </div>
                </a-tooltip>
              </div>
              <div
                @click.prevent="
                  goTo({
                    name: 'interlocuteurs-detail',
                    params: {
                      interlocuteurId: props.id,
                      action: 'duplique',
                      interlocuteurs: interlocuteurs,
                    },
                  })
                "
                class="ml5"
                v-if="!membre.approuvedInterlocuteurs"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Dupliquer l'interlocuteur</span>
                  </template>
                  <div>
                    <mcma-icon type="copy" color="purple" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
              <div v-if="!membre.approuvedInterlocuteurs" @click.prevent="remove(props)" class="ml5">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Supprimer l'interlocuteur</span>
                  </template>
                  <div>
                    <mcma-icon type="trash" color="red" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-table>
  </div>
</template>

<script>
import Vue from "vue"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSearchTableFilter from "@/components/mcma/common/McmaSearchTableFilter"
import { mapMutations, mapState, mapActions } from "vuex"
import Axios from "@/util/Axios"
import { accorization } from "@/util/Helpers"
import GenericFieldTable from "@/components/mcma/common/Fields/Jsons/GenericFieldTable"

Vue.component("interlocuteur-empty-without-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun interlocuteur </span>
              </a-empty>`,
})

Vue.component("interlocuteur-empty-with-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun interlocuteur ne correspond au filtre renseigné </span>
              </a-empty>`,
})

export default {
  name: "InterlocuteursListTable",
  props: {
    interlocuteurs: Array,
  },
  components: {
    McmaIcon,
    McmaButton,
    McmaSearchTableFilter,
  },
  data() {
    return {
      selectedRowKeys: [],
      oldSelectedRowKeys: [],
      dataSource: [],
      filters: {},
      searchText: {
        nom: "",
        prenom: "",
        mail: "",
        telephone: "",
        mobile: "",
      },
      searchInput: {
        nom: null,
        prenom: null,
        mail: null,
        telephone: null,
        mobile: null,
      },
      columns: [
        {
          title: "Nom",
          dataIndex: "nom",
          key: "nom",
          fixed: "left",
          width: 150,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nom",
          },
          onFilter: (value, record) =>
            record.nom ? record.nom.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["nom"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Prénom",
          dataIndex: "prenom",
          key: "prenom",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "prenom",
          },
          onFilter: (value, record) =>
            record.prenom ? record.prenom.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["prenom"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Fonction",
          dataIndex: "fonction",
          key: "fonction",
          filters: this.filtersGenericEnum("fonction"),
          onFilter: (value, record) => record.fonction === value,
          scopedSlots: { customRender: "fonction" },
        },
        {
          title: "Email",
          dataIndex: "mail",
          key: "mail",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "mail",
          },
          onFilter: (value, record) => {
            return record.mail
              ? record.mail.find((email) => {
                  return email.toString().toLowerCase().includes(value.toLowerCase())
                })
              : []
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["mail"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Téléphone Fixe",
          dataIndex: "telephone",
          key: "telephone",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "telephone",
          },
          onFilter: (value, record) =>
            record.telephone ? record.telephone.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["telephone"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Téléphone mobile",
          dataIndex: "mobile",
          key: "mobile",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "mobile",
          },
          onFilter: (value, record) =>
            record.mobile ? record.mobile.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["mobile"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Rôle",
          dataIndex: "role",
          key: "role",
          filters: this.filtersGenericEnum("role"),
          onFilter: (value, record) => record.role === value,
          scopedSlots: { customRender: "role" },
        },
        {
          title: "Actions",
          fixed: "right",
          key: "actions",
          width: 100,
          scopedSlots: { customRender: "actions" },
        },
      ],
    }
  },
  computed: {
    ...mapState(["membre", "user", "currentTutorial", "operation"]),
    getLocale() {
      return {
        emptyText:
          this.dataSource.length === 0 ? <interlocuteur-empty-without-filter /> : <interlocuteur-empty-with-filter />,
      }
    },
    selectedSentence() {
      return accorization(
        this.oldSelectedRowKeys.length,
        "interlocuteur sélèctionné",
        true,
        "aucun interlocuteur sélèctionné"
      )
    },
    getColumns() {
      return this.columns
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onSelectAll: this.onSelectAll,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
      }
    },
  },
  watch: {
    interlocuteurs: {
      deep: true,
      handler(interlocuteurs) {
        this.generateDataSource(interlocuteurs)
      },
    },
    selectedRowKeys(newValue, oldValue) {
      this.oldSelectedRowKeys = newValue.length === 0 ? [...oldValue] : [...newValue]
    },
  },
  mounted() {
    this.generateDataSource(this.interlocuteurs)
  },
  methods: {
    ...mapMutations(["setState"]),
    ...mapActions(["requestMembreStats"]),
    goTo(route) {
      const interlocuteurId = route && route.params ? route.params.interlocuteurId : null
      const interlocuteur = this.interlocuteurs.find((g) => g.id === interlocuteurId)
      this.setState({
        stateName: "interlocuteur",
        value: interlocuteur || {},
      })
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    updateSelectRowsOnFilterChange() {
      const dataSource = this.getDataSourceFiltered()
      this.selectedRowKeys = this.selectedRowKeys.filter((key) => {
        return dataSource.find((data) => {
          return key === data.key
        })
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      const list = this.dataSource.filter((data) => selectedRowKeys.includes(data.key)).map((data) => data.key)
      this.$emit("selectedChanged", list)
    },
    onChange(pagination, filters, sorter) {
      this.filters = filters
      this.updateSelectRowsOnFilterChange()
    },
    generateDataSource(interlocuteurs) {
      this.dataSource = []
      interlocuteurs.forEach((interlocuteur, index) => {
        this.dataSource.push({
          key: index,
          id: interlocuteur.id,
          nom: interlocuteur.nom,
          prenom: interlocuteur.prenom,
          fonction: this.getValueByGenericField("fonction", interlocuteur.fonction) || interlocuteur.fonction,
          mail: interlocuteur.mail ? interlocuteur.mail.split(",") : [],
          telephone: interlocuteur.telephone,
          mobile: interlocuteur.mobile,
          role: this.getValueByGenericField("role", interlocuteur.role) || interlocuteur.fonction,
        })
      })
      this.dataSource.sort((a, b) => b.id - a.id)
    },
    getValueByGenericField(fieldName, key) {
      if (GenericFieldTable[fieldName] && GenericFieldTable[fieldName][key]) {
        return GenericFieldTable[fieldName][key]
      }
      return key
    },
    filtersGenericEnum(key) {
      const filters = []
      for (let prop in GenericFieldTable[key]) {
        let text = GenericFieldTable[key][prop]
        filters.push({ text, value: text })
      }
      return filters
    },
    getDataSourceFiltered() {
      const toCheck = ["fonction", "role"]
      let dataSourceFiltered = [...this.dataSource].sort((a, b) => b.id - a.id)
      toCheck.forEach((key) => {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) => this.filters[key].includes(data[key]))
        }
      })
      for (let key in this.searchInput) {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) =>
            data[key] ? data[key].toString().toLowerCase().includes(this.searchText[key].toLowerCase()) : null
          )
        }
      }
      return dataSourceFiltered
    },
    onSelectAll(selected) {
      let dataSourceFiltered = []
      if (selected) {
        dataSourceFiltered = this.getDataSourceFiltered()
      }
      this.selectedRowKeys = dataSourceFiltered.map((data) => data.key)
      this.onSelectChange(this.selectedRowKeys)
    },
    handleSearch(fieldName, selectedKeys, confirm) {
      confirm()
      this.searchText[fieldName] = selectedKeys[0]
    },

    handleReset(fieldName, clearFilters) {
      clearFilters()
      this.searchText[fieldName] = ""
    },
    remove(interlocuteur) {
      const that = this
      this.$confirm({
        title: `Supprimer l'interlocuteur`,
        content: `Voulez-vous vraiment supprimer de façon irréversible l'interlocuteur ${interlocuteur.prenom} ${interlocuteur.nom} ?`,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          Axios("delete", "api/interlocuteurs/" + interlocuteur.id)
            .then((reponse) => {
              that.$notification.success({
                message: `Interlocuteur supprimé`,
                description: `L'interlocuteur ${interlocuteur.prenom} ${interlocuteur.nom} à bien été supprimé`,
              })
              // this.requestMembreStats(this.membre.id);
              that.$emit("initialize")
            })
            .catch((error) => {
              console.error(error)
              that.$notification.error({
                message: `Erreur de suppression`,
                description: `L'interlocuteur ${interlocuteur.prenom} ${interlocuteur.nom} n'as pas pu être supprimé. Veuillez réessayer ultérieurement.`,
              })
            })
        },
        onCancel() {
          console.log(
            `%c Cancel delete interlocuteur ${interlocuteur.prenom} ${interlocuteur.nom}`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
          )
        },
      })
    },
    updateInterlocuteur(interlocuteurId, dataToUpdate) {
      this.$emit("updateInterlocuteur", interlocuteurId, dataToUpdate)
    },
  },
}
</script>

<style lang="scss"></style>
