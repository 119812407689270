<template>
  <section>
    <mcma-spin-box :spinning="loading" :tip="'Chargement en cours'">
      <div>
        <div class="fileBtn pointer" @click="downloadFileOperation">
          <span>Télécharger le fichier d'initialisation de l'opération</span>
        </div>
      </div>
      <br />
      <div class="file-field">
        <div class="fileBtn">
          <span>Choisir un fichier</span>
          <input type="file" ref="fileInput" @change="processFile" />
        </div>
        <div class="file-path-wrapper">
          <input type="text" class="file-path validate" />
        </div>
      </div>
      <i class="material-icons importBtn" v-tooltip="'Importer les données'" @click="importFile">save_alt</i>
      <br />
      <div v-if="msg">
        <span style="font-size: larger" v-html="this.msg"></span>
      </div>
      <ul>
        <li v-for="membre in operation.membres" :key="membre.id">
          {{ membre.name }}
        </li>
      </ul>
      <div>
        <div class="fileBtn pointer" @click="downloadExportOperation">
          <span>Exporter une opération</span>
        </div>
      </div>
      <a class="ninja" ref="downloadLink" />
    </mcma-spin-box>
  </section>
</template>

<script>
import Materialize from "materialize-css"
import Axios from "@/util/Axios"
import { mapState } from "vuex"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"

export default {
  name: "membres",
  props: ["value"],
  components: {
    McmaSpinBox,
  },
  data() {
    return {
      file: null,
      msg: "",
      loading: false,
    }
  },
  computed: {
    ...mapState(["user"]),
    operation: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
  methods: {
    processFile(event) {
      console.group("importFile@Membre")
      this.file = event.target.files[0]
      console.log("Fichier ajouter : ", this.file.name)
      console.groupEnd()
    },
    importFile() {
      console.group("importFile@Membre")
      console.log("file : %O", this.file)
      this.msg = ""
      // TODO Vérifier qu'il n'a pas déjà des membres affectés à l'opération
      // TODO Ajouter la possibilité de supprimer l'opérations et les sites associés
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const file = this.file // this.$refs.fileInput
      const importFile = this.$refs.fileInput
      console.log("file : %O", importFile)
      if (this.file && this.file.name && this.file.name.length > 0) {
        setTimeout(() => {
          const importFileName = this.file.value
          console.log(importFileName + " imported from %O !", file)
          if (file.size > sizeMax) {
            Materialize.toast(
              "Le fichier dépasse la taille autorisé qui est de " + process.env.VUE_APP_UPLOAD_SIZE + "Mo.",
              4000,
              "amber white-text"
            )
          } else {
            this.loading = true
            const formData = new FormData()
            Materialize.toast("Chargement du fichier", 10000, "lime")
            formData.append("operation", this.operation.id)
            formData.append("file", file)
            Axios("post", "/zdp/import", formData, null, {
              "Content-Type": "multipart/form-data",
            })
              .then((reponse) => {
                this.loading = false
                console.log("reponse : %O", reponse)
                if (reponse.data) {
                  console.log("reponse : %O", reponse)
                  this.msg += reponse.data.split(">").join("<br>")
                  Materialize.toast(
                    "Import des données terminées, vous pouvez cliquer sur la petite maison pour vous rendre dans l'opération !",
                    2000,
                    "light-green darken-2"
                  )
                } else {
                  Materialize.toast("Erreur lors du dépot du fichier", 4000, "amber white-text")
                }
              })
              .catch((reponse) => {
                this.loading = false
                console.error(reponse)
                console.log("reponse.response.data : %O", reponse.response.data)
                this.msg += reponse.response.data.split(">").join("<br>")
                Materialize.toast("Erreur lors du dépot du fichier", 4000, "amber white-text")
              })
          }
        }, 1000)
      } else {
        Materialize.toast("Erreur : vous devez choisir un fichier", 4000, "deep-orange white-text")
      }
      console.groupEnd()
    },
    downloadFileOperation() {
      console.log("> downloadFileOperation@Membres")
      if (this.operation) {
        var date = new Date()
        var month = "" + (date.getMonth() + 1)
        var day = "" + date.getDate()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        var fileName = "FI_" + this.operation.name + "_" + date.getFullYear() + month + day +".xlsx"
        fileName = fileName.replace(" ", "_")
        Axios(
          "get",
          "zdp/patron?operation=" + this.operation.id + "&fileName=" + fileName + "&userEMail=" + this.user.email,
          null,
          {
            responseType: "blob",
          }
        )
          .then((response) => {
            console.log("> response :  %O", response)
            const link = this.$refs.downloadLink
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.setAttribute("download", fileName)
            link.click()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors du téléchargement", 4000, "amber white-text")
          })
      } else {
        Materialize.toast(
          "Erreur lors du téléchargement : aucune opération n'est sélectionné",
          4000,
          "amber white-text"
        )
      }
    },
    downloadExportOperation() {
      console.log("> downloadExportOperation@Membres")
      if (this.operation) {
        var date = new Date()
        var month = "" + (date.getMonth() + 1)
        var day = "" + date.getDate()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        var fileName =
          "Export_" +
          this.operation.name +
          "_" +
          date.getFullYear() +
          month +
          day +
          "_" +
          date.getHours() +
          date.getMinutes() +
          date.getSeconds() +
          ".xlsx"
        fileName = fileName.replace(" ", "_")
        Axios(
          "get",
          "zdp/exportOperation?operation=" +
            this.operation.id +
            "&fileName=" +
            fileName +
            "&userEMail=" +
            this.user.email,
          null,
          {
            responseType: "blob",
          }
        )
          .then((response) => {
            console.log("> response :  %O", response)
            Materialize.toast("Export terminé !", 5000, "light-green darken-2 white-text")
            const link = this.$refs.downloadLink
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.setAttribute("download", fileName)
            link.click()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast(
              "Désolé, il y a eu une rrreur lors de l'export de l'opération " + this.operation.name + "!",
              4000,
              "amber white-text",
              function () {
                alert("Désolé, il y a eu une rrreur lors de l'export de l'opération " + this.operation.name + "!")
              }
            )
          })
      } else {
        Materialize.toast(
          "Erreur lors du téléchargement : aucune opération n'est sélectionné",
          4000,
          "amber white-text"
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.file-field {
  display: inline-flex;
  align-items: center;
}
.fileBtn {
  background: #546e7a;
  color: white;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 15px;
  transition: 0.3s;
  &:hover {
    background: #6a8896;
  }
}
.file-path-wrapper {
  display: inline-block;
  min-width: 325px;
  & .file-path {
    margin: 0 !important;
  }
}
.importBtn {
  display: inline-block;
  height: 30px;
  padding: 3px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 15px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
