<template>
  <section class="superAdminContent" :class="navbarDeployed ? 'smallContent' : ''">
    <a class="ninja" ref="downloadLink" />
    <side-nav @deploy="(value) => (navbarDeployed = value)" />

    <router-view />
  </section>
</template>

<script>
import Axios from "@/util/Axios"
import SideNav from "./SideNav"
import { mapMutations, mapState } from "vuex"
import _ from "lodash"
import XLSX from "xlsx"
import Constants from "@/util/Constants"
import moment from "moment"

const DATE_FORMAT = "DD-MM-YYYY HH:mm:ss"
export default {
  name: "super-admin",
  components: {
    SideNav,
  },
  data() {
    return {
      loading: false,
      navbarDeployed: false,
      currentOperation: null,
    }
  },
  computed: {
    toDate() {
      return (timestamp) => {
        return timestamp ? moment(timestamp, "x").format(DATE_FORMAT) : "Inconnue"
      }
    },
    toTime() {
      return (timestamp) => {
        if (timestamp) {
          var seconds = Math.floor(timestamp)
          var minutes = Math.floor(seconds / 60)
          var hours = Math.floor(minutes / 60)
          var days = Math.floor(hours / 24)
          var secondsf = Math.floor(seconds - minutes * 60)
          var minutesf = Math.floor(minutes - hours * 60)
          var hoursf = Math.floor(hours - days * 24)
          return days + "j " + hoursf + ":" + minutesf + ":" + secondsf
        }
        return "Inconnue"
      }
    },
  },
  methods: {
    ...mapState(["user"]),
    ...mapMutations(["setBreadcrumbAdd", "setOperationEditing", "setOperation", "removeLastbreadcrumb"]),
    getOperationbyId(operationId) {
      if (!operationId) {
        return Promise.reject(new Error("no operation id"))
      }
      return Axios("get", "api/operations/" + operationId)
        .then((response) => {
          let operation = response.data
          return Axios("get", "api/operations/" + operationId + "/mails")
            .then((response) => {
              operation.mails = this.$cloneDeep(response.data._embedded.mails)
              return operation
            })
            .catch((error) => {
              this.$notification.error({
                message: `Erreur récuperation operation mail`,
                description: `Une erreur est survenue lors de la recupération de la configuration d'email de l'operation.`,
              })
              return error
            })
        })
        .catch((error) => {
          this.$notification.error({
            message: `Erreur récuperation de l'operation`,
            description: `Une erreur est survenue lors de la recupération de l'operation.`,
          })
          return error
        })
    },
    goTo(operation) {
      if (operation && operation.id) {
        this.getOperationbyId(operation.id).then((ope) => {
          this.removeLastbreadcrumb()
          this.setOperation(ope)
          this.setOperationEditing(false)
          this.$router.push({
            name: "operations-detail",
            params: {
              operationId: ope.id,
            },
          })
        })
      } else {
        this.$notification.warning({
          message: "L'operation n'existe pas",
          description: `Vous devez créer une operation avant de pouvoir vous y rendre`,
        })
      }
    },
    edit(operation) {
      if (operation && operation.id) {
        this.setOperationEditing(true)
        this.$router.push({
          name: "superadmin-operation-editing",
          params: {
            operationId: operation.id,
          },
        })
      } else {
        this.$notification.warning({
          message: "l'operation n'existe pas",
          description: `Vous ne pouvez editer une opération qui n'existe pas.`,
        })
      }
    },
    addAdmin(operation) {
      if (operation && operation.id) {
        this.$router.push({
          name: "superadmin-add-admin",
        })
      } else {
        this.$notification.warning({
          message: "l'operation n'existe pas",
          description: `Vous ne pouvez ajouter un administrateur sur une opération qui n'existe pas.`,
        })
      }
    },
    buildExportName(operation) {
      var date = new Date()
      var fileName =
        "Export_" +
        operation.name +
        "_" +
        date.getFullYear() +
        date.getMonth() +
        date.getDay() +
        "_" +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds() +
        ".xlsx"
      return fileName.replace(" ", "_")
    },
    async downloadExportOperation(operation) {
      console.log("operation >>", operation)
      console.log("> downloadExportOperation@SUPER_ADMIN")
      if (operation) {
        var fileName = this.buildExportName(operation)
        await Axios(
          "get",
          "zdp/exportOperation?operation=" + operation.id + "&fileName=" + fileName + "&userEMail=" + this.user.email,
          null,
          {
            responseType: "blob",
          }
        )
          .then((response) => {
            console.log("> response :  %O", response)
            const link = this.$refs.downloadLink
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.setAttribute("download", fileName)
            link.click()
            this.$notification.success({
              message: `Téléchargement démarré`,
              description: ` Le fichier d'export est en cours de téléchargement sur votre navigateur....Vous pourrez le consulter une fois le téléchargement démarré.`,
            })
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: `Erreur lors du téléchargement`,
              description: `Une erreur est survenue lors \ndu téléchargement de l'operation ${operation.name}.\n\nVeuillez ressayer ultérieurement.`,
            })
          })
      } else {
        this.$notification.error({
          message: `Erreur de téléchargement`,
          description: `Vous devez sélectionner une opération \npour pouvoir télécharger son contenu.`,
        })
      }
    },
    async downloadExportSI(operation) {
      console.log("> downloadExportOperationDedieSI@SUPER_ADMIN")
      var fileName = this.buildExportName(operation)
      await Axios("get", "zdp/exportOperationSI?operation=" + operation.id + "&fileName=" + fileName, null, {
        responseType: "blob",
      })
        .then((response) => {
          console.log("> response :  %O", response)
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", fileName)
          link.click()
        })
        .catch((error) => console.error(error))
    },
    async downloadAll(operation) {
      await this.downloadExportOperation(operation)
      await this.downloadExportSI(operation)
    },
    getLoading() {
      return this.loading
    },
    downloadAllWithStats(operation) {
      this.getOperationbyId(operation.id).then((operation) => {
        this.downloadAll(operation)
        this.exportMembersStats(operation)
      })
    },
    async exportMembersStats(operation) {
      const date = new Date()
      let fileName =
        "Export_Membre_Statistiques_" +
        operation.name +
        "_" +
        date.getFullYear() +
        date.getMonth() +
        date.getDay() +
        "_" +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds() +
        ".xlsx"
      fileName = fileName.replace(" ", "_")
      let membresStats = []
      let totalPages = 0
      await Axios("get", `api/membreStats/search/findByOperationId?operationId=${operation.id}&size=50&page=0`).then(
        (resp) => {
          membresStats = resp.data._embedded.membreStats
          totalPages = resp.data.page.totalPages
        }
      )
      for (let i = 1; i < totalPages; i++) {
        await Axios(
          "get",
          `api/membreStats/search/findByOperationId?operationId=${operation.id}&size=50&page=${i}`
        ).then((resp2) => {
          membresStats = _.concat(membresStats, resp2.data._embedded.membreStats)
        })
      }
      const membersStatsToExport = []
      membresStats.forEach((member) =>
        membersStatsToExport.push({
          Nom: member.nom,
          Statut: member.status ? Constants["STATUS"][member.status].text : member.status,
          "Nombre de sites": member.statSite ? member.statSite.size : 0,
          "Progression des sites": member.statSite ? member.statSite.progress : 0,
          "Nombre de périmètres de facturation": member.statGroupement ? member.statGroupement.size : 0,
          "Progression des périmètres de facturation": member.statGroupement ? member.statGroupement.progress : 0,
          "Nombre de documents à traiter": member.statPiece ? member.statPiece.nbPiece : 0,
          "Nombre de documents téléchargé": member.statPiece ? member.statPiece.nbPieceDownloaded : 0,
          "Nombre de documents déposé": member.statPiece ? member.statPiece.nbPieceUploaded : 0,
          "Nombre d'interlocuteurs": member.statInterlocuteur ? member.statInterlocuteur.size : 0,
          "Progression des interlocuteurs": member.statInterlocuteur ? member.statInterlocuteur.progress : 0,
          "Auteur validation parcours": member.auteurValidationParcours,
          "Progression globale du membre": member.progress * 100,
          "Actif il y a": this.toTime(member.actif),
          "Date de dernière connexion": this.toDate(member.lastLogin) ,
        })
      )
      const membersStats = XLSX.utils.json_to_sheet(membersStatsToExport)

      const wb = XLSX.utils.book_new()
      membersStats["!cols"] = [
        { wch: 57 },
        { wch: 15 },
        { wch: 15 },
        { wch: 18 },
        { wch: 32 },
        { wch: 36 },
        { wch: 27 },
        { wch: 29 },
        { wch: 26 },
        { wch: 21 },
        { wch: 26 },
      ]
      XLSX.utils.book_append_sheet(wb, membersStats, "Statistiques des membres")
      XLSX.writeFile(wb, fileName)
      this.$notification.success({
        message: `Téléchargement démarré`,
        description: ` Le fichier d'export est en cours de téléchargement sur votre navigateur....Vous pourrez le consulter une fois le téléchargement démarré.`,
      })
    },
    disableOperation(operation) {
      this.loading = true
      this.getOperationbyId(operation.id).then((ope) => {
        ope.active = !ope.active
        this.$deleteKeyRecursive(ope, "_links", true)
        Axios("post", "operation/save", ope)
          .then((response) => {
            if (ope.active === true) {
              this.$notification.success({
                message: `Opération activée`,
                description: `L'opération à bien été activée.`,
              })
            } else {
              this.$notification.success({
                message: `Opération désactivée`,
                description: `L'opération à bien été désactivée.`,
              })
              this.downloadAllWithStats(ope)
            }
            this.loading = false
          })
          .catch((error) => {
            this.$notification.error({
              message: `Erreur de modification`,
              description: `Une erreur est survenue lors de la désactivation de l'operation.`,
            })
            this.loading = false
            return error
          })
      })
    },
    deleteOperation(operation) {
      const that = this
      that.$confirm({
        title: `Supprimer l'operation`,
        content: `Voulez-vous vraiment supprimer l'opération ${operation.name} ?
                  \nLa suppression sera irréversible.`,
        okText: "Oui",
        okType: "error",
        cancelText: "Non",
        onOk() {
          console.log(
            `%c [FAKE] Operation supprimé : `,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            `${operation.name}`
          )
        },
      })
    },
  },
  created() {
    /*
    console.log('SuperAdmin@created his.$router : %O', this.$router)
    console.log('SuperAdmin@created this.$router.history : %O', this.$router.history)
    console.log('SuperAdmin@created this.$router.history.current : %O', this.$router.history.current)
    console.log('SuperAdmin@created this.$router.history.current.fullPath : %O', this.$router.history.current.fullPath)
    */
    this.setBreadcrumbAdd({
      name: "Administration des Opérations",
      action: () => {
        this.$router.push({
          name: "superadmin-board",
          params: {
            operationId: null,
            membreId: null,
          },
        })
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.superAdminContent {
  padding-left: 70px;
  transition: 0.3s;
  &:not(.smallContent) {
    transition-delay: 0.1s;
  }
  &.smallContent {
    padding-left: 220px;
  }
}
</style>
