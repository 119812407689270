<template>
  <main>
    <h5>Édition des Administrateurs et rattachement aux opérations</h5>
    <section class="container" id="addUserForm">
      <user-form :user="selected" :admin="true" :superAdmin="true" @submit="submit" @new="selected = null" />
    </section>
    <section class="row">
      <place-holder :notReady="!totalUsers" class="col s12" id="addUserGrid">
        <grid
          v-if="totalUsers"
          name="Liste des utilisateurs"
          :rows="totalUsers"
          :columns="columns"
          :columnHeader="columnHeader"
          :search="true"
          :columnsNoSearch="['Actions']"
        >
          <center slot="Actions" slot-scope="props">
            <router-link
              :to="{
                name: 'user-admin-link-operation',
                params: { id: props.row.id },
              }"
              class="tutorialNoBtn editUserLinksLink"
              v-tooltip.auto="'Associer des opérations en masse'"
            >
              <i class="material-icons blue-grey-text">link</i>
            </router-link>
            <i
              class="material-icons pointer blue-text tutorialNoBtn addUserEdit"
              v-tooltip.auto="'Editer l\'utilisateur'"
              @click.prevent="edit(props.row)"
              >edit</i
            >
          </center>
        </grid>
      </place-holder>
    </section>
  </main>
</template>

<script>
import Axios from "@/util/Axios"
import Materialize from "materialize-css"
import { mapMutations, mapState } from "vuex"
import Grid from "@/components/common/Grid"
import PlaceHolder from "@/components/common/PlaceHolder"
import UserForm from "@/components/common/UserForm"
import McmaField from "@/components/mcma/common/McmaField"
import McmaCard from "@/components/mcma/common/McmaCard"
export default {
  name: "add-admin",
  components: {
    Grid,
    PlaceHolder,
    UserForm,
    McmaField,
    McmaCard,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      columns: ["email", "nom", "prenom", "role", "Actions"],
      columnHeader: {
        email: "E-mail",
        nom: "Nom",
        prenom: "Prenom",
        role: "Rôle",
      },
      selected: null,
      totalUsers: null,
    }
  },
  computed: {
    ...mapState(["user", "currentTutorial", "operation", "intro"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "removeLastbreadcrumb"]),
    initialize() {
      console.group("initialize@AddAdmin")
      this.totalUsers = []
      Axios("get", "api/users?size=1000&page=0").then((response)=>{
        this.totalUsers.push(...response.data._embedded.users)
        for(let page = 1; page < response.data.page.totalPages; page++)
          Axios("get", "api/users?size=1000&page=" + page).then((resp)=>{
            this.totalUsers.push(...resp.data._embedded.users)
          })
      })
      console.groupEnd()
    },
    submit(user) {
      if (this.selected) {
        Axios("patch", "api/users/" + this.selected.id, {
          ...user,
          welcomed: null,
        })
          .then((response) => {
            Materialize.toast("Utilisateur modifié", 4000, "lime white-text")
            this.selected = null
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de l'ajout", 4000, "deep-orange white-text")
          })
      } else if (!this.invalidPassword) {
        Axios("post", "api/users", user)
          .then((response) => {
            Materialize.toast("Utilisateur ajouté", 4000, "lime white-text")
            // TODO add user operation_id dans la table operation_user...
            console.log("Add user response : %O", response.data)
            console.log("Add user response.data.id : %O", response.data.id)
            console.log("Add user response.data.id : %O", this.operation.id)
            /*
            Axios(
              "post",
              "operation/addLinkUser?operation=" +
                this.operation.id +
                "&user=" +
                response.data.id
            )
              .then(response => {
                Materialize.toast(
                  "Liaison utilisateur-opération sauvegardée",
                  4000,
                  "lime"
                );
              })
              .catch(error => {
                console.error(error);
                Materialize.toast(
                  "Erreur lors de l'ajout du lien entre l'utilisateur et l'opération !",
                  4000,
                  "deep-orange white-text"
                );
              }); */
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de l'ajout", 4000, "deep-orange white-text")
          })
      }
    },
    edit(row) {
      this.selected = row
      if (this.currentTutorial) {
        this.intro.nextStep()
      }
    },
  },
  watch: {
    selected() {
      this.initialize()
    },
  },
  created() {
    this.$scrollToTop()
    this.removeLastbreadcrumb()
    this.setBreadcrumbAdd({
      name: "Gestion des Administrateurs",
      action: () => {},
    })
    console.log("AddAdmin@created this : %O", this)
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
