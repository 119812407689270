<template>
  <div class="mcma-table">
    <div class="header">
      <div class="selected-sentence" :class="{ show: hasSelected }" v-if="dataSource.length > 0">
        <template>
          {{ selectedSentence }}
        </template>
      </div>
    </div>
    <a-table
      :rowSelection="rowSelection"
      :columns="getColumns"
      :dataSource="dataSource"
      :scroll="{ x: true }"
      :pagination="{
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
      }"
      @change="onChange"
      :locale="getLocale"
      bordered
    >
      <div
        slot="filterDropdown"
        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput[column.dataIndex] = c)"
          :placeholder="`Recherche par ${column.title.toLowerCase()}`"
          :value="selectedKeys[0]"
          @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block"
        />
        <a-button
          type="primary"
          @click="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >
        </a-button>
        <a-button
          @click="() => handleReset(column.dataIndex, clearFilters)"
          icon="sync"
          size="small"
          style="width: 90px"
        >
        </a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :class="{ 'blue-color': filtered }" />
      <template slot="nom" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="nom" :text="text" />
      </template>
      <template slot="commune" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="commune" :text="text" />
      </template>
      <template slot="budget" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="budget" :text="text" />
      </template>
      <template slot="siret" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="siret" :text="text" />
      </template>
      <template slot="sitesSize" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="sitesSize" :text="text" />
      </template>
      <div slot="state" slot-scope="props">
        <div class="cell-button">
          <mcma-button
            :name="getStateByProps(props).name"
            :color="getStateByProps(props).color"
            :leftImage="getStateByProps(props).leftImage"
            @click="goTo(getStateByProps(props).route)"
          />
        </div>
      </div>
      <div slot="actions" slot-scope="props">
        <div class="cell-actions">
          <div class="actions-block">
            <div class="line">
              <div
                @click.prevent="
                  goTo({
                    name: 'groupements-link-mass',
                    params: { groupementId: props.id },
                  })
                "
                class="ml5"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Associer en masse vos sites à ce périmètre de facturation</span>
                  </template>
                  <div>
                    <mcma-icon type="building" color="error" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
              <div
                @click.prevent="
                  goTo({
                    name: 'groupements-link',
                    params: { groupementId: props.id },
                  })
                "
                class="ml5"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Associer vos sites à un périmètre de facturation</span>
                  </template>
                  <div>
                    <mcma-icon type="link" color="success" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
              <a-divider
                type="vertical"
                v-if="props.state !== 'CERTIFIED' || (props.state === 'CERTIFIED' && user.role !== 'CLIENT')"
              />
              <div
                @click.prevent="
                  goTo({
                    name: 'groupements-detail',
                    params: { groupementId: props.id, action: 'edit' },
                  })
                "
                class="ml5"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Modifier le périmètre de facuration</span>
                  </template>
                  <div>
                    <mcma-icon leftImage="edit_white" color="primary" :full="true" />
                  </div>
                </a-tooltip>
              </div>
              <div
                @click.prevent="
                  goTo({
                    name: 'groupements-detail',
                    params: { groupementId: props.id, action: 'duplique' },
                  })
                "
                class="ml5"
                v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Dupliquer le périmètre</span>
                  </template>
                  <div>
                    <mcma-icon type="copy" color="purple" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
              <div @click.prevent="remove(props)" class="ml5" v-if="props.state !== 'CERTIFIED'">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Supprimer le périmètre</span>
                  </template>
                  <div>
                    <mcma-icon type="trash" color="red" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-table>
  </div>
</template>

<script>
import Vue from "vue"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSearchTableFilter from "@/components/mcma/common/McmaSearchTableFilter"
import { mapMutations, mapState } from "vuex"
import Constants from "@/util/Constants"
import Axios from "@/util/Axios"
import _ from "lodash"
import { accorization } from "@/util/Helpers"

Vue.component("groupement-empty-without-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun périmètre de facturation </span>
              </a-empty>`,
})

Vue.component("groupement-empty-with-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun périmètre de facturation ne correspond au filtre renseigné </span>
              </a-empty>`,
})

export default {
  name: "GroupementsListTable",
  props: {
    groupements: Array,
  },
  components: {
    McmaIcon,
    McmaButton,
    McmaSearchTableFilter,
  },
  data() {
    return {
      selectedRowKeys: [],
      oldSelectedRowKeys: [],
      dataSource: [],
      nbTotal: 0,
      nb: 0,
      loading: false,
      filters: {},
      searchText: {
        nom: "",
        commune: "",
        budget: "",
        siret: "",
        sitesSize: "",
      },
      searchInput: {
        nom: null,
        commune: null,
        budget: null,
        siret: null,
        sitesSize: null,
      },
      columns: [
        {
          title: "Nom",
          dataIndex: "nom",
          key: "nom",
          fixed: "left",
          width: 150,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nom",
          },
          onFilter: (value, record) =>
            record.nom ? record.nom.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["nom"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Commune",
          dataIndex: "commune",
          key: "commune",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "commune",
          },
          onFilter: (value, record) =>
            record.commune ? record.commune.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["commune"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Nom du budget affecté",
          dataIndex: "budget",
          key: "budget",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "budget",
          },
          onFilter: (value, record) =>
            record.budget ? record.budget.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["budget"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Siret",
          dataIndex: "siret",
          key: "siret",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "siret",
          },
          onFilter: (value, record) =>
            record.siret ? record.siret.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["siret"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Nombre de sites rattachés",
          dataIndex: "sitesSize",
          key: "sitesSize",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "sitesSize",
          },
          onFilter: (value, record) =>
            record.sitesSize ? record.sitesSize.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["sitesSize"].focus()
              }, 0)
            }
          },
        },
         {
          title: "Type de facturation",
          dataIndex: "typeFacturation",
          key: "typeFacturation",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeFacturation",
          },
        },
        {
          title: "Statut",
          key: "state",
          filters: [
            {
              text: Constants.STATUS.TO_COMPLETE.text,
              value: Constants.STATUS.TO_COMPLETE.value,
            },
            {
              text: Constants.STATUS.COMPLETED.text,
              value: Constants.STATUS.COMPLETED.value,
            },
          ],
          onFilter: (value, record) => record.state === value,
          scopedSlots: { customRender: "state" },
        },
        {
          title: "Actions",
          fixed: "right",
          key: "actions",
          width: 200,
          scopedSlots: { customRender: "actions" },
        },
      ],
      states: [
        {
          text: Constants.STATUS.TO_COMPLETE.text,
          value: Constants.STATUS.TO_COMPLETE.value,
          whiteIcon: Constants.STATUS.TO_COMPLETE.whiteIcon,
          classColor: Constants.STATUS.TO_COMPLETE.classColor,
        },
        {
          text: Constants.STATUS.TO_VERIFY.text,
          value: Constants.STATUS.TO_VERIFY.value,
          whiteIcon: Constants.STATUS.TO_VERIFY.whiteIcon,
          classColor: Constants.STATUS.TO_VERIFY.classColor,
        },
        {
          text: Constants.STATUS.COMPLETED.text,
          value: Constants.STATUS.COMPLETED.value,
          whiteIcon: Constants.STATUS.COMPLETED.whiteIcon,
          classColor: Constants.STATUS.COMPLETED.classColor,
        },
        {
          text: Constants.STATUS.CERTIFIED.text,
          value: Constants.STATUS.CERTIFIED.value,
          whiteIcon: Constants.STATUS.CERTIFIED.whiteIcon,
          classColor: Constants.STATUS.CERTIFIED.classColor,
        },
      ],
    }
  },
  computed: {
    ...mapState(["membre", "user", "currentTutorial", "operation"]),
    getStateByProps() {
      return (props) => {
        if (props.state === Constants.STATUS.TO_COMPLETE.value && this.membre.siteNotGrouped > 0) {
          return {
            name: this.getStateByValue(Constants.STATUS.TO_VERIFY.value).text,
            color: this.getStateByValue(Constants.STATUS.TO_VERIFY.value).classColor,
            leftImage: this.getStateByValue(Constants.STATUS.TO_VERIFY.value).whiteIcon,
            route: {
              name: "groupements-link",
              params: { groupementId: props.id },
            },
          }
        }
        return {
          name: this.getStateByValue(props.state).text,
          color: this.getStateByValue(props.state).classColor,
          leftImage: this.getStateByValue(props.state).whiteIcon,
          route: {
            name: "groupements-detail",
            params: {
              groupementId: props.id,
              action: this.getStateByValue(props.state).icon,
            },
          },
        }
      }
    },
    getStateByValue() {
      return (value) => {
        return this.states.find((state) => state.value === value)
      }
    },
    getLocale() {
      return {
        emptyText:
          this.dataSource.length === 0 ? <groupement-empty-without-filter /> : <groupement-empty-with-filter />,
      }
    },
    selectedSentence() {
      return accorization(
        this.oldSelectedRowKeys.length,
        "périmètre de facturation sélèctionné",
        true,
        "aucun périmètre de facturation sélèctionné"
      )
    },
    getColumns() {
      return this.columns
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onSelectAll: this.onSelectAll,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: "select-to-complete",
            text: `Sélectionner tous les sites ${Constants.STATUS.TO_COMPLETE.text.toLowerCase()}`,
            onSelect: () => {
              this.selectedByState("TO_COMPLETE")
            },
          },
          {
            key: "select-complete",
            text: `Sélectionner tous les sites ${Constants.STATUS.COMPLETED.text.toLowerCase()}`,
            onSelect: () => {
              this.selectedByState("COMPLETED")
            },
          },
        ],
        onSelection: this.onSelection,
      }
    },
  },
  watch: {
    groupements: {
      deep: true,
      handler(groupements) {
        this.generateDataSource(groupements)
      },
    },
    selectedRowKeys(newValue, oldValue) {
      this.oldSelectedRowKeys = newValue.length === 0 ? [...oldValue] : [...newValue]
    },
    nb() {
      if (this.nbTotal && this.nbTotal === this.nb) {
        this.deleteGroupement()
      }
    },
  },
  mounted() {
    this.generateDataSource(this.groupements)
  },
  methods: {
    ...mapMutations(["setState"]),
    goTo(route) {
      const groupementId = route && route.params ? route.params.groupementId : null
      const groupement = this.groupements.find((g) => g.id === groupementId)
      this.setState({
        stateName: "groupement",
        value: groupement || {},
      })
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    updateSelectRowsOnFilterChange() {
      const dataSource = this.getDataSourceFiltered()
      this.selectedRowKeys = this.selectedRowKeys.filter((key) => {
        return dataSource.find((data) => {
          return key === data.key
        })
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      const list = this.dataSource.filter((data) => selectedRowKeys.includes(data.key)).map((data) => data.key)
      this.$emit("selectedChanged", list)
    },
    onChange(pagination, filters, sorter) {
      this.filters = filters
      this.updateSelectRowsOnFilterChange()
    },
    generateDataSource(groupements) {
      this.dataSource = []
      groupements.forEach((groupement, index) => {
        this.dataSource.push({
          key: index,
          id: groupement.id,
          nom: groupement.nom,
          commune: groupement.commune,
          budget: groupement.budget,
          siret: groupement.siret.toString(),
          sitesSize: groupement.sitesSize.toString(),
          state: groupement.state,
          typeFacturation: groupement.typeFacturation
        })
      })
      this.dataSource.sort((a, b) => b.id - a.id)
    },
    getDataSourceFiltered() {
      const toCheck = ["status", "fluide", "state"]
      let dataSourceFiltered = [...this.dataSource].sort((a, b) => b.id - a.id)
      toCheck.forEach((key) => {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) => this.filters[key].includes(data[key]))
        }
      })
      for (let key in this.searchInput) {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) =>
            data[key] ? data[key].toString().toLowerCase().includes(this.searchText[key].toLowerCase()) : null
          )
        }
      }
      return dataSourceFiltered
    },
    selectedByState(state) {
      let dataSourceFiltered = this.getDataSourceFiltered()
      dataSourceFiltered = dataSourceFiltered.filter((data) => data.state === state).map((data) => data.key)
      this.selectedRowKeys = dataSourceFiltered
    },
    onSelectAll(selected) {
      let dataSourceFiltered = []
      if (selected) {
        dataSourceFiltered = this.getDataSourceFiltered()
      }
      this.selectedRowKeys = dataSourceFiltered.map((data) => data.key)
      this.onSelectChange(this.selectedRowKeys)
    },
    handleSearch(fieldName, selectedKeys, confirm) {
      confirm()
      this.searchText[fieldName] = selectedKeys[0]
    },

    handleReset(fieldName, clearFilters) {
      clearFilters()
      this.searchText[fieldName] = ""
    },
    remove(groupement) {
      const that = this
      this.$confirm({
        title: `Supprimer le périmètre`,
        content: `Voulez-vous vraiment supprimer de façon irréversible le périmètre ${groupement.nom} ?`,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          that.groupementSuppr = groupement
          Axios("get", "api/groupements/" + that.groupementSuppr.id + "/sites").then((response) => {
            let sites = _.filter(
              _.flatMap(response.data._embedded, (value) => value),
              { removed: false }
            )
            that.nbTotal = sites.length
            if (that.nbTotal) {
              that.nb = 0
              for (var key in sites) {
                Axios("patch", "api/sites/" + sites[key].id, {
                  groupement: null,
                }).then((response) => {
                  that.nb++
                })
              }
            } else {
              that.deleteGroupement(groupement)
            }
          })
        },
        onCancel() {
          console.log(
            `%c Cancel delete groupement ${groupement.nom}`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
          )
        },
      })
    },
    deleteGroupement(groupement) {
      Axios("delete", "api/groupements/" + this.groupementSuppr.id)
        .then((reponse) => {
          this.$emit("initialize")
          this.$notification.success({
            message: `Périmètre Supprimé`,
            description: `Le périmètre ${groupement ? groupement.nom : ""} a bien été supprimé.`,
          })
          this.nbTotal = 0
          this.nb = 0
          this.groupementSuppr = null
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: `Erreur lors de la suppression`,
            description: `${
              groupement ? groupement.nom : "Le périmètre"
            } n'as pas pu être supprimé pour une raison inconnue.\n Veuillez contacter un administrateur`,
          })
        })
    },
    updateGroupement(groupementId, dataToUpdate) {
      this.$emit("updateGroupement", groupementId, dataToUpdate)
    },
  },
}
</script>

<style lang="scss"></style>
