<template>
  <section v-if="exist">
    <label>
      <font-awesome-icon icon="asterisk" class="red-text" />
      Statut
    </label>
    <select class="browser-default" v-model="field" required>
      <option value="" disabled selected>Sélectionnez un statut</option>
      <template v-if="context.added">
        <option value="NOUVEAU">Nouveau</option>
        <option value="EN_COURS_DE_RACCORDEMENT">En cours de raccordement</option>
      </template>
      <template v-else>
        <option value="ACTIF">Actif</option>
        <option value="REJETE">Rejeté</option>
        <option value="BRANCHEMENT_PROVISOIRE">Branchement provisoire</option>
        <!--<option value="INEXISTANT">Inexistant</option>-->
        <option value="RESILIATION">Résilié ou en cours de résiliation</option>
      </template>
    </select>
  </section>
</template>

<script>
import Mixin from "./Mixin"

export default {
  name: "site-status",
  mixins: [Mixin],
  computed: {
    exist() {
      return this.confOperation !== "CARREFOUR"
    },
  },
}
</script>

<style lang="scss" scoped></style>
