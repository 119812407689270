<template>
  <div class="flows">
    <a-steps :current="current">
      <a-popover slot="progressDot" slot-scope="{ index, status, prefixCls }">
        <template slot="content">
          <span>Etape {{ index + 1 }}</span>
        </template>
        <div class="bar-number" @click="goToSlide(index)">
          <span v-if="progression(index) === 'COMPLETED' || progression(index) === 'CERTIFIED'">
            <mcma-icon type="check" color="success" :faIcon="true" />
          </span>
          <span v-else>
            {{ index + 1 }}
          </span>
        </div>
      </a-popover>
      <a-step
        v-for="(flow, index) in getFlow"
        :class="{ notValidated: progression(index) !== 'COMPLETED' && progression(index) !== 'CERTIFIED' }"
        :key="index"
        :title="getFlowByKey(flow).shortTitle"
        @click="clickOnStep(getFlowByKey(flow))"
      />
      <a-step      
        v-if="membreStats.status !== 'APPROVED'"
        :class="{ notValidated: progression(index) !== 'COMPLETED' && progression(index) !== 'CERTIFIED' }"
        :title="flows[flows.length - 1].shortTitle"
        @click="clickOnStep(flows[flows.length - 1])"
      />
    </a-steps>
    <GeneralInfo v-on:unVisible="infoModalClosed" v-on:completed="infosCompleted" :visibleModal="visible" />
    <mcma-validate-parcours-modal
      :visible="openValidation"
      v-bind:infosGenCompleted="completedInfo"
      @save="save"
      @close="close"
    ></mcma-validate-parcours-modal>
    <!-- Card with hints for steps, deprecated cause steps integrated in header -->
    <!--<a-carousel
      :dots="false"
      ref="carousel"
      :infinite="false"
      class="flows-content"
      :beforeChange="changeStepTo"
      :class="`prev-slide-number-${current - 1} next-slide-number-${current + 1}`"
    >
      <flow-card
        v-for="(flow, index) in getFlow"
        :key="index"
        :index="index + 1"
        :title="getFlowByKey(flow).longTitle"
        :flowName="getFlowByKey(flow).name"
        :customDescribe="getFlowByKey(flow).customDescribe"
        :describe="getFlowByKey(flow).describe"
        :buttons="getFlowByKey(flow).buttons"
        :mycomment="stateComment(index)"
        :state="progression(index)"
        @refresh-flows="initFlows"
      />
    </a-carousel>
    <div
      class="custom-slick-arrow left-arrow"
      :style="{ bottom: `${heightOfFlows / 2 - carouselArrowPXSize / 2}px` }"
      @click.prevent="goToSlide(current > 0 ? current - 1 : current)"
      :class="{ disabled: current <= 0 }"
    >
      <mcma-icon type="chevron-left" :disabled="current <= 0" color="primary" :faIcon="true" />
    </div>
    <div
      class="custom-slick-arrow right-arrow"
      :style="{ bottom: `${heightOfFlows / 2 - carouselArrowPXSize / 2}px` }"
      @click.prevent="goToSlide(current < flows.length - 1 ? current + 1 : current)"
      :class="{ disabled: current >= flows.length - 1 }"
    >
      <mcma-icon type="chevron-right" :disabled="current >= flows.length - 1" color="primary" :faIcon="true" />
    </div>-->
  </div>
</template>

<script>
import McmaIcon from "@/components/mcma/common/McmaIcon"
import FlowCard from "@/components/mcma/steps/components/FlowCard"
import McmaButton from "@/components/mcma/common/McmaButton"
import Axios from "@/util/Axios"
import { accorization } from "@/util/Helpers"
import { mapGetters, mapMutations, mapState } from "vuex"
import GeneralInfo from "./GeneralInfo.vue"
import Constants from "@/util/Constants"
import McmaValidateParcoursModal from "./McmaValidateParcoursModal"

export default {
  name: "Flows",
  components: {
    FlowCard,
    McmaIcon,
    McmaButton,
    GeneralInfo,
    McmaValidateParcoursModal,
  },
  data() {
    return {
      current: 0,
      carousel: null,
      flows: [],
      waitProcess: false,
      documentsDownloaded: [],
      documentsUploaded: [],
      heightOfFlows: 100,
      carouselArrowPXSize: 50,
      visible: false,
      completedInfo: false,
      openValidation: false,
      parcoursFinish: false,
      mandatoryPieces: 0,
    }
  },
  computed: {
    ...mapState(["membreStats", "operation"]),
    ...mapGetters(["getFlow"]),
    getFlowByKey() {
      return (key) => {
        return this.flows.find((value) => value.key === key) || {}
      }
    },
  },
  methods: {
    ...mapMutations(["setState"]),
    close() {
      this.openValidation = false
    },
    save() {
      this.parcoursFinish = true
    },
    infosCompleted(value) {
      // !value car value renvoie false si les infos sont bien renseigné et true si elles le sont pas, ici completedinfo
      // vaut true si les infos sont complété et donc si infoNeeded == false
      this.completedInfo = !value
    },
    infoModalClosed(value) {
      this.visible = value
    },
    clickOnStep(flow) {
      const generalsInfosName = this.operation.flows.find((item) => item.name === "general").name
      const validateName = "Validation"
      if (flow.name !== generalsInfosName && flow.name !== validateName) {
        this.goTo(flow.buttons[0].routeName, flow.buttons[0].routeParams)
      } else if (flow.name !== validateName) {
        //info-gen case, on rend juste visible le composant info-gen
        this.visible = true
      } else if (flow.name === validateName) {
        this.openValidation = true
      }
    },
    stateComment(index) {
      const step = this.getFlow[index]
      let res = ""
      if (this.membreStats) {
        switch (step) {
          case "SERVICES":
            if (this.membreStats.statInterlocuteur.state === "COMPLETED") {
              res =
                "C'est bon ! Vous avez bien renseigné un interlocuteur pour la facturation et un pour le suivi technique."
            } else {
              res = "Il vous reste à ajouter un ou plusieurs interlocuteurs..."
            }
            break
          case "DOWNLOAD":
            if (this.membreStats.statPiece.nbPieceDownloaded === this.membreStats.statPiece.nbPiece) {
              res = "C'est bon ! Vous avez bien téléchargé l'ensemble des pièces."
            } else {
              res = "Il vous reste des pièces à télécharger."
            }
            break
          case "PERIMETRE":
            if (
              this.membreStats.statSite.size > 0 &&
              (this.membreStats.statSite.completed === this.membreStats.statSite.size ||
                this.membreStats.statSite.certified === this.membreStats.statSite.size)
            ) {
              res =
                "C'est bon ! Vous avez bien complété tous vos sites, mais vous pouvez toujours en ajouter d'autres si necessaire..."
            } else {
              res = "Il vous reste à compléter l'ensemble de vos sites..."
            }
            break
          case "FACTURATION":
            if (
              this.membreStats.statGroupement.size > 0 &&
              (this.membreStats.statGroupement.completed === this.membreStats.statGroupement.size ||
                this.membreStats.statGroupement.certified === this.membreStats.statGroupement.size) &&
              this.membreStats.statSite.size > 0 &&
              this.membreStats.statSite.notGrouped === 0
            ) {
              res = "C'est bon ! Vous avez bien complété tous vos périmètres et rattaché l'ensemble de vos sites."
            } else {
              res = "Il vous reste des périmètres de facturation à créer ou des sites à rattacher."
            }
            break
          case "UPLOAD":
            if (this.membreStats.statPiece.nbPieceUploaded === this.membreStats.statPiece.nbPiece) {
              res = "C'est bon ! Vous avez bien déposé l'ensemble des pièces ou nous les avions déjà réccupérées..."
            } else {
              res = "Il vous reste à déposer vos pièces si necessaire."
            }
            break
        }
      }
      return res
    },
    progression(index) {
      const step = this.getFlow[index]
      let res = "TO_COMPLETE"
      if (this.membreStats) {
        switch (step) {
          case "GENERAL":
            if (this.completedInfo) {
              res = "COMPLETED"
            }
            break
          case "SERVICES":
            if (this.membreStats.statInterlocuteur.state === "COMPLETED") {
              res = this.membreStats.statInterlocuteur.state
            }
            break
          case "DOWNLOAD":
            if (this.membreStats.statPiece.nbPieceDownloaded === this.membreStats.statPiece.nbPiece) {
              res = "COMPLETED"
            }
            break
          case "PERIMETRE":
            if (
              this.membreStats.statSite.size > 0 &&
              this.membreStats.statSite.completed + this.membreStats.statSite.certified ===
                this.membreStats.statSite.size
            ) {
              res = "COMPLETED"
            }
            break
          case "FACTURATION":
            if (
              this.membreStats.statGroupement.size > 0 &&
              this.membreStats.statGroupement.completed + this.membreStats.statGroupement.certified ===
                this.membreStats.statGroupement.size &&
              this.membreStats.statSite.size > 0 &&
              this.membreStats.statSite.notGrouped === 0
            ) {
              res = "COMPLETED"
            }
            break
          case "UPLOAD":
            if (this.membreStats.statPiece.nbPieceUploaded === this.mandatoryPieces) {
              res = "COMPLETED"
            }
            break
        }
      }
      return res
    },
    goTo(routeName, params) {
      this.$router.push({
        name: routeName,
        params: params,
      })
    },
    goToSlide(slideNumber) {
      if (!this.waitProcess) {
        this.waitProcess = true
        // this.$refs.carousel.goTo(slideNumber)
        this.current = slideNumber
        setTimeout(() => {
          this.waitProcess = false
        }, 700)
      }
    },
    changeStepTo(from, to) {
      this.current = to
    },
    async initFlows() {
      if (this.operation && this.operation.flows) {
        if (this.membreStats) {
          const generalsInfos = this.operation.flows.find((item) => item.name === "general")
          if (generalsInfos && generalsInfos.active) {
            this.flows = []
            this.flows.push({
              key: "GENERAL",
              shortTitle: "Infos Générales",
              longTitle: "Informations Générales",
              name: generalsInfos.name,
              customDescribe: generalsInfos.cardDescribe,
              buttons: [
                {
                  title: "Renseigner les infos",
                },
              ],
            })
          }
          const documentsDownload = this.operation.flows.find(
            (item) => item.name === "documents-download" || item.name === "piecetelechargement"
          )
          if (documentsDownload && documentsDownload.active) {
            // this.flows.splice(0, 0, {
            try {
              this.documentsDownloaded = await this.requestDocumentsMembreAlready("downloaded")
            } catch (error) {
              console.log(
                `%c error`,
                "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
                error
              )
            }
            this.flows.push({
              key: "DOWNLOAD",
              shortTitle: "Pièces à télécharger",
              longTitle: "Télécharger des pièces",
              name: documentsDownload.name,
              customDescribe: documentsDownload.cardDescribe,
              describe: `Vous avez téléchargé ${accorization(
                this.documentsDownloaded,
                "piéce",
                true,
                "aucune piéce"
              )}.`,
              buttons: [
                {
                  title: "Télécharger les documents",
                  routeName: "documents_down",
                  routeParams: { action: "download" },
                },
              ],
            })
          }

          const sites = this.operation.flows.find((item) => item.name === "sites")
          if (sites && sites.active) {
            this.flows.push({
              key: "PERIMETRE",
              shortTitle: "Sites",
              longTitle: "Valider ou compléter vos sites",
              name: sites.name,
              customDescribe: sites.cardDescribe,
              describe: `Votre périmètre contient actuellement ${accorization(
                this.membreStats.statSite.size,
                "site",
                true,
                "aucun site"
              )}.`,
              buttons: [
                {
                  title: "Mes sites",
                  routeName: "sites",
                },
              ],
            })
          }

          const groupements = this.operation.flows.find((item) => item.name === "groupements")
          if (groupements && groupements.active) {
            // this.flows.splice(2, 0, {
            this.flows.push({
              key: "FACTURATION",
              shortTitle: "Périmetres de facturation",
              longTitle: "Définir vos périmètres de facturation",
              name: groupements.name,
              customDescribe: groupements.cardDescribe,
              describe: `Vous avez défini ${accorization(
                this.membreStats.statGroupement.size,
                `périmètre`,
                true,
                "aucun périmètre"
              )} de facturation et il vous reste ${accorization(
                this.membreStats.siteNotGrouped,
                "site",
                true,
                "aucun site"
              )} à associer.`,
              buttons: [
                {
                  title: "Mes périmètres de facturation",
                  routeName: "groupements",
                },
              ],
            })
          }

          const services = this.operation.flows.find((item) => item.name === "services")
          if (services && services.active) {
            // this.flows.splice(3, 0, {
            this.flows.push({
              key: "SERVICES",
              shortTitle: "Interlocuteurs",
              longTitle: "Interlocuteurs",
              name: services.name,
              customDescribe: services.cardDescribe,
              describe: `Vous avez défini ${accorization(
                this.membreStats.statInterlocuteur.size,
                "interlocuteur",
                true,
                "aucun interlocuteur"
              )}.`,
              buttons: [
                {
                  title: "Mes interlocuteurs",
                  routeName: "interlocuteurs",
                },
              ],
            })
          }

          const documents = this.operation.flows.find((item) => item.name === "documents")
          if (documents && documents.active) {
            // this.flows.splice(4, 0, {
            this.flows.push({
              key: "DOCUMENTS",
              shortTitle: "Documents & Pièces",
              longTitle: "Documents & Pièces",
              name: documents.name,
              customDescribe: documents.cardDescribe,
              describe: `Déposez et téléchargez vos documents.`,
              buttons: [
                {
                  title: "Télécharger des documents",
                  routeName: "documents",
                  routeParams: { action: "download" },
                },
                {
                  title: "Déposer des documents",
                  routeName: "documents",
                  routeParams: { action: "upload" },
                },
              ],
            })
          }

          const documentsUpload = this.operation.flows.find(
            (item) => item.name === "documents-upload" || item.name === "piecedepot"
          )
          if (documentsUpload && documentsUpload.active) {
            try {
              this.documentsUploaded = await this.requestDocumentsMembreAlready("uploaded")
            } catch (error) {
              console.log(
                `%c error`,
                "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
                error
              )
            }
            // this.flows.splice(5, 0, {
            this.flows.push({
              key: "UPLOAD",
              shortTitle: "Pièces à déposer",
              longTitle: "Charger les pièces",
              name: documentsUpload.name,
              customDescribe: documentsUpload.cardDescribe,
              describe: `Vous avez déposé et signé ${accorization(
                this.documentsUploaded,
                "piéce",
                true,
                "aucune piéce"
              )}.`,
              buttons: [
                {
                  title: "Déposer les documents",
                  routeName: "documents",
                  routeParams: { action: "upload" },
                },
              ],
            })
          }
          if (this.membreStats && this.membreStats.status !== Constants.STATUS.APPROVED.value) {
            this.flows.push({
              key: "VALIDATE",
              shortTitle: "Valider mon parcours",
              longTitle: "Valider et télécharger",
              name: "Validation",
              buttons: [
                {
                  title: "Valider",
                },
              ],
            })
          }
        }
      }
    },
    requestDocumentsMembreAlready(downloadOrUpload) {
      console.log("requestDocumentsMembreAlready@Flow this.membreStats : %O", this.membreStats)
      return Axios("get", `file/pieceMembre/${downloadOrUpload}/?membreId=${this.membreStats.id}`)
        .then((response) => {
          return response.data || []
        })
        .catch((error) => {
          console.log(
            `%c error`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            error
          )
          return error
        })
    },
    getHeightOfFlows() {
      if (this.flows && this.flows.length > 0) {
        let flowHeight = document.querySelector(".flows")
        let antStepsHeight = document.querySelector(".ant-steps")
        if (!flowHeight || !antStepsHeight) {
          return 100
        }
        if (flowHeight) {
          flowHeight = flowHeight.offsetHeight
        }
        if (antStepsHeight) {
          antStepsHeight = antStepsHeight.offsetHeight
        }
        return flowHeight - antStepsHeight - 20 - 12 // 20 = marging-top of carousel / 12 = padding-top of carousel
      }
      return 100
    },
    handleResize() {
      this.heightOfFlows = this.getHeightOfFlows()
    },
  },
  watch: {
    membreStats: {
      handler() {
        this.initFlows()
      },
      deep: true,
    },
  },
  async created() {
    await this.initFlows()
    this.heightOfFlows = this.getHeightOfFlows()
    window.addEventListener("resize", this.handleResize)
    Axios("get", "api/pieces/search/findByOperationIdEquals?size=2000&operationId=" + this.operation.id).then(
      (response) => {
        let pieces = response.data._embedded.pieces
        console.log("this.pieces : %O", response.data._embedded.pieces)
        pieces.forEach((p) => {
          if(p.mandatory_upload){
            this.mandatoryPieces = this.mandatoryPieces + 1
          }
        })
      }
    )
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
  },
}
</script>
<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.flows {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 0;
  height: flex;
  .ant-steps {
    width: 70%;
  }
  .ant-steps-item {
    &.notValidated {
      .ant-steps-item-container {
        .ant-steps-item-content {
          .ant-steps-item-title {
            color: orangered;
          }
        }
      }
    }
  }
  .ant-steps-item-process {
    .ant-steps-item-icon {
      width: 30px;
      height: 30px;
    }
    & > .ant-steps-item-content {
      & > .ant-steps-item-title {
      }
    }
  }
  .ant-steps-item-content {
    & > .ant-steps-item-title {
      cursor: pointer;
      transition: color 0.5s ease;
      font-weight: 600;
    }
  }
  .ant-steps-item-tail {
    margin: 0 0 0 80px;
    &:after {
      width: calc(100% - 50px);
    }
  }
  .ant-steps-item-wait {
    .bar-number {
      span {
        color: $border-color-base !important;
      }
    }
  }
  .ant-steps-item-icon {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: 53px;
    .bar-number {
      display: flex;
      width: 30px;
      min-width: 30px;
      height: 30px;
      min-height: 30px;
      border-radius: 5px;
      box-shadow: $box-shadow;
      background: $white;
      cursor: pointer;
      span {
        transition: color 0.5s ease;
        margin: auto;
        font-weight: 600;
        color: var(--primary-color, $primary-color);
        font-size: 15px;
        line-height: 15px;
      }
    }
  }
  .flows-content {
    margin-top: 40px;
    &.ant-carousel {
      overflow: hidden;
      width: calc(100% + 30px + 30px);
      padding: 12px 0;
      .slick-track {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }
      .slick-list {
        overflow: visible;
        .slick-slide {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          &.slick-active {
            & > div {
              margin-right: 0 !important;
              margin-left: 0 !important;
              /*transform: scale(1, 1);*/
            }
          }
          & > div {
            transition: margin-left 0.3s linear, margin-right 0.3s linear;
            /*transform: scale(0.95, 0.95);*/
            width: 70%;
            box-shadow: $box-shadow;
            background: #364d79;
            border-radius: 4px;
          }
        }
      }
    }
    &.prev {
      @for $i from 0 through 20 {
        &-slide-number-#{$i} .slick-slide[data-index="#{$i}"] {
          > div {
            margin-right: -40%;
          }
        }
      }
    }
    &.next {
      @for $i from 0 through 20 {
        &-slide-number-#{$i} .slick-slide[data-index="#{$i}"] {
          > div {
            margin-left: -40%;
          }
        }
      }
    }
  }
  .custom-slick-arrow {
    cursor: pointer;
    width: 50px;
    height: 50px;
    font-size: 25px;
    color: var(--primary-color, $primary-color);
    background: $white;
    box-shadow: $box-shadow;
    border-radius: 50px;
    display: flex !important;
    position: absolute;
    &.left-arrow {
      left: 7.5%;
      z-index: 1;
      .mcma-icon {
        .content {
          margin-left: -2px;
          margin-bottom: 6px;
        }
      }
    }
    &.right-arrow {
      right: 7.5%;
      .mcma-icon {
        .content {
          margin-right: -2px;
          margin-bottom: 6px;
        }
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
    .mcma-icon {
      margin: auto;
      .content {
        display: flex;
        .icon-background {
          margin: auto;
          svg {
            transition: color 0.5s linear;
          }
        }
      }
    }
  }
}
</style>
