/* eslint-disable standard/computed-property-even-spacing */
<template>
  <section>
    <site-edit-localisation
      v-if="site"
      ref="siteEditLocalisation"
      @deletedStatus="stateDeletedStatus = $event"
      @update="update"
      :site="site"
    />
    <mcma-divider class="mb20" />
    <site-interlocuteur-form
      :name="getNbEtapes === 2 ? 'Valider le site' :'Passer à l\'étape suivante'"
      :site="site"
      ref="siteInterlocuteurForm"
      :isDeletedStatus="stateDeletedStatus"
      @save="submitAll"
      @reset="resetAll"
    />
  </section>
</template>

<script>
import McmaDivider from "@/components/mcma/common/McmaDivider"
import SiteEditLocalisation from "@/components/mcma/steps/sites/components/SiteEditLocalisation"
import SiteInterlocuteurForm from "@/components/mcma/steps/sites/components/SiteInterlocuteurForm"
import getEtapes from "@/components/mcma/steps/sites/Etapes"
import { mapState, mapGetters } from "vuex"
export default {
  name: "etape_1",
  props: ["site", "isDeletedStatus"],
  components: {
    McmaDivider,
    SiteEditLocalisation,
    SiteInterlocuteurForm,
  },
  data() {
    return {
      stateDeletedStatus: null,
      name: String,
    }
  },
  watch: {
    stateDeletedStatus: function (val) {
      this.stateDeletedStatus = val
    },
  },
  computed: {
    ...mapState(["operation", "user"]),
    ...mapGetters(["getFlow"]),
    getNbEtapes() {
      return getEtapes(this.operation, this.getFlow, this.user, this.site).length
    },
  },
  methods: {
    submitAll(interlocuteurId) {
      console.log("submitAll(interlocuteurId)")
      console.log("interlocuteurId", interlocuteurId)
      console.log(
        "this.$refs.siteInterlocuteurForm.getSelectedInterlocuteurId()",
        this.$refs.siteInterlocuteurForm.getSelectedInterlocuteurId()
      )
      const interId = interlocuteurId || this.$refs.siteInterlocuteurForm.getSelectedInterlocuteurId()
      console.log("interId", interId)
      this.$refs.siteEditLocalisation.submit(interId)
    },
    resetAll() {
      this.$refs.siteEditLocalisation.initialize()
    },
    update(site) {
      this.$emit("update", site)
    },
  },
}
</script>

<style lang="scss">
.interlocuteur-wrapper {
  display: inline-block;
  width: 100%;
  border: 1px solid #ddd;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  & ul {
    padding: 20px;
  }
  & div {
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
}
</style>
