<template>
  <mcma-card title="Guide Administrateur" icon="question-solid">
    <template #describe>
      Voici un guide pour les administrateurs afin d'aider à la compréhension et l'utilisation de certaines fonctionnalités
      de l'application. Merci d'y prêter une forte attention car sa bonne compréhension peut prévenir des problèmes d'utilisation
      récurrent.
      <br><br>
      INTEGRER ICI FAQ
    </template>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"

export default {
  name: "GuideHeader",
  components: {
    McmaCard,
  },
}
</script>

<style lang="scss"></style>