<template>
  <!-- <a :href="myPdf" :download="'Guide_' + page">
      <i class="material-icons">help</i>
    </a> -->
  <div class="help" :class="{ floatLeft: float === 'floatLeft', floatRight: float ==='floatRight' }">
    <a v-if="page && page !== ''" :href="myPdf" :download="'Guide_' + page">
      <i class="material-icons">help</i>
    </a>
    <a v-else @click="visible = true">
      <i class="material-icons">help</i>
    </a>
    <a-modal title="Aide" :visible="visible" class="mcma-tips-modal" :maskClosable="false" :width="1200">
      <pre class="dataTips">
      <p v-html="data"></p>
    </pre
      >
      <div class="align-row-end mtb12">
        <mcma-button name="J'ai compris" color="blue" class="ok mr7" @click="handleOk" />
      </div>
    </a-modal>
  </div>
</template>


<script>
import McmaButton from "@/components/mcma/common/McmaButton"
export default {
  name: "Tips",
  components: {
    McmaButton,
  },
  data() {
    return {
      //need real pdf for each pages
      myPdf: null,
      visible: false,
    }
  },
  props: {
    page: String,
    data: String,
    title: String,
    float: String,
  },

  methods: {
    handleOk() {
      this.visible = false
    },
  },
  mounted() {
    if (this.page) {
      this.myPdf = require("@/assets/pdf/" + this.page + ".pdf")
    }
  },
}
</script>
<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";
.help {
  &.floatLeft {
    float: left;
  }
  &.floatRight{
    float:right;
  }
}
.dataTips{
  font-size:18px;
}
.mcma-tips-modal {
  font-family: $font-family !important;
  .ant-modal-content {
    .ant-modal-footer {
      display: none;
    }
  }
  .container-list {
    display: flex;
    flex-flow: column nowrap;
  }
}
</style>
