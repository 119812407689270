<template>
  <main>
    <place-holder :notReady="!groupement">
      <section class="container" v-if="groupement">
        <div class="row">
          <div class="col s12">
            <article class="card">
              <div class="card-content">
                <span class="card-title">
                  Gestion en masse du rattachements de plusieurs Sites à un Périmètre de Facturation
                </span>
                <blockquote>
                  Rattacher massivement vos sites au périmètre de facturation "{{ groupement.nom }}" 
                   <br/> 
                  Pour ce faire ajouter une liste de numéro de PDL dans l'espace ci-dessous
                   <br/> 
                  Cliquer sur Valider la liste
                  <br/>
                  Puis confirmer le rattachement
                </blockquote>
                <blockquote>
                  <span v-if="sitesAlreadyAttachedInPF">
                    <strong>
                    Certains sites de la liste des PDL sont déjà rattachés dans ce périmètre.
                    </strong>
                    <br/>
                  </span>
                  <span v-if="sitesNotExist">
                    <strong class="red--text text--lighten-1">
                    ATTENTION certains sites n'ont pas été trouvés dans votre périmètre à partir de la liste des n° de PDL ! Veuillez revenir à l'étape site pour les ajouter si nécessaire.
                    </strong>
                    <br/>
                  </span>
                  <span v-if="sitesAlreadyAttached">
                    <strong class="red--text text--lighten-1">
                    ATTENTION certains sites sont déjà rattachés à d'autres pérmiètres ! Veuillez vous assurer de vouloir les affecter à ce pérmiètre avant de confirmer.
                    </strong>
                    <br/>
                  </span>
                  
                </blockquote>
                <div v-if="groupement && groupement.monoSite && membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'">
                  <i class="material-icons">warning</i> Ce périmètre de facturation contient un site configuré en
                  "facture monosite". Vous ne pouvez donc pas lui affecter d'autres sites tant que cela est le cas.
                </div>
              </div>
              <center class="card-action" v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'">
                <button
                  v-if="sitesToLink"
                  type="button"
                  class="waves-effect waves-light btn light-green"
                  @click="submit"
                  id="validGroupValidSites"
                >
                <i class="material-icons">camera</i>
                  Vérifier la liste de PDL
                </button>
                <button
                  v-if="sites.length > 0"
                  type="button"
                  class="waves-effect waves-light btn green"
                  @click="confirm"
                  id="confirmGroupValidSites"
                >
                <i class="material-icons">save</i>
                  Confirmer le rattache
                </button>
                <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
                  <i class="material-icons">remove</i>
                  Réinitialiser
                </button>
              </center>
            </article>
          </div>
        </div>
      </section>
    </place-holder>
    <place-holder :notReady="!groupement">
      <section class="container" v-if="groupement">
        <div class="row">
          <div class="col s12">
            <article class="card">
              <div class="card-content">
                <label :class="{ active: field }" for="pdl">
            <font-awesome-icon icon="asterisk" class="red-text" />
            Copier la liste de vos PDL
        </label>
          <textarea id="pdl" showCount v-model="sitesToLink" autoSize="{ minRows: 10, maxRows: 20 }"></textarea>
              </div>
    </article>
          <article class="card">
              <div class="card-content">
                <label :class="{ active: field }" for="pdlNE">
            <font-awesome-icon class="red-text" />
            Liste des sites non trouvés
        </label>
          <textarea id="pdlNE" v-model="sitesNotExist" readonly="true" autoSize="{ minRows: 6, maxRows: 10 }"></textarea>
              </div>
    </article>
    </div>
        </div>
      </section>
    </place-holder>
    <section class="row">
      <article class="col s6" id="validGroupSitesToAdd">
        <place-holder :notReady="!sites">
          <grid
            v-if="sites"
            name="Liste des sites qui seront rattachés"
            :rows="sites"
            :columns="columns"
            :search="true"
            :columnHeader="columnHeader"
            :columnsNoSearch="['Actions']"
            :columnsListFilter="columnsListFilter"
            :multiSelect="groupement && !groupement.monoSite && membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
            @select="(value) => (sitesSelected = value)"
          >
            <!--
              <template slot="header">
              <a
                href
                @click.prevent="add(sitesSelected)"
                class="waves-effect waves-light btn light-green"
                v-if="!groupement.monoSite && membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
                id="validGroupSiteCheck"
              >
                <i class="material-icons">send</i>
                Rattacher en une fois les sites sélectionnés ci-dessous au périmètre de facturation.
              </a>
            </template>
            -->
            <template slot="state" slot-scope="props">
              <router-link
                :to="{ name: 'site', params: { id: props.row.id } }"
                class="chip white-text deep-orange"
                v-if="props.row.state === 'TO_COMPLETE'"
              >
                <i class="material-icons">edit</i>
              </router-link>
              <router-link :to="{ name: 'site', params: { id: props.row.id } }" class="chip white-text lime" v-else>
                <i class="material-icons">check</i>
              </router-link>
            </template>
            <!--
            <center slot="Actions" slot-scope="props">
              <a
                href
                @click.prevent="add([props.row])"
                class="validGroupSiteAdd chip green"
                v-tooltip.auto="'Ajouter au périmètre de facturation'"
              >
                <i class="material-icons small white-text">send</i>
              </a>
            </center>
            -->
          </grid>
        </place-holder>
      </article>
      <article class="col s6" id="validGroupSitesToRemove">
        <place-holder :notReady="!sitesGrouped">
          <grid
            v-if="sitesGrouped"
            name="Sites déjà associés à ce périmètre de facturation"
            :rows="sitesGrouped"
            :columns="columnsGrouped"
            :search="true"
            :columnHeader="columnHeader"
            :columnsNoSearch="['Actions']"
            :columnsListFilter="columnsListFilter"
            :multiSelect="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
            @select="(value) => (sitesGroupedSelected = value)"
          >
          <!--
            <template slot="header">
              <a
                href
                @click.prevent="remove(sitesGroupedSelected)"
                class="waves-effect waves-light btn deep-orange"
                v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
                id="validGroupSiteRemCheck"
              >
                <i class="material-icons">delete</i>
                Détacher en une fois les sites sélectionnés ci-dessous.
              </a>
            </template>
            -->
            <!--
            <template slot="multiSite" slot-scope="props">
              <span v-if="props.row.multiSite">
                Oui
              </span>
              <span v-else>
                Non
              </span>
              <i class="material-icons pointer" @click="toggleMono(props.row)"
                >edit</i
              >
            </template>
            -->
            <template slot="state" slot-scope="props">
              <router-link
                :to="{ name: 'site', params: { id: props.row.id } }"
                class="chip white-text deep-orange"
                v-if="props.row.state === 'TO_COMPLETE'"
              >
                <i class="material-icons">edit</i>
              </router-link>
              <router-link
                :to="{ name: 'site', params: { id: props.row.id } }"
                class="chip white-text amber"
                v-else-if="props.row.state === 'TO_CERTIFY'"
              >
                <i class="material-icons">send</i>
              </router-link>
              <router-link :to="{ name: 'site', params: { id: props.row.id } }" class="chip white-text lime" v-else>
                <i class="material-icons">check</i>
              </router-link>
            </template>
            <!--
            <template slot="Actions" slot-scope="props">
              <center>
                <a
                  href
                  @click.prevent="remove([props.row])"
                  class="validGroupSiteRem chip red"
                  v-tooltip.auto="'Supprimer du périmètre de facturation'"
                >
                  <i class="material-icons white-text">delete</i>
                </a>
              </center>
            </template>
            -->
          </grid>
        </place-holder>
      </article>
    </section>
  </main>
</template>

<script>
import GuardiansMixin from "@/components/mcma/steps/guardians/GuardiansMixin"
import Axios from "@/util/Axios"
import { mapState, mapMutations } from "vuex"
import Grid from "@/components/common/Grid"
import _ from "lodash"
import Materialize from "materialize-css"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "GroupementsLinkMass",
  mixins: [GuardiansMixin],
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      flow: "FACTURATION",
      sitesGroupedInitial: null,
      sitesGrouped: null,
      sitesToLink: null,
      sitesNotExist: null,
      sitesGroupedSelected: [],
      sites: [],
      sitesAlreadyAttached: false,
      sitesAlreadyAttachedInPF: false,
      sitesAll: null,
      sitesSelected: [],
      columnHeader: {
        nom: "Nom",
        rae: "RAE",
        // multiSite: 'Multisite',
        state: "Progression",
        groupementNom: "Rattaché actuellement à",
      },
      columnsListFilter: {
        state: {
          TO_COMPLETE: "À completer",
          TO_CERTIFY: "À certifier",
          CERTIFIED: "Exporté",
        },
      },
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
    columns() {
      if (this.groupement) {
        if (this.groupement.monoSite || this.membre.status === "EXPORTED" || this.membre.status === "APPROVED") {
          return ["nom", "rae", "groupementNom", "state"]
        }
      }
      //return ["nom", "rae", "groupementNom", "state", "Actions"]
      return ["nom", "rae", "groupementNom", "state"]
    },
    columnsGrouped() {
      if (this.membre.status === "EXPORTED" || this.membre.status === "APPROVED") {
        return ["nom", "rae", "state"]
      }
      //return ["nom", "rae", "state", "Actions"]
      return ["nom", "rae", "state"]
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    add(sites) {
      sites.forEach((site) => {
        site.selected = false
      })
      this.sites = [..._.pullAll(this.sites, sites)]
      this.sitesGrouped.push(...sites)
      if (sites === this.sitesSelected) {
        this.sitesSelected = []
      } else {
        this.sitesSelected = [..._.pullAll(this.sitesSelected, sites)]
      }
    },
    remove(sites) {
      sites.forEach((site) => {
        site.selected = false
      })
      this.sitesGrouped = [..._.pullAll(this.sitesGrouped, sites)]
      this.sites.push(...sites)
      if (sites === this.sitesGroupedSelected) {
        this.sitesGroupedSelected = []
      } else {
        this.sitesSelected = [..._.pullAll(this.sitesGroupedSelected, sites)]
      }
    },
    initialize() {
      this.groupement = null
      this.sites = []
      this.sitesAll =[]
      this.sitesGrouped = null
      this.sitesToLink = null
      this.sitesAlreadyAttached = false
      this.sitesAlreadyAttachedInPF = false
      this.sitesNotExist = null

      Axios("get", "api/groupements/" + this.$route.params.groupementId).then((response) => {
        this.groupement = response.data
        this.setBreadcrumbAdd({
          name: "Liens pour : " + this.groupement.nom || this.groupement.siret,
          action: () => {},
        })
      })
      Axios("get", "api/membres/" + this.membre.id + "/sites").then((response) => {
        const sites = _.filter(
          _.flatMap(response.data._embedded, (value) => value),
          { removed: false }
        )
        // this.sites = sites.filter((site) => site.status !== "REJETE" && site.status !== "INEXISTANT")
        this.sitesAll = sites.filter((site) => site.status !== "REJETE" && site.status !== "INEXISTANT")
        this.sites = []
        this.recadrage()
      })

      Axios("get", "api/groupements/" + this.$route.params.groupementId + "/sites").then((response) => {
        const sitesGrouped = _.filter(
          _.flatMap(response.data._embedded, (value) => value),
          { removed: false }
        )
        this.sitesGrouped = sitesGrouped.filter((site) => site.status !== "REJETE" && site.status !== "INEXISTANT")
        this.sitesGroupedInitial = _.map(this.sitesGrouped, (site) => {
          return site.id
        })
        this.recadrage()
      })
    },
    recadrage() {
      if (this.sites && this.sitesGrouped) {
        this.sites = _.differenceBy(this.sites, this.sitesGrouped, "id")
      }
    },
    confirm() {
      for (let key in this.sites) {
          const site = this.sites[key]
          console.log('link site ' + site.id + ' to ' + this.groupement.id)
          Axios("patch", "api/sites/" + site.id, {
              groupement: process.env.VUE_APP_API_URL + "/api/groupements/" + this.groupement.id,
          })
        }
        Materialize.toast("Liaison sites / facturation sauvegardée", 4000, "lime")
        this.$router.push({
          name: "groupements",
        })
    },
    submit() {
      this.sitesAlreadyAttached = false
      this.sitesAlreadyAttachedInPF = false 
      let valid = true

      console.log('this.sitesToLink', this.sitesToLink)
      var re = / |;|,|\n|'/;
      const listPdl = this.sitesToLink.split(re)
      const listPdlNoempty = listPdl.filter(a => a != "")
      console.log('test split')
      console.log('listPdl', listPdl)
      console.log('listPdlNoempty', listPdlNoempty)
      this.sitesToLink = listPdlNoempty.join(', ')
      //Map list
      let siteNotExist = []
      let siteExist = []
      let siteIdLink = []

      for(var idxSiteTL in listPdlNoempty) {
        // console.log(listPdlNoempty[siteTL])
        var founded = false 
        for(var idxSite in this.sitesAll) {
          // console.log('--> ', this.sitesAll[idxSite].rae)
          if(this.sitesAll[idxSite].rae === listPdlNoempty[idxSiteTL]) {
            // n'ajoute que les sites qui ne sont pas déjà rattaché au groupement 
            if(this.sitesAll[idxSite].groupementId!=this.groupement.id){
              // indique si des sites sont déjà rattaché à d'autre site
              if(this.sitesAll[idxSite].groupementId!=null){
                this.sitesAlreadyAttached = true
              }
              siteIdLink.push(this.sitesAll[idxSite].id)
              siteExist.push(this.sitesAll[idxSite])
            } else {
              this.sitesAlreadyAttachedInPF = true
            }
            founded = true
            break
          }
        }
        if(founded===false) {
          siteNotExist.push(listPdlNoempty[idxSiteTL])
        }
      }
      this.sites = siteExist
      console.log('siteNotExist', siteNotExist)
      console.log('siteIdLink', siteIdLink)
      console.log('this.site', this.sites)
      this.sitesNotExist = siteNotExist.join(', ')

      /*
      if (valid) {
        for (let key in this.sites) {
          const site = this.sites[key]
          if (this.sitesGroupedInitial.includes(site.id)) {
            Axios("patch", "api/sites/" + site.id, {
              groupement: null,
            })
          }
        }
        for (let key in this.sitesGrouped) {
          const site = this.sitesGrouped[key]
          if (!this.sitesGroupedInitial.includes(site.id)) {
            Axios("patch", "api/sites/" + site.id, {
              groupement: process.env.VUE_APP_API_URL + "/api/groupements/" + this.groupement.id,
            })
          }
        }
        Materialize.toast("Liaison sites / facturation sauvegardée", 4000, "lime")
        this.$router.push({
          name: "groupements",
        })
      } else {
        Materialize.toast(
          "Vous ne pouvez pas lier un site à mono facturation avec d'autres sites. Vous devez créer un périmètre de facturation spécifique pour chacun de vos sites à facturation mono-site",
          20000,
          "deep-orange"
        )
      }
      */
    },
    toggleMono(site) {
      console.log(site)
      if (
        window.confirm(
          "Voulez vous vraiment passer le site " + site.nom + " en " + (site.multiSite ? "monosite" : "multisite") + "?"
        )
      ) {
        Axios("patch", "api/sites/" + site.id, {
          multiSite: !site.multiSite,
        })
          .then((response) => {
            Materialize.toast("Site modifié", 4000, "lime")
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Association",
      action: () => {},
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.material-icons.pointer {
  transition: 0.3s;
  border-radius: 15px;
  padding: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
