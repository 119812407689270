<template>
  <mcma-card :title="getTitle" icon="edit_site" class="mcma-card-interlocuteur-detail-header" :inline="true" :tips="true" :data="dataHelp">
    <template #describe>
      <span v-if="interlocuteur.id"> Editer l'interlocuteur en remplissant le formulaire ci-dessous. </span>
      <span v-else> Ajout d'un interlocuteur en remplissant le formulaire ci-dessous. </span>
    </template>
    <!--    <template #headerRightSide>-->
    <!--      <mcma-button-->
    <!--        v-if="interlocuteur.id && membre.stateInterlocuteurs === Constants.STATUS.COMPLETED.value"-->
    <!--        name="Certifier l'intelocuteur"-->
    <!--        color="success"-->
    <!--        @click="-->
    <!--          goTo({-->
    <!--            name: 'validate-interlocuteur',-->
    <!--            params: {id: interlocuteur.id}-->
    <!--          })-->
    <!--        "-->
    <!--      />-->
    <!--    </template>-->
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapMutations, mapState } from "vuex"
import Constants from "@/util/Constants"
import Tips from "@/components/mcma/help/contactUs/Tips"

export default {
  name: "InterlocuteursDetailCardHeader",
  data(){
    return{
      dataHelp: "<strong>Conseil pour éviter les saisies multiples</strong> : Pensez à utiliser le bouton « Dupliquer » lorsqu’un même interlocuteur cumule des rôles différents \n(exemple : interlocuteur facturation et administrateur espace client) .Pour le créer en autant d’exemplaires que de rôles. <strong>Vous n’aurez pas besoin de multiplier les saisies à l’identique</strong>. \nEn effet vous disposez du bouton « dupliquer » qui vous épargne les saisies multiples.\n<strong>A NOTER</strong> : A tout moment durant votre parcours (étapes « Sites » et « Périmètres de facturation ») il est possible de modifier ou rajouter des interlocuteurs supplémentaires.",
    }
  },
  components: {
    McmaCard,
    McmaButton,
    Tips,
  },
  props: {
    interlocuteur: Object,
  },
  computed: {
    ...mapState(["membre"]),
    getTitle() {
      if (this.interlocuteur.id) {
        return `Édition de l'interlocuteur ${this.interlocuteur.prenom} ${this.interlocuteur.nom}`
      }
      return "Ajout d'un nouvel interlocuteur"
    },
    Constants() {
      return Constants
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    goTo(route) {
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: this.interlocuteur.id ? `Édition` : "Nouveau",
      action: () => {},
    })
  },
}
</script>

<style lang="scss"></style>
