<template>
  <a-form :form="form">
    <a-row type="flex" :gutter="12">
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="nom"
        label="Nom"
        placeholder="DUPONT"
        :defaultValue="interlocuteur.nom"
        :required="true"
        :uppercase="true"
      />
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="prenom"
        label="Prénom"
        placeholder="John"
        :defaultValue="interlocuteur.prenom"
        :required="true"
        :capitalize="true"
      />

      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.mail"
        fieldName="mail"
        label="Email"
        placeholder="john@gmail.com"
        :required="true"
        :requiredMessage="`Merci de renseigner l'email ${getInterName(form.getFieldValue('prenom'))}`"
        rule="isEmail"
      />

      <mcma-field
        type="select"
        :form="form"
        :wasSubmit="wasSubmit"
        getFlow="name::services"
        getPanel="name::Interlocuteurs"
        getField="type::civility"
      />
      <mcma-field
        type="input"
        :tips="true"
        data="Il est demandé de renseigner <strong>au moins un n° de téléphone (fixe ou mobile)</strong>. Lors de la création d’un nouvel 
interlocuteur, les 2 champs (tel fixe / tel mobile) sont marqués « obligatoire » avec un astérisque. Dès que l’un 
des deux est renseigné, l’autre redevient facultatif."
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.telephone"
        fieldName="telephone"
        label="Téléphone Fixe"
        placeholder="01 02 03 04 05"
        :requiredMessage="`Merci d'indiquer le téléphone fixe ou le téléphone mobile`"
        :required="!form.getFieldValue('mobile')"
        rule="isPhone"
      />
      <mcma-field
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.mobile"
        fieldName="mobile"
        label="Téléphone Mobile"
        placeholder="06 02 03 04 05"
        :required="!form.getFieldValue('telephone')"
        :requiredMessage="`Merci d'indiquer le téléphone fixe ou le téléphone mobile`"
        rule="isPhone"
      />

      <mcma-field
        type="select"
        :tips="true"
        data="Renseigner ici la fonction « officielle » de l’interlocuteur au sein de votre entité"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.fonction"
        getFlow="name::services"
        getPanel="name::Interlocuteurs"
        getField="type::fonction"
        :requiredMessage="`Renseignez ici la fonction officielle de l'interlocuteur au sein de votre entité`"
        :required="true"
      />

      <mcma-field
        type="select"
        :tips="true"
        data="Rôle : Sélectionner le rôle que jouera l’interlocuteur par rapport au contrat.
- CONTRAT : désigne la (les) personnes qui sera(ont) le(s) contact(s) privilégié(s) au sein de votre entité pour le suivi courant du marché;
- Seuls les interlocuteurs dont le rôle indiqué est <strong>TECHNIQUE</strong> apparaitront lors de la saisie des 
informations liées <strong>aux sites (étape 3)</strong> ;
- Seuls les interlocuteurs dont le rôle indiqué est <strong>FACTURATION</strong> apparaitront lors de la saisie des 
informations liées <strong>aux périmètres de facturation (étape 4)</strong> ;
<strong>- Si un même interlocuteur a les deux rôles (technique et facturation), il est important de le déclarer 
deux fois (en rôle TECHNIQUE et en rôle FACTURATION) afin de pouvoir le retrouver ensuite dans 
l’étape Sites et dans l’étape Périmètres de facturation → pensez à utiliser le bouton Dupliquer !</strong>"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="interlocuteur.role"
        getFlow="name::services"
        getPanel="name::Interlocuteurs"
        getField="type::role"
        :requiredMessage="`Indiquez ici le rôle de l'interlocuteur par rapport au contrat énergie`"
        :required="true"
        :disabled="from === 'site' || from === 'groupement'"
      />
      <mcma-field
        :colSpan="24"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="refInterlocuteur"
        placeholder="Référence"
        getFlow="name::services"
        getPanel="name::Interlocuteurs"
        getField="type::refInterlocutor"
        :defaultValue="interlocuteur.refInterlocuteur"
      />
    </a-row>
    <a-row type="flex" :gutter="12" v-if="$route.name === 'interlocuteurs-detail'">
      <a-col :span="24">
        <div class="button-group-inline mt24">
          <mcma-button
            name="Abandonner"
            color="red"
            leftIcon="backward"
            :isFaIcon="true"
            @click="$router.push({ name: 'interlocuteurs' })"
          />
          <mcma-button name="Réinitialiser" color="primary" leftIcon="undo-alt" :isFaIcon="true" @click="initialize" />
          <mcma-button
            name="Valider les informations"
            class="ml12"
            color="success"
            leftIcon="save"
            :isFaIcon="true"
            @click="submit"
          />
        </div>
      </a-col>
    </a-row>
    <a-modal title="Aide" :visible="openHelpModal" :maskClosable="false" :width="1200" class="modal">
      <p class="data">
        Si vous souhaitez donner un 2ème rôle à un interlocuteur existant : pensez à la fonction « dupliquer » présente
        dans la colonne "Actions" de la liste de vos interlocuteurs ! Vous pouvez également sélectionner le rôle "Tous
        les rôles obligatoires" qui génèrera tous les interlocuteurs obligatoires (même données interlocuteurs, mais
        rôles différents)
      </p>
      <div class="align-row-end mtb12">
        <mcma-button name="J'ai compris" color="blue" class="ok mr7" @click="openHelpModal = false" />
      </div>
    </a-modal>
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapState, mapActions } from "vuex"
import { pick } from "@/util/Helpers"
import Tips from "@/components/mcma/help/contactUs/Tips"

export default {
  name: "InterlocuteursEdit",
  props: {
    interlocuteur: Object,
    from: String,
  },
  components: {
    McmaField,
    McmaButton,
    Tips,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      openHelpModal: false,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    getInterName() {
      return (name) => {
        return name ? `de ${this.$capitalize(name)}` : ""
      }
    },
  },
  methods: {
    ...mapActions(["updateInterlocuteurOfGroupements", "refreshStore"]),
    initialize() {
      if (this.$route.params.action === "new") {
        this.form.setFieldsValue({
          nom: null,
          prenom: null,
          civility: null,
          mail: null,
          telephone: null,
          mobile: null,
          fonction: null,
          role: null,
          refInterlocuteur: null,
        })
      } else if (this.$route.params.interlocuteurId) {
        Axios("get", "api/interlocuteurs/" + this.$route.params.interlocuteurId).then((response) => {
          this.form.setFieldsValue(
            pick(response.data, [
              "nom",
              "prenom",
              "civility",
              "mail",
              "telephone",
              "mobile",
              "fonction",
              "role",
              "refInterlocuteur",
            ])
          )
        })
      }
      if (this.from === "site") {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            role: "TECHNIQUE",
          })
        })
      } else if (this.from === "groupement") {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            role: "FACTURATION",
          })
        })
      }
    },
    submit() {
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
          this.$emit("form-error")
        } else {
          const interlocuteur = {
            membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
            nom: fieldsValue.nom.toUpperCase(),
            prenom: this.$capitalize(fieldsValue.prenom),
            mail: fieldsValue.mail,
            civility: fieldsValue.civility,
            telephone: fieldsValue.telephone,
            mobile: fieldsValue.mobile,
            fonction: fieldsValue.fonction,
            role: fieldsValue.role,
            refInterlocuteur: fieldsValue.refInterlocuteur,
            operationId: this.operation.id,
          }
          console.log(">> MODIF/ADD Interlocuteur : %O", interlocuteur)
          if (this.interlocuteur.id) {
            if (this.$route.params.action === "duplique") {
              this.createInterlocuteur(interlocuteur)
            } else {
              if (interlocuteur.role === "TOUS_LES_ROLES_OBL") {
                const oblRole = ["CONTRAT", "FACTURATION", "TECHNIQUE", "ADMINSTRATEUR_ESPACE_CLIENT"]
                oblRole.forEach((role) => {
                  const interlocuteurModified = {
                    membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
                    nom: fieldsValue.nom.toUpperCase(),
                    prenom: this.$capitalize(fieldsValue.prenom),
                    mail: fieldsValue.mail,
                    civility: fieldsValue.civility,
                    telephone: fieldsValue.telephone,
                    mobile: fieldsValue.mobile,
                    fonction: fieldsValue.fonction,
                    role: role,
                    refInterlocuteur: fieldsValue.refInterlocuteur,
                    operationId: this.operation.id,
                  }
                  if (interlocuteurModified.role === this.interlocuteur.role) {
                    this.patchInterlocuteur(interlocuteurModified)
                    this.updateInterlocuteurOfGroupements({
                      id: this.interlocuteur.id,
                      ...interlocuteurModified,
                    })
                  }else{
                      this.createInterlocuteur(interlocuteurModified)
                  }
                })
              } else {
                this.patchInterlocuteur(interlocuteur)
                this.updateInterlocuteurOfGroupements({
                  id: this.interlocuteur.id,
                  ...interlocuteur,
                })
              }
            }
          } else {
            if (interlocuteur.role === "TOUS_LES_ROLES_OBL") {
              const oblRole = ["CONTRAT", "FACTURATION", "TECHNIQUE", "ADMINSTRATEUR_ESPACE_CLIENT"]
              oblRole.forEach((role) => {
                const interlocuteurModified = {
                  membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
                  nom: fieldsValue.nom.toUpperCase(),
                  prenom: this.$capitalize(fieldsValue.prenom),
                  mail: fieldsValue.mail,
                  civility: fieldsValue.civility,
                  telephone: fieldsValue.telephone,
                  mobile: fieldsValue.mobile,
                  fonction: fieldsValue.fonction,
                  role: role,
                  refInterlocuteur: fieldsValue.refInterlocuteur,
                  operationId: this.operation.id,
                }
                this.createInterlocuteur(interlocuteurModified)
              })
            } else {
              this.createInterlocuteur(interlocuteur)
            }
          }
        }
      })
      this.refreshStore()
    },
    patchInterlocuteur(interlocuteur) {
      Axios("get", "api/membres/"+this.membre.id+"/interlocuteurs").then((interlocuteurs) => {
        let interlocuteurExistantList = interlocuteurs.data._embedded.interlocuteurs
        var adminClient = interlocuteurExistantList.filter(function (interlocuteurAdmin) {
          return interlocuteurAdmin.role === "ADMINSTRATEUR_ESPACE_CLIENT"
        })

        if ( adminClient.length > 0 && adminClient.find((i) => i.id === this.interlocuteur.id) === undefined && interlocuteur.role === "ADMINSTRATEUR_ESPACE_CLIENT") {
          alert(
            "Ajout impossible : vous avez déjà enregistré un Administrateur Espace Client ; vous ne pouvez en créer qu'un seul. Veuillez vous rapprocher de votre contact ENGIE le cas échéant"
          )
        } else {
          Axios("patch", "api/interlocuteurs/" + this.interlocuteur.id, interlocuteur)
            .then((response) => {
              this.$notification.success({
                message: "Interlocuteur mis à jour",
                description: `L'interlocuteur ${interlocuteur.prenom} ${interlocuteur.nom} à bien été mis à jours.`,
              })
              if (this.$route.name === "interlocuteurs-detail") {
                setTimeout(() => {
                  this.$router.push({
                    name: "interlocuteurs",
                  })
                }, 1500)
              } else {
                this.$emit("save-interlocuteur", response.data)
              }
            })
            .catch((error) => {
              console.error(error)
              this.$emit("form-error")
              this.$notification.error({
                message: "Erreur serveur",
                description: "Une erreur server est survenue, veuillez contacter un administrateur.",
              })
            })
        }
      })
    },
    createInterlocuteur(interlocuteur) {
      Axios("get", "api/membres/"+this.membre.id+"/interlocuteurs").then((interlocuteurs) => {
        let interlocuteurExistantList = interlocuteurs.data._embedded.interlocuteurs
        console.log("interlocutreursExistantList =========> %O", interlocuteurExistantList)
        var adminClient = interlocuteurExistantList.filter(function (interlocuteurAdmin) {
          return interlocuteurAdmin.role === "ADMINSTRATEUR_ESPACE_CLIENT"
        })
        console.log("adminClinet ====> %O", adminClient)
        if (adminClient.length > 0 && interlocuteur.role === "ADMINSTRATEUR_ESPACE_CLIENT") {
          alert(
            "Ajout impossible : vous avez déjà enregistré un Administrateur Espace Client ; vous ne pouvez en créer qu'un seul. Veuillez vous rapprocher de votre contact ENGIE le cas échéant"
          )
        } else {
          Axios("post", "api/interlocuteurs/", interlocuteur)
            .then((response) => {
              this.$notification.success({
                message: "Nouvel Interlocuteur ajouté",
                description: `L'interlocuteur ${interlocuteur.prenom} ${interlocuteur.nom} à bien été ajouté à vos interlocuteurs.`,
              })
              if (this.$route.name === "interlocuteurs-detail") {
                setTimeout(() => {
                  this.$router.push({
                    name: "interlocuteurs",
                  })
                }, 1500)
              } else {
                this.$emit("save-interlocuteur", response.data)
              }
            })
            .catch((error) => {
              console.error(error)
              this.$emit("form-error")
              if (error.response.status === 409) {
                this.$notification.warning({
                  message: "L'interlocuteur existe déjà",
                  description: "Un interlocuteur avec cette email existe déjà.",
                })
              } else {
                this.$notification.error({
                  message: "Erreur serveur",
                  description: "Une erreur server est survenue, veuillez contacter un administrateur.",
                })
              }
            })
        }
      })
    },
  },
  created() {
    this.openHelpModal = true
    this.initialize()
  },
}
</script>

<style lang="scss">
.modal {
  .ant-modal-content {
    .ant-modal-footer {
      display: none;
    }
  }
}

.data {
  font-size: 18px;
}
</style>
