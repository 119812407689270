<template>
  <div>
    <mcma-button
      class="show"
      name="Télécharger le pas-à-pas détaillé"
      color="blue"
      leftIcon="download"
      :isFaIcon="true"
      :disabled="false"
      @click="dlGuide"
    />
    <mcma-card title="Modifications / créations / suppressions en masse" class="mcma-card-in-tab site-masse">
      <template #describe>
        <!--<font size="4">
          <span v-if="!site.id">
            Vous devez à minima avoir un site en statut A Complété, pous y accèder dans via le tableau des modification
            en masse.
          </span>
          <span v-else> Vous pouvez modifier les informations d'identification du site autant que souhaité. </span>
          <br />
          Si vous souhaitez apporter des modifications, double-cliquez sur la cellule à modifier pour accéder à la
          saisie.<br />
          Modification en masse: De la même manière que sous excel, vous pouvez sélectionner une cellule et la faire
          glisser vers le bas afin de modifier en masse vos sites.
        </font>-->
        <font size="4">
          Cette fonctionnalité est particulièrement adaptée aux périmètres à forte volumétrie (à partir de plusieurs
          dizaines de sites). Elle permet d’exporter vers Excel votre périmètre de sites, et d’effectuer sous excel
          toutes les manipulations et croisements de données permettant de mettre à jour les données de vos sites : -
          Données administratives (notamment type de taxe TCFE, exonération CSPE, exonération CEE) - Interlocuteur
          technique associé au site Elle permet également de gérer des ajouts de site en nombre, par import depuis un
          fichier excel préalablement préparé. Vous pouvez librement multiplier les itérations : travailler en plusieurs
          lots, remodifier des sites déjà traités par import de masse, etc.
        </font>
        <br />
        <br />
        <div v-if="checkEnrActive()">
        <span style="color: rgb(24, 144, 255)"
          ><h5 style="color: #1890ff !important">Info Energie Renouvelable</h5></span
        >

        <br />
        <div class="mcma-informations">
          <div
            v-if="getSitesFlow && getSitesFlow.enrDescribe"
            class="html-render-content"
            v-html="getSitesFlow.enrDescribe"
            :class="{ 'can-edit': $isAdmin() }"
            @click="setEditorModal('sites', 'enrDescribe')"
          ></div>
          <span class="action" @click="openEnrInfosModal">Cliquer ici</span>
          pour en savoir plus.
        </div>
        </div>
      </template>

      <mcma-enr-infos-modal
        :visible="openEnrInfos"
        :htmlRender="getSitesFlow && getSitesFlow.enrModalDescribe ? getSitesFlow.enrModalDescribe : null"
        @setEditorModal="setEditorModal('sites', 'enrModalDescribe')"
        @close="closeEnrInfos"
      />

      <mcma-editor-modal
        v-if="$isAdmin()"
        :visible="showEditor"
        :flowName="flowName"
        :flowProp="flowProp"
        @onCancel="setEditorModal"
        @onSave="editorHasMakeChange"
      />

      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <input
            type="text"
            @keyup="onQuickFilterChanged()"
            id="sitesMasseQuickFilter"
            placeholder="Recherche rapide..."
          />

          <div class="block-selectedRows" :class="{ empty: selectedRows.result.length === 0 }">
            <span class="sentence">
              {{ selectedRows.sentence }}
            </span>
            <mcma-button
              class="show"
              name="Exporter les sites"
              color="purple"
              leftIcon="download"
              :isFaIcon="true"
              :disabled="false"
              @click="exportSites()"
            />
            <!--    <mcma-button
              class="show"
              name="Exporter la sélection"
              color="purple"
              leftIcon="download"
              :isFaIcon="true"
              :disabled="selectedRows.result.length < 1"
              @click="exportSitesSelected()"
            />
           <mcma-button
              class="show"
              name="Désactiver site"
              color="red"
              leftIcon="trash"
              :isFaIcon="true"
              :disabled="selectedRows.result.length < 1 && selectedRows.state !== 'CERTIFIED' && user.role !== 'CLIENT'"
              @click="remove(selectedRows)"
            /> -->
          </div>
          <div class="block-import">
            <mcma-button
              name="Importer en masse"
              color="green"
              leftIcon="help"
              :isFaIcon="true"
              @click="openImportSiteMasseModal"
            />
            <import-site-masse-modal
              :visible="showImportSiteMasse"
              @close="closeImportSiteMasseModal"
              @initialize="initialize"
            />
          </div>

          <ag-grid-vue
            style="height: 500px"
            class="ag-theme-material"
            :grid-options="gridOptions"
            :modules="modules"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :enableRangeSelection="true"
            :fillOperation="fillOperation"
            :enableFillHandle="true"
            :row-selection="'multiple'"
            :row-multi-select-with-click="true"
            :default-col-def="defaultColDef"
            :enableCellChangeFlash="false"
            :components="components"
            @firstDataRendered="onFirstDataRendered"
            @cellValueChanged="onCellValueChanged"
            @cellDoubleClicked="onCellDoubleClicked"
            @selectionChanged="onSelectionChanged"
          />
        </a-col>
      </a-row>
    </mcma-card>
    <mcma-ps-modal :visible="showPsModal" :site="siteForPs" @cancel="cancelUpdatePs" @submit="updatePsFromModal" />
    <a class="ninja" ref="downloadLink" />
  </div>
</template>

<script>
import GenericField from "@/components/common/Fields/GenericField"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaPsModal from "@/components/mcma/common/McmaPsModal"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import McmaEnrInfosModal from "@/components/mcma/common/McmaEnrInfosModal"
import Axios from "@/util/Axios"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import MixinValidator from "@/util/Mixin/Validator"
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { AgGridVue } from "ag-grid-vue"
import $ from "jquery"
import "jquery-ui-bundle"
import _ from "lodash"
import moment from "moment"
import { accorization } from "@/util/Helpers"
import { mapState } from "vuex"
import XLSX from "xlsx"
import { Segments } from "./Fta"
import ImportSiteMasseModal from "./ImportSiteMasseModal"
const DATE_FORMAT = "DD/MM/YYYY"

function dateStringToTimeStamp(value) {
  if (value && moment(value, DATE_FORMAT).format("x") !== "Invalid date") {
    return Math.round(moment(value, DATE_FORMAT).format("x"))
  }
  return value
}

function timeStampToDateString(params) {
  if (Array.isArray(params.value) && params.value.length > 0) {
    if (typeof params.value[0] === "number") {
      return moment(params.value[0], "x").format(DATE_FORMAT)
    } else {
      return params.value
    }
  } else if (params.value) {
    if (typeof params.value === "number") {
      return moment(params.value, "x").format(DATE_FORMAT)
    } else {
      return params.value
    }
  } else {
    return null
  }
}

function getDatePicker() {
  function Datepicker() {}

  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input")
    this.eInput.value = moment(params.value, "x").format(DATE_FORMAT)
    $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" })
  }
  Datepicker.prototype.getGui = function () {
    return this.eInput
  }
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus()
    this.eInput.select()
  }
  Datepicker.prototype.getValue = function () {
    console.log("this.eInput.value", this.eInput.value)
    return this.eInput.value
  }
  Datepicker.prototype.destroy = function () {}
  Datepicker.prototype.isPopup = function () {
    return false
  }
  return Datepicker
}

export default {
  name: "masse",
  mixins: [MixinValidator],
  props: { site: Object },
  components: {
    AgGridVue,
    GenericField,
    McmaCard,
    McmaButton,
    McmaPsModal,
    McmaEditorModal,
    McmaEnrInfosModal,
    ImportSiteMasseModal,
  },
  data() {
    return {
      openEnrInfos: false,
      components: null,
      modules: AllModules,
      showPsModal: false,
      siteForPs: null,
      ftaModalToOpen: [],
      nom: null,
      rae: null,
      fluide: null,
      status: null,
      refTDG: null,
      refSite: null,
      adresse: null,
      codePostal: null,
      commune: null,
      codeImputation: null,
      added: null,
      dateActivation: null,
      dateResiliation: null,
      codeInsee: null,
      sites: null,
      columnDefs: null,
      rowData: null,
      confENR: null,
      confFTA: null,
      defaultColDef: null,
      selectedRows: {
        sentence: "0 site sélectionné",
        result: [],
        loading: false,
        canBeCertify: [],
      },
      gridOptions: {
        suppressRowClickSelection: true,
      },
      flowName: null,
      flowProp: null,
      showEditor: false,
      file: null,
      showImportSiteMasse: false,
      interlocuteurTechnique: null,
    }
  },
  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      minWidth: 100,
      resizable: true,
      filter: "agSetColumnFilter",
      cellClass: function (params) {
        return `${params.colDef.field}_${params.node.id}`
      },
    }

    // Gestion du mode ENR Unique ou ENR Multi
    let editEnr = true
    let cellClassEnr = ""
    const enrUniqueSite = getDynamiqueFields(
      this.operation,
      "name::sites",
      "name::Données techniques (électricité)",
      "type::enrUniqueSite"
    )
    if (enrUniqueSite && enrUniqueSite.displayed) {
      editEnr = false
      cellClassEnr = "not-editable"
    }

    // Charge les columnsDef par defaut
    this.columnDefs = [
      {
        headerName: "Nom",
        field: "nom",
        minWidth: 120,
        editable: false,
        sortable: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: "left",
        lockPinned: true,
      },
      {
        headerName: "Fluide",
        field: "fluide",
        editable: false,
        cellClass: "not-editable",
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
      },
      {
        headerName: "RAE/PCE",
        field: "rae",
        editable: false,
        cellClass: "not-editable",
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
        minWidth: 140,
        sort: {
          direction: "asc",
          priority: 0,
        },
      },
      {
        headerName: "Libelle facture (ref compta)",
        field: "codeGroupement",
        minWidth: 120,
        editable: false,
        valueSetter: function (params) {
          let newValue = null
          if (Array.isArray(params.newValue)) {
            newValue = params.newValue[0]
          } else {
            newValue = params.newValue
          }
          if (params.data.codeGroupement !== newValue) {
            const regex = /[^ a-zA-Z0-9]/g
            let a = newValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            params.data.codeGroupement = a.replace(regex, " ").toUpperCase().substring(0, 39)
            return true
          } else {
            return false
          }
        },
      },
      {
        headerName: "Adresse",
        field: "adresse",
        minWidth: 180,
        editable: false,
        cellEditor: "agLargeTextCellEditor",
      },
      {
        headerName: "Commune",
        field: "commune",
        minWidth: 140,
        editable: false,
      },
      {
        headerName: "Code postale",
        field: "codePostal",
        minWidth: 140,
        editable: false,
      },
      {
        headerName: "Date d'entrée",
        field: "dateActivation",
        editable: false,
        cellEditor: "datePicker",
        valueFormatter: timeStampToDateString,
      },
      {
        headerName: "Date de sortie",
        field: "dateResiliation",
        editable: false,
        cellEditor: "datePicker",
        valueFormatter: timeStampToDateString,
      },
      {
        headerName: "Segment",
        field: "segment",
        editable: false,
        cellClass: "not-editable",
        cellRenderer: (data) => {
          return data.value ? this.$getValueByKey(data.value) : "C0"
        },
      },
      {
        headerName: "FTA",
        field: "fta",
        minWidth: 140,
        editable: false,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: (params) => {
          let selectedSegement = params.data.segment
          selectedSegement = selectedSegement || "C0"
          return {
            cellHeight: 50,
            values: this.getFtas(selectedSegement),
          }
        },
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
      },
      {
        headerName: "ENR",
        field: "enr", // trouver un moyen de mettre à jour la valeur biogaz
        editable: false,
        cellClass: cellClassEnr,
        cellEditorParams: {
          cellHeight: 30,
          values: this.confENR.lockedValues,
        },
        cellEditor: "agRichSelectCellEditor",
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
      },
      {
        headerName: "Type site",
        field: "typeSite",
        editable: false,
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
        cellEditorParams: {
          cellHeight: 50,
          values: this.confTypeSite.lockedValues,
        },
        cellEditor: "agRichSelectCellEditor",
      },
      {
        headerName: "Type TCFE",
        field: "typeTcfe",
        editable: false,
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
        cellEditorParams: {
          cellHeight: 50,
          values: this.confTypeTcfe.lockedValues,
        },
        cellEditor: "agRichSelectCellEditor",
      },
      {
        headerName: "Exoneration CSPE",
        field: "exonerationCspe",
        editable: false,
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
        cellEditorParams: {
          cellHeight: 50,
          values: this.confExonerationCspe.lockedValues,
        },
        cellEditor: "agRichSelectCellEditor",
      },
      {
        headerName: "Exoneration CEE",
        field: "exonerationCee",
        editable: false,
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
        cellEditorParams: {
          cellHeight: 50,
          values: this.confExonerationCee.lockedValues,
        },
        cellEditor: "agRichSelectCellEditor",
      },
      {
        headerName: "HVE",
        field: "hve",
        editable: false,
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
        cellEditorParams: {
          cellHeight: 50,
          values: this.confHVE.lockedValues,
        },
        cellEditor: "agRichSelectCellEditor",
      },
      {
        headerName: "Statut",
        field: "status",
        editable: false,
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
        cellEditorParams: {
          cellHeight: 50,
          values: this.confStatus.lockedValues,
        },
        cellEditor: "agRichSelectCellEditor",
      },
    ]

    // Personalise le nom des colonnes selon la conf
    console.log(">#### Technique : ")
    let index = this.columnDefs.length - 1
    while (index >= 0) {
      const c = this.columnDefs[index]
      console.log(">c Technique : " + c.field)
      const f = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::" + c.field
      )
      console.log("> f: %O", f)
      if (f) {
        if (!f.displayed) {
          this.columnDefs.splice(index, 1)
          console.log(">remove : " + c.field)
        } else {
          console.log(">load : " + c.field)
          c.headerName = f.name
        }
      }
      index -= 1
    }

    console.log(">#### Localisation : ")
    index = this.columnDefs.length - 1
    while (index >= 0) {
      const c = this.columnDefs[index]
      console.log(">c Technique : " + c.field)
      const f = getDynamiqueFields(this.operation, "name::sites", "name::Localisation", "type::" + c.field)
      console.log("> f: %O", f)
      if (f) {
        if (!f.displayed) {
          this.columnDefs.splice(index, 1)
          console.log(">remove : " + c.field)
        } else {
          console.log(">load : " + c.field)
          c.headerName = f.name
        }
      }
      index -= 1
    }

    this.components = { datePicker: getDatePicker() }

    console.log("this.columnDefs : %O", this.columnDefs)
  },
  computed: {
    ...mapState(["membre", "operation", "user"]),
    getSitesFlow() {
      return this.operation.flows.find((flow) => flow.name === "sites")
    },
    getFtas() {
      return (segment) => {
        const allFtasForSegment = Segments[segment] ? Segments[segment].fta : []
        const keys =
          this.confFTA.lockedValues.filter(function (item) {
            return !!allFtasForSegment.includes(item)
          }) || []
        return keys.map((key) => this.$getValueByKey(key))
      }
    },
    Segments() {
      return Segments
    },
    fields() {
      if (this.operation && this.operation.flows) {
        const flow = _.find(this.operation.flows, ["name", "sites"])
        if (flow && flow.panels) {
          const panel = _.find(flow.panels, ["name", "Localisation"])
          if (panel && panel.fields) {
            panel.fields.forEach((element) => {
              switch (element.type) {
                case "siteName":
                  element.value = this.nom
                  break
                case "adress":
                  element.value = this.adresse
                  break
                case "postalCode":
                  element.value = this.codePostal
                  break
                case "siteCode":
                  element.value = this.CodeInsee
                  break
                case "codeGroupement":
                  element.value = this.codeGroupement
                  break
                case "town":
                  element.value = this.commune
                  break
                case "dateFinContrat":
                  element.value = this.Resiliation
                  break
                default:
                  element.value = this[element.type]
              }
              if (!element.value) {
                console.log("element.type non trouvé : %O", element.type)
              } else {
                console.log("%O : %O", element.type, element.value)
              }
            })
            return panel.fields
          }
        }
      }
      return []
    },
    fluidField: {
      get() {
        if (this.operation && this.operation.flows) {
          const flow = _.find(this.operation.flows, ["name", "sites"])
          if (flow && flow.panels) {
            const panel = _.find(flow.panels, ["name", "Localisation"])
            if (panel && panel.fields) {
              let field = _.find(panel.fields, ["type", "fluid"])
              if (!this.fluide) {
                field.value = field.lockedValues[0]
              } else {
                field.value = this.fluide
              }
              return field
            }
          }
        }
        return null
      },
      set(value) {
        this.fluide = value
      },
    },
    fluidFieldElec: {
      get() {
        if (this.fluidField) {
          return this.fluidField.value === "ELEC"
        }
        return false
      },
    },
    fluidFieldGaz: {
      get() {
        if (this.fluidField) {
          return this.fluidField.value === "GAZ"
        }
        return false
      },
    },
  },
  methods: {
    checkEnrActive(){
      const f = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enr"
      )
      return f.displayed
    },
    dlGuide() {
      const link = this.$refs.downloadLink
      link.href = require("@/assets/pdf/guide_export_reimport.pdf")
      link.setAttribute("download", "Guide-import-export")
      link.click()
    },
    initialize() {
      console.group("intialize@Masse")
      this.confStatus = getDynamiqueFields(this.operation, "name::sites", "name::Localisation", "type::status")

      console.log("> this.columnDefs : %O", this)
      this.enrUniqueSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enrUniqueSite"
      )
      this.confEnrElec = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enr"
      )
      this.confEnrGaz = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enr"
      )

      this.confTypeSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::typeSite"
      )

      this.confExonerationCspe = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::exonerationCspe"
      )

      this.confExonerationCee = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::exonerationCee"
      )

      this.confTypeTcfe = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::typeTcfe"
      )

      const confTHVE = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::thve"
      )

      if (confTHVE && confTHVE.displayed) {
        // Mode HVE déduit de l'enr
        this.confHVE = confTHVE
      } else {
        // Mode HVE
        this.confHVE = getDynamiqueFields(
          this.operation,
          "name::sites",
          "name::Données techniques (électricité)",
          "type::hve"
        )
      }

      if (this.enrUniqueSite && this.enrUniqueSite.displayed) {
        // Mode ENR Unique
        this.confENR = this.confEnrElec
      } else {
        // Mode ENR multi
        if (this.fluidFieldGaz) {
          this.confENR = this.confEnrGaz
        } else {
          this.confENR = this.confEnrElec
        }
      }

      this.confENR.lockedValues = this.confENR.lockedValues.map((item) => {
        return item.match(/(\d+)/) ? item.match(/(\d+)/)[0] : item
      })
      console.log("this.confENR.lockedValues", this.confENR.lockedValues)
      console.log("typeof this.confENR.lockedValues[0]", typeof this.confENR.lockedValues[0])
      this.confFTA = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::fta"
      )
      this.sites = null
      Axios("get", "api/membres/" + this.membre.id + "/sites").then((response) => {
        this.sites = _.filter(
          _.flatMap(response.data._embedded, (value) => value),
          { removed: false }
        )
        this.rowData = [...this.sites.filter((site) => !site.certified)]
        console.log("rowData", this.rowData)
      })
      console.log("this.site : %O", this.site)
      console.groupEnd()
    },
    setEditorModal(flowName, flowProp) {
      console.group("setEditorModal@Etape2_Elec")
      console.log("flowName : %O", flowName)
      console.log("flowProp : %O", flowProp)
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
      console.groupEnd()
    },
    editorHasMakeChange() {
      this.setEditorModal()
    },
    psNeedChanged(site, oldFta, newFta) {
      newFta = Array.isArray(newFta) ? newFta[0] : newFta
      if (newFta && (site.segment === "C_5" || site.segment === "C_0")) {
        switch (oldFta) {
          case "BTINF_CU_BASE":
          case "BTINF_LU_BASE":
            if (newFta === "BTINF_MU_HP_HC" || newFta === "BTINF_CU_4" || newFta === "BTINF_MU_4") {
              this.openFtaModal(site)
            }
            break
          case "BTINF_MU_HP_HC":
            if (
              newFta === "BTINF_CU_BASE" ||
              newFta === "BTINF_LU_BASE" ||
              newFta === "BTINF_CU_4" ||
              newFta === "BTINF_MU_4"
            ) {
              this.openFtaModal(site)
            }
            break
          case "BTINF_CU_4":
          case "BTINF_MU_4":
            if (newFta === "BTINF_CU_BASE" || newFta === "BTINF_MU_HP_HC" || newFta === "BTINF_LU_BASE") {
              this.openFtaModal(site)
            }
            break
          default:
            this.openFtaModal(site)
            break
        }
      }
    },
    openFtaModal(site) {
      this.siteForPs = site
      this.showPsModal = true
    },
    cancelUpdatePs() {
      this.siteForPs = null
      this.showPsModal = false
    },
    updatePsFromModal() {
      this.siteForPs = null
      this.showPsModal = false
      setTimeout(() => {
        this.loopFtaModalToOpen()
      }, 500)
    },
    submit() {
      console.group("Site>Gestion en masse>Submit")
      console.log("this.rowData : %O", this.rowData)
      const countSiteChange = this.rowData.filter((site) => Array.isArray(site.changed) && site.changed.length > 0)
      const requests = []
      if (countSiteChange.length <= 0) {
        this.$notification.info({
          message: `Aucune Modification`,
          description: `Vous n'avez fait aucune modification, cependant nous considérons que vous validez les informations de localisation et techniques du sites.`,
        })
        this.rowData.forEach((site) => {
          // Blindage : test si allCange est bon avant d'envoyer sur le back
          requests.push(
            Axios("patch", "api/sites/" + site.id, {
              ongletIdentification: true,
              ongletParametre: true,
            })
              .then(() => {
                site.changed = []
                this.initialize()
                return {
                  siteSuccess: site,
                }
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: `Erreur de validation en masse des onglets techniques et paramètres`,
                  description: `Une erreur est survenue lors de la modifications du site ${site.nom} [${site.rae}]`,
                })
                return {
                  siteError: site,
                }
              })
          )
        })
        // return;
      } else {
        this.rowData.forEach((site) => {
          if (Array.isArray(site.changed) && site.changed.length > 0) {
            let allChange = {}
            site.changed.forEach((change) => {
              console.log("modification : %O - %O - %O <=  %O", site.id, site.rae, change, site[change])
              let valueToUpdate = null
              if (Array.isArray(site[change]) && site[change].length > 0) {
                // cas du changement par glisser dans le tableau
                valueToUpdate = site[change][0]
              } else {
                // cas du changement unique
                valueToUpdate = site[change]
              }

              const isDate = moment(valueToUpdate, DATE_FORMAT, true).isValid()
              if (isDate) {
                allChange[change] = dateStringToTimeStamp(valueToUpdate)
              } else if (change === "eclairagePublique") {
                allChange[change] = valueToUpdate === "Oui"
              } else if (change === "exonerationCee") {
                allChange[change] = valueToUpdate === "Oui"
              } else if (change === "enr" && typeof valueToUpdate === "string") {
                allChange[change] = valueToUpdate.replace("%", "")
              } else {
                allChange[change] = valueToUpdate
              }
            })
            console.log("modification allChange : %O - %O - %O <=  %O", site.id, site.rae, allChange)
            console.log("Object : %O", Object.entries(allChange).length !== 0)
            console.log("Object : %O", allChange.constructor === Object)
            // Blindage : test si allCange est bon avant d'envoyer sur le back
            if (Object.entries(allChange).length !== 0 && allChange.constructor === Object) {
              requests.push(
                Axios("patch", "api/sites/" + site.id, {
                  ongletIdentification: true,
                  ongletParametre: true,
                  ...allChange,
                })
                  .then(() => {
                    site.changed = []
                    this.initialize()
                    return {
                      siteSuccess: site,
                    }
                  })
                  .catch((error) => {
                    console.error(error)
                    this.$notification.error({
                      message: `Erreur de modification`,
                      description: `Une erreur est survenue lors des modifications du site ${site.nom} [${site.rae}]`,
                    })
                    return {
                      siteError: site,
                    }
                  })
              )
            } else {
              this.$notification.error({
                message: `Erreur de modification`,
                description: `Une erreur est survenue lors des modifications du site ${site.nom} [${site.rae}]`,
              })
            }
          }
        })
      }
      Promise.all(requests).then((responses) => {
        const success = responses.filter((response) => response.siteSuccess)
        const errors = responses.filter((response) => response.siteError)
        if (success && success.length > 0) {
          this.$notification.success({
            message: `Modifications sauvegardées`,
            description: `Les modifications ont bien été sauvegardées avec ${accorization(
              success.length,
              "site modifié",
              true,
              "aucun site modifié"
            )} ${errors.length > 0 ? `et ${accorization(errors.length, "erreur", true)}` : ""}`,
          })
        }
      })
      console.groupEnd()
    },
    onFirstDataRendered() {
      this.gridOptions.api.sizeColumnsToFit()
    },
    onQuickFilterChanged() {
      this.gridOptions.api.setQuickFilter(document.querySelector("#sitesMasseQuickFilter").value)
    },
    onSelectionChanged() {
      this.selectedRows.result = this.gridOptions.api.getSelectedRows()
      this.selectedRows.canBeCertify = this.selectedRows.result.filter((item) => item.state === "COMPLETED")
      this.selectedRows.sentence = `${accorization(
        this.selectedRows.result,
        "site sélectionné",
        true,
        "Aucun site sélectionné"
      )}`
    },
    onCellValueChanged(params) {
      let newValue = {
        value: params.newValue,
        isArray: false,
      }
      newValue.isArray = Array.isArray(params.newValue) && params.newValue.length > 0

      if (newValue.isArray) {
        newValue.value.forEach((value) => {
          if (params.colDef.field === "fta") {
            this.ftaModalToOpen.push(params)
          }
          this.addColorIfChange(params, value)
        })
      } else {
        if (params.colDef.field === "fta") {
          this.ftaModalToOpen.push(params)
        }
        this.addColorIfChange(params, newValue.value)
      }
      setTimeout(() => {
        this.loopFtaModalToOpen()
      }, 500)
    },
    loopFtaModalToOpen() {
      if (!this.showPsModal && this.ftaModalToOpen.length > 0) {
        this.psNeedChanged(
          this.ftaModalToOpen[0].data,
          this.ftaModalToOpen[0].oldValue,
          this.ftaModalToOpen[0].newValue
        )
        this.ftaModalToOpen.shift()
      }
    },
    addColorIfChange(params, value) {
      const isDate = moment(value, DATE_FORMAT, true).isValid()
      let valueTocheck = isDate ? dateStringToTimeStamp(value) : value
      if (params.oldValue !== valueTocheck) {
        console.log("Value change : %O + %O", params.oldValue, " => ", valueTocheck)
        if (params.data.changed === undefined) {
          console.log("init params.data.changed")
          params.data.changed = []
        }
        if (params.data.changed.indexOf(params.colDef.field) === -1) {
          params.data.changed.push(params.colDef.field)
        }
        const element = document.querySelector(`.${params.colDef.field}_${params.node.id}`)
        element.classList.add("cell-data-has-changed")
      }
    },
    fillOperation(params) {
      const ranges = this.gridApi.getCellRanges()
      if (params.column.colId !== "fta") {
        return params.initialValues
      } else {
        // permet de n'appliquer les changements de Fta qu'au ligne dont le segement est identique à la ligne initiale !!!
        // console.log("startRow rowIndex", ranges[0].startRow.rowIndex)
        const direction = params.direction === "up" ? -1 : 1
        const dataInit = this.gridApi.getDisplayedRowAtIndex(ranges[0].startRow.rowIndex).data
        const segmentInit = dataInit.segment || "C0"
        console.log("segmentInit", segmentInit)
        const dataCurrent = this.gridApi.getDisplayedRowAtIndex(
          ranges[0].startRow.rowIndex + (params.currentIndex + 1) * direction
        ).data
        const segmentCurrent = dataCurrent.segment || "C0"
        console.log("segmentCurrent", segmentCurrent)
        if (segmentInit === segmentCurrent) {
          return params.initialValues
        } else {
          return dataCurrent.fta
        }
      }
    },
    onCellDoubleClicked(params) {
      const parent = document.querySelector(`.ag-virtual-list-container`)
      const elements = document.querySelectorAll(`.ag-virtual-list-item`)
      if (elements.length > 0) {
        const className = "cell-number-selected-is-"
        elements.forEach((element, index) => {
          if (params.colDef.field === "eclairagePublique") {
            if (params.value && element.textContent === "Oui") {
              parent.classList.add(className + index)
            }
            if (!params.value && element.textContent === "Non") {
              parent.classList.add(className + index)
            }
          } else {
            if (params.value && params.value.toString() === element.textContent) {
              parent.classList.add(className + index)
            }
          }
        })
      }
    },
    async remove(sites) {
      const that = this
      this.site.loading = true
      this.$confirm({
        title: `Retirer ce site pour ce membre`,
        content: `Voulez-vous vraiment désactiver ${sites.result.length} site(s)?`,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          for (let i = 0; i < sites.result.length; i++) {
            const site = sites.result[i]
            Axios("patch", "api/sites/" + site.id, {
              removed: 1,
              status: "INEXISTANT",
            })
              .then(() => {
                that.$notification.success({
                  message: `Site(s) desactivé(s)`,
                })
                that.$emit("initialize")
              })
              .catch((error) => {
                console.error(error)
                that.$notification.error({
                  message: `Erreur de suppression`,
                  description: `Le site ${site.nom} n'as pas pu être supprimé. Veuillez réessayer ultérieurement.`,
                })
              })
          }
        },
      })
    },
    async exportSites() {
      this.$emit("loading", true)
      const date = new Date()
      let fileName =
        "Export_Sites_" +
        this.operation.name +
        "_" +
        this.membre.nom +
        "_" +
        date.getFullYear() +
        date.getMonth() +
        date.getDay() +
        "_" +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds() +
        ".xlsx"
      fileName = fileName.replace(" ", "_")
      Axios("get", "zdp/exportSiteMasse?membre=" + this.membre.id + "&fileName=" + fileName, null, {
        responseType: "blob",
      })
        .then((response) => {
          console.log("> response :  %O", response)
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", fileName)
          link.click()
          this.$emit("loading", false)
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: `Erreur lors du téléchargement`,
            description: `Une erreur est survenue lors du téléchargement de vos sites`,
          })
          this.$emit("loading", false)
        })
    },
    async exportSitesSelected() {
      if (this.selectedRows.result && this.selectedRows.result.length > 0) {
        const date = new Date()
        let fileName =
          "Export_Sites_" +
          this.operation.name +
          "_" +
          date.getFullYear() +
          date.getMonth() +
          date.getDay() +
          "_" +
          date.getHours() +
          date.getMinutes() +
          date.getSeconds() +
          ".xlsx"
        fileName = fileName.replace(" ", "_")

        const requests = this.selectedRows.result.map((site) => {
          /*
          if (site.interlocuteurId != null) {
            return Axios("get", process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + site.interlocuteurId).then(
              (interlocuteurTechnique) => {
                interlocuteurTechnique = interlocuteurTechnique.data
                return {
                  Nom: site.nom,
                  Fluide: this.$getValueByKey(site.fluide),
                  RAE_PCE: site.rae.charAt(0) === "0" ? "'" + site.rae : site.rae,
                  "Libelle facture": site.codeGroupement,
                  Adresse: site.adresse,
                  Commune: site.commune,
                  "Code Postale": site.codePostal,
                  "Date d'activation": moment(site.dateActivation, "x").format(DATE_FORMAT),
                  "Date de sortie": moment(site.dateResiliation, "x").format(DATE_FORMAT),
                  Segment: site.segment ? this.$getValueByKey(site.segment) : "C0",
                  FTA: this.$getValueByKey(site.fta),
                  "Code Imputation": site.codeImputation,
                  ENR: this.$getValueByKey(site.enr),
                  Type_site: this.$getValueByKey(site.typeSite),
                  "Type TCFE": site.typeTcfe,
                  "Exoneration CSPE": !site.exonerationCspe ? "Non" : "Oui",
                  "Exoneration CEE": !site.exonerationCee ? "Non" : "Oui",
                  Statut: site.status,
                  HVE: this.$getValueByKey(site.hve) == null ? site.thve : site.hve,
                  "Eclairage Public": !site.eclairagePublique ? "Non" : "Oui",
                  Interlocuteur_tech_nom: interlocuteurTechnique.nom,
                  Interlocuteur_tech_prenom: interlocuteurTechnique.prenom,
                  Interlocuteur_tech_mail: interlocuteurTechnique.mail,
                  Interlocuteur_tech_telephone: interlocuteurTechnique.telephone,
                  Interlocuteur_tech_mobile: interlocuteurTechnique.mobile,
                  Interlocuteur_tech_fonction: interlocuteurTechnique.fonction,
                  Interlocuteur_tech_civility: interlocuteurTechnique.civility,
                }
              }
            )
          } else {
            return {
              Nom: site.nom,
              Fluide: this.$getValueByKey(site.fluide),
              RAE_PCE: site.rae.charAt(0) === "0" ? "'" + site.rae : site.rae,
              "Libelle facture": site.codeGroupement,
              Adresse: site.adresse,
              Commune: site.commune,
              "Code Postale": site.codePostal,
              "Date d'activation": moment(site.dateActivation, "x").format(DATE_FORMAT),
              "Date de sortie": moment(site.dateResiliation, "x").format(DATE_FORMAT),
              Segment: site.segment ? this.$getValueByKey(site.segment) : "C0",
              FTA: this.$getValueByKey(site.fta),
              "Code Imputation": site.codeImputation,
              ENR: this.$getValueByKey(site.enr),
              Type_site: this.$getValueByKey(site.typeSite),
              "Type TCFE": site.typeTcfe,
              "Exoneration CSPE": !site.exonerationCspe ? "Non" : "Oui",
              "Exoneration CEE": !site.exonerationCee ? "Non" : "Oui",
              Statut: site.status,
              HVE: this.$getValueByKey(site.hve) == null ? site.thve : site.hve,
              "Eclairage Public": !site.eclairagePublique ? "Non" : "Oui",
              Interlocuteur_tech_nom: "",
              Interlocuteur_tech_prenom: "",
              Interlocuteur_tech_mail: "",
              Interlocuteur_tech_telephone: "",
              Interlocuteur_tech_mobile: "",
              Interlocuteur_tech_fonction: "",
              Interlocuteur_tech_civility: "",
            }
          }*/
          return {
            Nom: site.nom,
            Fluide: this.$getValueByKey(site.fluide),
            RAE_PCE: site.rae.charAt(0) === "0" ? "'" + site.rae : site.rae,
            "Libelle facture": site.codeGroupement,
            Adresse: site.adresse,
            Commune: site.commune,
            "Code Postale": site.codePostal,
            "Date d'activation": moment(site.dateActivation, "x").format(DATE_FORMAT),
            "Date de sortie": moment(site.dateResiliation, "x").format(DATE_FORMAT),
            Segment: site.segment ? this.$getValueByKey(site.segment) : "C0",
            FTA: this.$getValueByKey(site.fta),
            "Code Imputation": site.codeImputation,
            ENR: this.$getValueByKey(site.enr),
            Type_site: this.$getValueByKey(site.typeSite),
            "Type TCFE": site.typeTcfe,
            "Exoneration CSPE": site.exonerationCspe,
            "Exoneration CEE": !site.exonerationCee ? "Non" : "Oui",
            Statut: site.status,
            HVE: this.$getValueByKey(site.hve) == null ? site.thve : site.hve,
            "Eclairage Public": !site.eclairagePublique ? "Non" : "Oui",
            Interlocuteur_tech_nom: site.interlocuteurNom,
            Interlocuteur_tech_prenom: site.interlocuteurPrenom,
            Interlocuteur_tech_mail: site.interlocuteurMail,
            Interlocuteur_tech_telephone: site.interlocuteurTelephone,
            Interlocuteur_tech_mobile: site.interlocuteurMobile,
            Interlocuteur_tech_fonction: site.interlocuteurFonction,
            Interlocuteur_tech_civility: site.interlocuteurCivility,
          }
        })

        Promise.all(requests).then((sitesToExportFinal) => {
          const sitesExcel = XLSX.utils.json_to_sheet(sitesToExportFinal)
          const workbook = XLSX.readFile("~/src/template/template_site_masse.xlsx")
          console.log("-- workbook --")
          console.log(workbook)
          // const workbook = XLSX.utils.book_new()
          var wbcols = [
            { wch: 29 },
            { wch: 9 },
            { wch: 19 },
            { wch: 19 },
            { wch: 19 },
            { wch: 35 },
            { wch: 20 },
            { wch: 15 },
            { wch: 15 },
            { wch: 10 },
            { wch: 21 },
            { wch: 20 },
            { wch: 7 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 15 },
            { wch: 10 },
            { wch: 19 },
            { wch: 19 },
            { wch: 22 },
            { wch: 19 },
            { wch: 22 },
            { wch: 22 },
            { wch: 22 },
          ]
          // console.log('siteExcel', sitesExcel)
          sitesExcel["!cols"] = wbcols
          console.log("siteExcel :", sitesExcel)
          XLSX.utils.book_append_sheet(workbook, sitesExcel, "sites")

          var sheets = workbook.Sheets["sites"]
          sheets["B1"].s = {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF1c4587" },
              bgColor: { argb: "FF1c4587" },
            },
          }
          console.log("workbook : ", workbook)

          XLSX.writeFile(workbook, fileName)
        })
      }
    },
    closeEnrInfos() {
      console.log("closeEnrInfos")
      this.openEnrInfos = false
    },
    openEnrInfosModal() {
      console.log("openEnrInfosModal")
      this.openEnrInfos = true
    },
    openImportSiteMasseModal() {
      this.showImportSiteMasse = true
    },
    closeImportSiteMasseModal() {
      this.showImportSiteMasse = false
    },
  },
  watch: {
    site() {},
  },
  created() {
    this.initialize()
  },
  mounted() {
    this.gridApi = this.gridOptions.api
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.site-masse {
  .block-selectedRows {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    transition: all 0.5s ease;
    padding: 10px 20px;
    border-radius: 4px;
    border: 2px solid var(--success-color, $success-color);
    margin-bottom: 10px;
    .sentence {
      transition: all 0.5s ease;
      color: var(--success-color, $success-color);
      font-weight: 900;
      flex-grow: 2;
    }
    &.empty {
      border: 2px solid $grey;
      .sentence {
        color: $grey;
      }
    }
    .mcma-button {
      transition: opacity 0.3s ease;
      opacity: 0;
      margin-right: 20px;
      &.last {
        margin-right: 0;
        .leftImage {
          top: 3px;
        }
        .name {
          top: 3px;
        }
      }
      &.show {
        opacity: 1;
      }
    }
  }
}
.block-import {
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
  align-items: center;
  transition: all 0.5s ease;
  padding: 10px 20px;
  border-radius: 4px;
  border: 2px solid $grey;
  margin-bottom: 10px;
  .mcma-button {
    margin-right: 20px;
  }
  .file_import {
    margin-right: 20px;
  }
}

.col {
  margin-bottom: 10px;
}

.interlocuteur-wrapper {
  display: inline-block;
  width: 100%;
  border: 1px solid $grey;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  & ul {
    padding: 20px;
  }
  & div {
    padding: 20px;
    border-bottom: 1px solid $grey;
  }
}

.ag-row-hover {
  background-color: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.05) !important;
}

.ag-theme-material .ag-cell {
  &.not-editable {
    background: $grey-light;
  }
  .ag-icon {
    &:before {
      vertical-align: baseline !important;
    }
  }
}

.v-dialog__container {
  .v-dialog__content {
    &.v-dialog__content--active {
      background: rgba(black, 0.2);
    }
    .v-dialog {
      width: 100vw;
      height: 100vh;
      min-width: 100vw;
      max-height: 100vh;
      margin: 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      .text-red {
        color: red;
      }
      .modal-dialog {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2) !important;
        border-radius: 4px;
        padding: 10px;
        .headline {
          font-size: 21px !important;
          font-weight: 900;
        }
        .v-card__actions {
          button {
            color: #fff;
            &.success {
              background: #8bc34a;
            }
          }
        }
      }
    }
  }
}
.file-field {
  display: inline-flex;
  align-items: center;
}
.fileBtn {
  background: #546e7a;
  color: white;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 15px;
  transition: 0.3s;
  &:hover {
    background: #6a8896;
  }
}
.file-path-wrapper {
  display: inline-block;
  min-width: 325px;
  & .file-path {
    margin: 0 !important;
  }
}
.importBtn {
  display: inline-block;
  height: 30px;
  padding: 3px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 15px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
