/* eslint-disable no-unused-vars */

/**
 * File: index.js
 * Website: Waika
 * Author: Alexy FONDEVILLE <alexy.fondeville@gmail.com>
 */
const GenericFieldTable = require("@/components/mcma/common/Fields/Jsons/GenericFieldTable")
const _ = require("lodash")
const Axios = require("@/util/Axios")
const { accorization } = require("@/util/Helpers")
const store = require("@/store")

export default {
  store,
  install(Vue) {
    Vue.prototype.$capitalize = (value) => {
      return value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined
    }
    Vue.prototype.$uppercase = (value) => {
      return value.toUpperCase()
    }
    Vue.prototype.$replaceAll = (string, search, replacement) => {
      return string.split(search).join(replacement)
    }
    Vue.prototype.$removeChar = (string, position) => {
      const part1 = string.substring(0, position)
      const part2 = string.substring(position + 1, string.length)
      return part1 + part2
    }
    Vue.prototype.$lettersIndex = (letter, string) => {
      let a = []
      let i = -1
      while ((i = string.indexOf(letter, i + 1)) >= 0) a.push(i)
      return a
    }
    Vue.prototype.$generateRandomIndex = () => {
      let text = ""
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

      for (let i = 0; i < 10; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))

      return text
    }
    Vue.prototype.$reverseFilter = (array, iteratorFunction) => {
      return array.filter(function (item) {
        return !iteratorFunction(item)
      })
    }
    Vue.prototype.$sum = (array) => {
      let sum = 0
      for (let i = 0; i < array.length; i++) {
        if (!isNaN(Number(array[i]))) {
          sum += Number(array[i])
        }
      }
      return sum
    }
    Vue.prototype.$getAllElementsWithAttribute = (attribute) => {
      let matchingElements = []
      let allElements = document.getElementsByTagName("*")
      for (let i = 0, n = allElements.length; i < n; i++) {
        if (allElements[i].getAttribute(attribute) !== null) {
          // Element exists with attribute. Add to array.
          matchingElements.push(allElements[i])
        }
      }
      return matchingElements
    }
    Vue.prototype.$getExistValue = (...args) => args.find((arg) => arg)
    Vue.prototype.$onResize = (event) => {
      const width = event ? event.target.innerWidth : window.innerWidth
      if (width < 480) {
        return "xs"
      } else if (width < 768) {
        return "sm"
      } else if (width < 991) {
        return "md"
      } else if (width < 1199) {
        return "lg"
      } else {
        return "xl"
      }
    }
    Vue.prototype.$countLetter = (value, max) => {
      const number = value ? value.length : 0
      let rest = max - number
      if (rest >= 0) {
        return `${rest}`
      } else {
        rest -= rest
        return `${rest}`
      }
    }
    Vue.prototype.$scrollToTop = () => {
      const section = document.querySelector(".app > .app-header + section")
      if (section) {
        section.scrollTop = 0
      }
    }
    Vue.prototype.$scrollToBottom = () => {
      const section = document.querySelector(".app > .app-header + section")
      if (section) {
        section.scrollTop = section.scrollHeight
      }
    }
    Vue.prototype.$mergeObject = (obj1, obj2) => {
      return _.mergeWith({}, obj1, obj2, (o, s) => (_.isNull(s) ? o : s))
    }
    Vue.prototype.$getValueByKey = (key, noDataMessage) => {
      return getValueByKey(key, noDataMessage)
    }
    /**
     * * Example :
     * * const map = {"first" : "1", "second" : "2"};
     * * console.log($getKeyByValue(map,"2")); //output : "second"
     */
    Vue.prototype.$getKeyByValue = (object, value) => {
      const keys = Object.keys(object) || []
      return keys.find((key) => object[key] === value)
    }

    Vue.prototype.$evalSentence = (sentence, that) => {
      if (!sentence) {
        return null
      }
      return sentence.replace(/{{.*?}}/g, (match) => {
        let expression = match.slice(2, -2)
        try {
          // eslint-disable-next-line
          const value = eval("that." + expression)
          return getValueByKey(value)
        } catch (e) {
          return expression
        }
      })
    }
    Vue.prototype.$getLogoBySubDomain = () => {
      const host = location.host
      let slogan = null
      let backgroundCss = null
      let scaleNumber = 0
      let bgImage = null
      const hostSplit = host.split(".")
      const subDomain = host !== hostSplit[0] ? hostSplit[0] : "demo"
      try {
        if (subDomain === "demo") {
          scaleNumber = 3
          bgImage = require(`@/assets/images/logos/login/bg_${subDomain}_login.jpg`)
          backgroundCss = `url('${bgImage}')`
        } else if (subDomain === "sde35") {
          slogan = "Groupement d’achat d’énergie"
        } else if (subDomain === "ucanss") {
          scaleNumber = 2
          bgImage = require(`@/assets/images/logos/login/bg_${subDomain}_login.png`)
          backgroundCss = `url('${bgImage}')`
        } else if (subDomain === "siea") {
          scaleNumber = 0
          bgImage = require(`@/assets/images/logos/login/bg_${subDomain}_login.png`)
        } else {
          scaleNumber = 0
          bgImage = require(`@/assets/images/logos/login/bg_${subDomain}_login.png`)
        }
      } catch (e) {
        scaleNumber = 3
        bgImage = require(`@/assets/images/logos/login/bg_demo_login.jpg`)
        backgroundCss = `url('${bgImage}')`
      }

      if (bgImage) {
        backgroundCss = `url('${bgImage}')`
      }

      // let loginLogo = require(`@/assets/images/logos/login/logo_se60_login.png`);
      let loginLogo
      try {
        if (subDomain !== "demo") {
          loginLogo = require(`@/assets/images/logos/login/logo_${subDomain}_login.png`)
        } else {
          loginLogo = null
        }
      } catch (e) {
        loginLogo = null
      }
      let headerLogo
      try {
        headerLogo = require(`@/assets/images/logos/header/logo_${subDomain}_header.png`)
      } catch (e) {
        headerLogo = require(`@/assets/images/logos/header/logo_demo_header.png`)
      }
      let loginLogoPower
      try {
        loginLogoPower = require(`@/assets/images/logos/login/logo_engie_2_login.png`)
      } catch (e) {
        loginLogoPower = null
      }

      return {
        name: subDomain,
        slogan: slogan,
        scaleNumber: scaleNumber,
        backgroundCss: backgroundCss,
        logo: {
          login: loginLogo,
          header: headerLogo,
          loginLogoPower: loginLogoPower,
        },
      }
    }
    Vue.prototype.$getPowerBySubDomain = () => {
      const host = location.host
      let slogan = null
      let backgroundCss = null
      let scaleNumber = 0
      let bgImage = null
      const hostSplit = host.split(".")
      const subDomain = host !== hostSplit[0] ? hostSplit[0] : "demo"
      /*
      if (subDomain === "demo") {
        scaleNumber = 3;
        bgImage = require(`@/assets/images/logos/login/bg_${subDomain}_login.jpg`);
        backgroundCss = `url('${bgImage}')`;
      }else if (subDomain === "sde35") {
        slogan = "Groupement d’achat d’énergie"
      }else if (subDomain === "ucanss") {
        scaleNumber = 2;
        bgImage = require(`@/assets/images/logos/login/bg_${subDomain}_login.png`);
        backgroundCss = `url('${bgImage}')`;
      } else if (subDomain === "siea") {
        scaleNumber = 0;
        bgImage = require(`@/assets/images/logos/login/bg_${subDomain}_login.png`);
      }
      if(bgImage){
        backgroundCss = `url('${bgImage}')`;
      }
      */
      // let loginLogo = require(`@/assets/images/logos/login/logo_se60_login.png`);

      let loginLogo
      try {
        loginLogo = require(`@/assets/images/logos/login/logo_engie_2_login.png`)
      } catch (e) {
        loginLogo = null
      }
      let headerLogo
      try {
        headerLogo = require(`@/assets/images/logos/header/logo_${subDomain}_header.png`)
      } catch (e) {
        headerLogo = require(`@/assets/images/logos/header/logo_demo_header.png`)
      }

      if (!loginLogo) {
        loginLogo = require(`@/assets/images/logos/login/logo_engie_2_login.png`)
      }
      if (!headerLogo) {
        headerLogo = require(`@/assets/images/logos/header/logo_demo_header.png`)
      }

      return {
        name: subDomain,
        slogan: slogan,
        scaleNumber: scaleNumber,
        backgroundCss: backgroundCss,
        logo: {
          login: loginLogo,
          header: headerLogo,
        },
      }
    }
    Vue.prototype.$accorization = (items, singularWord, showNumber, messageOnEmpty) => {
      return accorization(items, singularWord, showNumber, messageOnEmpty)
    }
    Vue.prototype.$cloneDeep = (obj) => {
      return JSON.parse(JSON.stringify(obj))
    }
    Vue.prototype.$deleteKeyRecursive = (obj, key, removeObject) => {
      return deleteKeyRecursive(obj, key, removeObject)
    }
    Vue.prototype.$isAdmin = () => {
      return store.default.state && store.default.state.user
        ? store.default.state.user.role === "SUPER_ADMIN" || store.default.state.user.role === "ADMIN"
        : false
    }
    Vue.prototype.$stringToColor = (str) => {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      let color = "#"
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff
        color += ("00" + value.toString(16)).substr(-2)
      }
      return color
    }
    Vue.prototype.$orderBy = (array, prop) => {
      return [...array].sort((a, b) => a[prop] - b[prop])
    }
    Vue.prototype.$sortGenericFieldASC = (array) => {
      if (array.length > 2) {
        return [...array].sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }))
      }
      return array
    }
    Vue.prototype.$loopStateCanChangeToCompleted = async (operation, datas, flowName) => {
      let hasChange = false
      const flow = operation.flows.find((flow) => flow.name === flowName)
      const oldState = "TO_COMPLETE"
      const dataToCheck = []
      if (flow) {
        datas.forEach((data) => {
          if (data.state === oldState) {
            dataToCheck.push({
              id: data.id,
              fieldsInError: [],
            })
          }
        })
        flow.panels.forEach((panel) => {
          panel.fields.forEach((field) => {
            if (field.required) {
              datas.forEach((data) => {
                if (data.state === oldState && (data[field.type] === null || data[field.type] === undefined)) {
                  const find = dataToCheck.find((d) => d.id === data.id)
                  find.fieldsInError.push(field.type)
                }
              })
            }
          })
        })

        for (let i = 0; i < dataToCheck.length; i++) {
          const dCheck = dataToCheck[i]
          if (dCheck.fieldsInError.length === 0) {
            hasChange = true
            await Axios.default("patch", `api/${flowName}/${dCheck.id}`, {
              ongletIdentification: true,
              ongletParametre: true,
            })
          }
        }
      }
      return hasChange
    }
    Vue.prototype.$hexToHSL = async (hex, hslChoice) => {
      // hslChoice can be hue, saturation or lightness
      return hexToHSL(hex, hslChoice)
    }
  },
}

if (!Object.entries) {
  Object.entries = function (obj) {
    let ownProps = Object.keys(obj)
    let i = ownProps.length
    let resArray = new Array(i)

    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]
    return resArray
  }
  console.log(
    `%c Object.entries Polyfill Done`,
    "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
    Object.entries
  )
}
if (!Object.fromEntries) {
  Object.fromEntries = (l) => l.reduce((a, [k, v]) => ({ ...a, [k]: v }), {})
  console.log(
    `%c Object.fromEntries Polyfill Done`,
    "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
    Object.fromEntries
  )
}

function deleteKeyRecursive(obj, key, removeObject) {
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue
    if (typeof obj[i] === "object") {
      if (i === key && removeObject) {
        delete obj[key]
      } else {
        deleteKeyRecursive(obj[i], key, removeObject)
      }
    } else if (i === key) {
      delete obj[key]
    }
  }
  return obj
}

const getEntries = (o) =>
  Object.entries(o).flatMap(([k, v]) => {
    if (Object(v) === v && !v.name) {
      return getEntries(v)
    } else if (v.name) {
      return [[k, v.name]]
    } else {
      return [[k, v]]
    }
  })

function getValueByKey(key, noDataMessage) {
  if (noDataMessage && (key === null || key === undefined)) {
    return noDataMessage
  }
  key = key && key.name ? key.name : key
  const newObject = Object.fromEntries(getEntries(GenericFieldTable))
  if (newObject[key]) {
    if (_.isPlainObject(newObject[key]) && newObject[key].name) {
      return newObject[key].name
    } else {
      return newObject[key]
    }
  }
  console.log(
    `%c can't get value for key : ${key}.`,
    "background:#CECECE ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: red"
  )
  return key
}

function hexToHSL(H, hslChoice) {
  // Convert hex to RGB first
  let r = 0
  let g = 0
  let b = 0
  if (H.length === 4) {
    r = "0x" + H[1] + H[1]
    g = "0x" + H[2] + H[2]
    b = "0x" + H[3] + H[3]
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2]
    g = "0x" + H[3] + H[4]
    b = "0x" + H[5] + H[6]
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  let cmin = Math.min(r, g, b)
  let cmax = Math.max(r, g, b)
  let delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0

  if (delta === 0) h = 0
  else if (cmax === r) h = ((g - b) / delta) % 6
  else if (cmax === g) h = (b - r) / delta + 2
  else h = (r - g) / delta + 4

  h = Math.round(h * 60)

  if (h < 0) h += 360

  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  if (hslChoice === "hue") {
    return h
  } else if (hslChoice === "saturation") {
    return `${s}%`
  } else if (hslChoice === "lightness") {
    return `${l}%`
  }
  return `${h}, ${s}%, ${l}%`
}
