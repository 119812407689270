<template>
  <section>
    <div class="container">
        <a-row type="flex" :gutter="12">
          <a-col :span="24">
            <GuideHeader />
          </a-col>
        </a-row>
     
    </div>
  </section>
</template>

<script>
import GuideHeader from "./components/GuideHeader.vue"
export default {
  name: "Guide",
  components: {
      GuideHeader,
  },
}
</script>
    