<template>
  <a-modal
    v-model="visible"
    :title="`Validation du parcours`"
    :maskClosable="false"
    class="mcma-validation-modal"
    :width="1000"
  >
    <div class="container-list">
      <mcma-card
        v-if="!checkValidation"
        class="mcma-card-actual-progress-body"
      >
        <h5>
          <p class="principal-message with-bottom">
            Vous ne pouvez pas encore valider votre parcours pour le(s) motif(s) suivant(s):
          </p>
        </h5>
        <br />
        <h6>
          <p v-if="!infosGenCompleted">
            Etape Infos Générales: Vous n'avez pas complété les champs requis de l'étape Informations Générales.
          </p>
          <br />
        </h6>
        <h6>
          <p v-if="membreStats.statPiece.state != 'CERTIFIED' && membreStats.statPiece.state != 'COMPLETED'">
            Etape Dépôt des pièces: Le téléchargement/dépôt n'a pas été effectué et est nécessaire.
          </p>
          <br />
        </h6>
        <h6>
          <p v-if="membreStats.statSite.toComplete !== 0">
            Etape Site: au moins un des onglets 'Localisation' ou 'données techniques' n'est pas complété
          </p>
          <br />
        </h6>
        <h6>
          <p v-if="membreStats.statGroupement.progress !== 1">
            Etape Périmètre facturation: au moins une des données obligatoires du formulaire n'est pas renseigné
          </p>
          <br />
        </h6>
        <h6>
          <p v-if="membreStats.statInterlocuteur.progress !== 1">
            Etape Interlocuteur: au moins un des rôles suivant n'a pas été créé en tant qu'interlocuteur (GESTIONNAIRE /
            FACTURATION / CONTRAT / ADMINSTRATEUR_ESPACE_CLIENT
          </p>
          <br />
        </h6>
        <h6>
          <p v-if="membreStats.statGroupement.link !== 1">
            Etape Périmètre facturation: au moins un des sites n'est pas associé à un périmètre de facturation
          </p>
        </h6>
        <br />
        <div class="align-row-end mtb12">
          <mcma-button name="J'ai compris" color="blue" class="ok mr7" @click="close" />
        </div>
      </mcma-card>

      <mcma-card v-else>
        <h5>
          Vous allez valider votre formulaire, vos données ne pourront plus être modifiées. Une copie de vos données
          vous sera immédiatement mise à disposition par téléchargement quand vous aurez cliqué sur « Confirmer », en
          complément du mail de confirmation qui vous est adressé.
        </h5>
        <div class="inline-buttons">
          <mcma-button name="Annuler" color="orange" class="mt24" @click="close" />
          <mcma-button name="Télécharger le parcours" color="primary" class="mt24" @click="handleDl" />
          <mcma-button name="Valider les informations" color="primary" class="mt24" @click="handleOk" />
        </div>
        <a class="ninja" ref="downloadLink" />
      </mcma-card>
    </div>
  </a-modal>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapState, mapActions } from "vuex"
import McmaCard from "@/components/mcma/common/McmaCard"
import Axios from "@/util/Axios"
import Materialize from "materialize-css"

export default {
  name: "McmaValidateParcoursModal",
  props: {
    visible: Boolean,
    infosGenCompleted: Boolean,
  },
  data() {
    return {
      validatedParcours: false,
    }
  },
  components: {
    McmaButton,
    McmaCard,
  },
  computed: {
    ...mapState(["operation", "membre", "membreStats"]),
    checkValidation() {
      return this.membreStats.statSite.toComplete === 0 &&
        this.membreStats.statGroupement.progress === 1 &&
        this.membreStats.statInterlocuteur.progress === 1 &&
        this.membreStats.statGroupement.link === 1 &&
        this.infosGenCompleted &&
        this.membreStats.statPiece.state === "CERTIFIED"
        ? true
        : false
    },
  },
  created() {
    //deprecated (validatedparcours ==> checkvalidation)
    if (
      this.membreStats.statSite.toComplete === 0 &&
      this.membreStats.statGroupement.progress === 1 &&
      this.membreStats.statInterlocuteur.progress === 1 &&
      this.membreStats.statGroupement.link === 1 &&
      this.infosGenCompleted &&
      membreStats.statPiece.state === "CERTIFIED"
    ) {
      this.validatedParcours = true
    } else {
      this.validatedParcours = false
    }
  },
  methods: {
    ...mapActions(["tutoGoesTo", "requestMembresStats", "requestMembreByMembreId"]),
    handleDl() {
      this.sendMailWithExportMembre()
    },
    handleOk() {
      this.validate()
      this.$emit("save")
      this.close()
    },
    close() {
      this.$emit("close")
    },
    validate() {
      const callSite = Axios("get", "api/membres/" + this.membre.id + "/sites")
        .then((sites) => {
          let listeSites = []
          if (sites.data._embedded.siteGazes != null) {
            listeSites = sites.data._embedded.siteGazes
          } else if (sites.data._embedded.siteElecs != null) {
            listeSites = sites.data._embedded.siteElecs
          }
          listeSites.map((site) => {
            Axios("patch", "api/sites/" + site.id, {
              certified: true,
            })
          })
        })
        .catch((error) => console.log(error))

      const callGroupement = Axios("get", "api/membres/" + this.membre.id + "/groupements")
        .then((groupements) => {
          let listeGroupements = []
          listeGroupements = groupements.data._embedded.groupements
          listeGroupements.map((groupement) => {
            Axios("patch", "api/groupements/" + groupement.id, {
              state: "CERTIFIED",
            })
          })
        })
        .catch((error) => console.log(error))

      const callMembre = Axios("patch", "api/membres/" + this.membre.id, {
        certified: true,
        serviceCertified: true,
        approuved: true,
        approuvedInterlocuteurs: true,
      }).catch((error) => console.log(error))

      Promise.all([callSite, callMembre, callGroupement])
        .then(() => {
          this.requestMembresStats()
          this.requestMembreByMembreId(this.membre.id)
          // this.sendMailWithExportMembre()
          Materialize.toast("Toutes les données parcours sont validées", 4000, "lime white-text")
            this.$notification.success({
              message: "Félicitations ! ",
              description: "Vous venez d'atteindre 100% de la complétude de votre parcours, vous pouvez désormais valider votre parcours en cliquant sur la dernière étape: Valider. Merci de votre engagement ! ",
            })
        })
        .catch((error) => console.log(error))

      this.$router.push({
        name: "steps",
      })
    },

    sendMailWithExportMembre() {
      const date = new Date()
      let fileName =
        "Export_Parcours_" +
        this.operation.name +
        "_" +
        this.membre.nom +
        "_" +
        date.getFullYear() +
        date.getMonth() +
        date.getDay() +
        "_" +
        date.getHours() +
        date.getMinutes() +
        date.getSeconds() +
        ".xlsx"
      fileName = fileName.replace(" ", "_")
      Axios("get", "zdp/membres/parcours?operationId=" + this.operation.id + "&membreId=" + this.membre.id, null, {
        responseType: "blob",
      })
        .then((response) => {
          console.log("> response :  %O", response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement("a")
          fileLink.href = url
          fileLink.setAttribute("download", fileName)
          document.body.appendChild(fileLink)
          fileLink.click()
          if (this.membreStats && this.membreStats.userEmail !== null) {
            Axios("post", "export/membres/users?idOperation=" + this.operation.id, [this.membre])
              .then(() => {})
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: "Erreur lors de l'envoi de email",
                  description: "erreur lors de l'envoi du mail avec export",
                })
              })
          }
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur lors de la récupération du parcours utilisateur",
          })
        })
    },
  },
}
</script>
<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-validation-modal {
  font-family: $font-family !important;
  width: 800px !important;
  .ant-modal-content {
    .ant-modal-footer {
      display: none;
    }
  }
  .container-list {
    display: flex;
    flex-flow: column nowrap;
  }
}
</style>
