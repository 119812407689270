/**
 * Constants/index.js
 ** Store here all constant which can be use, with value
 */
export default {
  MAIL: {
    WELCOME_ADMIN: {
      value: "welcomAdmin",
      text: "de bienvenue aux administrateurs",
    },
    BDD_WELCOME_ADMIN: {
      value: "BDD_WELCOME_ADMIN",
      text: "de bienvenue aux administrateurs",
    },
    BDD_WELCOME: {
      value: "BDD_WELCOME",
      text: "de bienvenue",
    },
    BDD_RAISE: {
      value: "BDD_RAISE",
      text: "de relance",
    },
    BDD_REMERCIEMENT: {
      value: "BDD_REMERCIEMENT",
      text: "de remerciement",
    },
  },
  MAIL_TYPE: {
    BIENVENUE: {
      value: "BIENVENUE",
      text: "Bienvenue",
      classColor: "blue",
    },
    RELANCE: {
      value: "RELANCE",
      text: "Relance",
      classColor: "orange",
    },
    RAPPEL: {
      value: "RAPPEL",
      text: "Rappel",
      classColor: "purple",
    },
  },
  MAIL_STATUS: {
    PROCESSED: {
      value: "PROCESSED",
      text: "Traité",
    },
    QUEUED: {
      value: "QUEUED",
      text: "En attente",
    },
    SENT: {
      value: "SENT",
      text: "Envoyé",
    },
    OPENED: {
      value: "OPENED",
      text: "Ouvert",
    },
    CLICKED: {
      value: "CLICKED",
      text: "Cliqué",
    },
    BOUNCE: {
      value: "BOUNCE",
      text: "Rebondir",
    },
    SPAM: {
      value: "SPAM",
      text: "Spam",
    },
    UNSUB: {
      value: "UNSUB",
      text: "Unsub",
    },
    BLOCKED: {
      value: "BLOCKED",
      text: "Bloqué",
    },
    SOFTBOUNCED: {
      value: "SOFTBOUNCED",
      text: "Rebondissement doux",
    },
    HARDBOUNCED: {
      value: "HARDBOUNCED",
      text: "Rebondissement dur",
    },
    DEFERRED: {
      value: "DEFERRED",
      text: "Reporté",
    },
  },
  USER_ROLE: {
    CLIENT: {
      value: "CLIENT",
      text: "Client",
    },
    ADMIN: {
      value: "ADMIN",
      text: "Admin",
    },
    SUPER_ADMIN: {
      value: "SUPER_ADMIN",
      text: "Super Admin",
    },
    COORDINATEUR: {
      value: "COORDINATEUR",
      text: "Coordinateur",
    },
    COORDINATEUR_AVANCE: {
      value: "COORDINATEUR_AVANCE",
      text: "Coordinateur Avancé",
    },
  },
  STATUS: {
    INITIALISED: {
      value: "INITIALISED",
      text: "Initialisé",
      icon: "initialised",
      color: "#1890ff",
    },
    IN_PROGRESS: {
      value: "IN_PROGRESS",
      text: "En Cours",
      icon: "in_progress",
      color: "#36CBCB",
    },
    EXPORTED: {
      value: "EXPORTED",
      text: "Exporté",
      icon: "exported",
      color: "#975FE5",
    },
    APPROVED: {
      value: "APPROVED",
      text: "Approuvé",
      icon: "approved",
      color: "#52c41a",
    },
    NOT_PRESERVED: {
      value: "NOT_PRESERVED",
      text: "Non concerné",
      icon: "not_preserved",
      color: "#F2637B",
    },
    TO_COMPLETE: {
      value: "TO_COMPLETE",
      text: "À completer",
      icon: "to_complete",
      whiteIcon: "edit_white",
      color: "#F2637B",
      classColor: "error",
    },
    TO_COMPLETE_MASSE: {
      value: "TO_COMPLETE_MASSE",
      text: "À completer en masse",
      icon: "to_complete",
      whiteIcon: "edit_white",
      color: "#F2637B",
      classColor: "error",
    },
    TO_VERIFY: {
      value: "TO_VERIFY",
      text: "Vérifier l'association de vos sites",
      icon: "to_complete",
      whiteIcon: "edit_white",
      color: "#F2637B",
      classColor: "error",
    },
    TO_CERTIFY: {
      value: "TO_CERTIFY",
      text: "À certifier",
      icon: "to_certify",
      whiteIcon: "send_white",
      color: "#FBD437",
      classColor: "warning",
    },
    COMPLETED: {
      value: "COMPLETED",
      text: "Complété",
      icon: "completed",
      whiteIcon: "send_white",
      color: "#FBD437",
      classColor: "warning",
    },
    CERTIFIED: {
      value: "CERTIFIED",
      text: "Certifié",
      icon: "certified",
      whiteIcon: "certified_white",
      color: "#4ECB74",
      classColor: "success",
    },
  },
  FLUIDE: {
    ELEC: {
      value: "ELEC",
      text: "Électricité",
    },
    GAZ: {
      value: "GAZ",
      text: "Gaz",
    },
  },
  SITE_STATUS: {
    NOUVEAU: {
      value: "NOUVEAU",
      text: "Site à ajouter au périmètre",
    },
    EN_COURS_DE_RACCORDEMENT: {
      value: "EN_COURS_DE_RACCORDEMENT",
      text: "En cours de raccordement",
    },
    ACTIF: {
      value: "ACTIF",
      text: "Actuellement en service",
    },
    REJETE: {
      value: "REJETE",
      text: "Rejeté",
    },
    BRANCHEMENT_PROVISOIRE: {
      value: "BRANCHEMENT_PROVISOIRE",
      text: "Branchement provisoire",
    },
    /*INEXISTANT: {
      value: "INEXISTANT",
      text: "Inexistant",
    },*/
    RESILIATION: {
      value: "RESILIATION",
      text: "Résilié ou en cours de résiliation",
    },
  },
  VALIDATE_STATUS: {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
    VALIDATING: "validating",
  },
  FILESTATUS: {
    DOWNLOADED: {
      value: "DOWNLOADED",
      text: "Téléchargée",
      icon: "initialised",
      color: "#1890ff",
    },
    NOT_DOWNLOADED: {
      value: "NOT_DOWNLOADED",
      text: "À télécharger",
      icon: "in_progress",
      color: "#36CBCB",
    },
    UPLOADED: {
      value: "EXPORTED",
      text: "Déposée",
      icon: "exported",
      color: "#975FE5",
    },
    NOT_UPLOADED: {
      value: "NOT_UPLOADED",
      text: "À déposer",
      icon: "approved",
      color: "#52c41a",
    },
    MANDATORY_UPLOAD: {
      value: "OBLIGATOIRE",
      text: "Obligatoire",
    },
    NOT_MANDATORY_UPLOAD: {
      value: "FACULTATIF",
      text: "Facultatif",
    },
  },
}
