<template>
  <section class="input-field">
    <input id="telephone" type="tel" v-model="field" required class="validate" ref="field" :disabled="disabled" />
    <label :class="{ active: field }" for="telephone">
      <font-awesome-icon icon="asterisk" class="red-text" />
      Téléphone
    </label>
  </section>
</template>

<script>
import Mixin from "./Mixin"

export default {
  name: "telephone",
  mixins: [Mixin],
  props: ["disabled"],
  methods: {
    guardian() {
      if (this.field && this.field.length && !/^[0-9]{10}$/.test(this.field)) {
        this.$refs.field.setCustomValidity("Mauvais format de numéro de télephone")
      } else {
        this.$refs.field.setCustomValidity("")
      }
    },
  },
  watch: {
    field() {
      this.guardian()
    },
  },
  mounted() {
    this.guardian()
  },
}
</script>

<style lang="scss" scoped></style>
