<template>
  <section v-if="membre">
    <div class="container" v-if="membre.stateInterlocuteurs !== 'CERTIFIED' && membre.certified !== true && membre.approuved !== true">
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <interlocuteurs-detail-card-header :interlocuteur="interlocuteur">
            <interlocuteurs-edit :interlocuteur="interlocuteur" />
          </interlocuteurs-detail-card-header>
        </a-col>
      </a-row>
    </div>
    <div class="container" v-else>
      <mcma-empty
        :inCard="true"
        image="in_validation"
        :center="true"
        :imageSize="300"
        message="Cet interlocuteur ne peux pas être modifié car il a déjà été envoyé en validation."
      >
        <mcma-button name="Revenir aux Interlocuteurs" class="mt24" @click="goBack()" />
      </mcma-empty>
    </div>
  </section>
</template>

<script>
import InterlocuteursDetailCardHeader from "@/components/mcma/steps/interlocuteurs/components/InterlocuteursDetailCardHeader"
import InterlocuteursEdit from "@/components/mcma/steps/interlocuteurs/components/InterlocuteursEdit"
import GroupementsInterlocuteurForm from "@/components/mcma/steps/groupements/components/GroupementsInterlocuteurForm"
import GroupementsEdit from "@/components/mcma/steps/groupements/components/GroupementsEdit"
import McmaEmpty from "@/components/mcma/common/McmaEmpty"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapState } from "vuex"

export default {
  name: "InterlocuteursDetail",
  components: {
    InterlocuteursDetailCardHeader,
    InterlocuteursEdit,
    GroupementsEdit,
    GroupementsInterlocuteurForm,
    McmaEmpty,
    McmaButton,
  },
  computed: {
    ...mapState(["interlocuteur", "membre"]),
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "interlocuteurs",
      })
    },
  },
  destroyed() {
    this.$store._mutations.resetState[0]("interlocuteur")
  },
}
</script>

<style lang="scss" scoped></style>
