<template>
  <a-form :form="form">
    <mcma-card title="Type de périmètre de facturation" icon="administration" class="mcma-card-in-tab" :inline="true">
      <a-row type="flex" :gutter="12">
        <mcma-field
          :colSpan="12"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="nom"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::groupementName"
          placeholder="Mon périmètre n°5"
          :defaultValue="groupement.nom"
          :required="true"
          @change="updateGroupementEditing(form.getFieldsValue())"
          :extraLabel="msgNomPfByTypeFacturation"
        />
      </a-row>
      <a-row type="flex" :gutter="12">
        <mcma-field
          :colSpan="12"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="typeFacturation"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::typeFacturation"
          :defaultValue="this.$route.params.type || groupement.typeFacturation"
          :extraLabel="msgTypeFacturation"
          :disabled="true"
        />
      </a-row>
    </mcma-card>
    <mcma-card
      title="Entité facturée (adresse de facturation)"
      icon="administration"
      class="mcma-card-in-tab"
      :inline="true"
    >
      <a-row type="flex" :gutter="12">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="budget"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::groupementBudget"
          placeholder="Mon budget n°2"
          :defaultValue="groupement.budget"
          @change="updateGroupementEditing(form.getFieldsValue())"
          :extraLabel="'n\'apparaîtra pas sur les factures'"
        />
      </a-row>
      <a-row type="flex" :gutter="24">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :required="true"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::raisonSocialePayeur"
          fieldName="raisonSocialePayeur"
          :defaultValue="groupement.raisonSocialPayeur"
          :requiredMessage="`Merci de renseigner la raison sociale du destinataire de la facture`"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :required="true"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::siretPayeur"
          fieldName="siretPayeur"
          :defaultValue="groupement.siretPayeur"
          :numberOnly="true"
          rule="isSiret"
          :requiredMessage="`Merci de renseigner le siret payeur du destinataire de la facture`"
        />
      </a-row>

      <a-row type="flex" :gutter="24">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :maxlength="32"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::voieNumber"
          fieldName="numeroVoie"
          :defaultValue="groupement.numeroVoie"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :maxlength="250"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::voieName"
          fieldName="nomVoie"
          :defaultValue="groupement.nomVoie"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :maxlength="32"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::addressCompleted"
          fieldName="complementAdresse"
          :defaultValue="groupement.complementAdresse"
        />

        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::boitePostale"
          fieldName="boitePostale"
          placeholder="Boite postale destinataire facture"
          :defaultValue="groupement.boitePostale"
        />
      </a-row>

      <a-row type="flex" :gutter="24">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :numberOnly="true"
          rule="isZipCode"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::codePostal"
          fieldName="codePostale"
          :defaultValue="groupement.codePostal"
        />

        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :required="true"
          label="Commune"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::town"
          fieldName="commune"
          :defaultValue="groupement.commune"
          :uppercase="true"
          :requiredMessage="`Merci de renseigner la commune du destinataire de la facture`"
        />
      </a-row>
    </mcma-card>
    <mcma-card title="Modalité de paiement" icon="administration" class="mcma-card-in-tab" :inline="true">
      <a-row type="flex" :gutter="24">
        <mcma-field
          :colSpan="8"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.moyenPaiement"
          fieldName="moyenPaiement"
          disableValue="Sélectionnez un mode de paiement"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::moyenPaiement"
          :disabledOptions="disabledOptions"
          @change="moyenPayementChange"
          :required="true"
        />
        <mcma-field
          :colSpan="8"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.rythmeFacturation"
          fieldName="rythmeFacturation"
          disableValue="Sélectionnez un rythme de facturation"
          label="Rythme de facturation"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::rythmeFacturation"
          :required="true"
          extraLabel="ne s'appliquera qu'aux sites à relève semestrielle du présent périmètre de facturation"
        />
        <mcma-field
          :colSpan="8"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.delaiPaiement"
          fieldName="delaiPaiement"
          disableValue="Sélectionnez un délai de paiement"
          label="Délai de paiement"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::delaiPaiement"
          :required="true"
        />
      </a-row>
      <a-row type="flex" :gutter="24">
        <mcma-field
          :colSpan="12"
          v-if="ibanIsRequired(form.getFieldValue('moyenPaiement'))"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.titulaireIban"
          fieldName="titulaireIban"
          label="Titulaire IBAN"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::titulaireIban"
        />
        <mcma-field
          :colSpan="12"
          v-if="ibanIsRequired(form.getFieldValue('moyenPaiement'))"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.iban"
          fieldName="iban"
          label="Code IBAN"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::iban"
          rule="isIban"
          @change="
            resetIbanUpload()
            isIbanTresorerie(form.getFieldValue('moyenPaiement'))
          "
          :required="ibanIsRequired(form.getFieldValue('moyenPaiement'))"
          extraLabel="Si vous renseignez l'iban d'une trésorerie la modalité de paiement sera de type Prélevement trésorerie"
        />

        <div>
          <div
            v-if="ibanIsRequired(form.getFieldValue('moyenPaiement'))"
            class="upload-box"
            :class="{ 'has-file': ibanUploadedFile }"
          >
            <mcma-button
              :colSpan="12"
              name="Téléversement de l'iban"
              :color="this.checkIban || ibanUploadedFile ? 'success' : 'purple'"
              :leftIcon="this.checkIban || ibanUploadedFile ? 'check' : 'upload'"
              :isFaIcon="true"
              @click="$refs.documentUploaded.click()"
              :required="ibanIsRequired(form.getFieldValue('moyenPaiement'))"
            />
            <div class="file-name">
              {{ ibanUploadedFile ? ibanUploadedFile.name : "" }}
            </div>
            <input class="ninja" type="file" ref="documentUploaded" @change="processFile" />
          </div>
          <legend v-if="!(this.checkIban || ibanUploadedFile) && ibanIsRequired(form.getFieldValue('moyenPaiement'))">
            Veuillez téléverser votre iban au format PDF.
          </legend>
        </div>
      </a-row>
    </mcma-card>
    <mcma-card
      v-if="showTresorerie(form.getFieldValue('moyenPaiement'))"
      title="Trésorerie en charge du paiement "
      icon="administration"
      class="mcma-card-in-tab"
      :inline="true"
    >
      <template #describe>
        Merci d’indiquer les coordonnées de la trésorerie exécutant le paiement. A renseigner uniquement en cas de
        paiement sans mandatement préalable.
        <br />
        {{ msgNotaBnEntiteExecutant }}
      </template>
      <a-row type="flex" :gutter="12">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="designationTresorerie"
          :defaultValue="groupement.designationTresorerie"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::designationTresorerie"
          placeholder="Ma raison sociale payeur"
          :required="true"
          :requiredMessage="`Merci de renseigner la raison sociale du gestionnaire de la facture`"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />

        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="adresseFacturationTresorerie"
          :defaultValue="groupement.adresseFacturationTresorerie"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::adresseFacturationTresorerie"
          placeholder="5 rue de la tour effel"
          :required="true"
          :requiredMessage="`Merci de renseigner l'adresse d'envoie de la facture`"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />

        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="codePostaleTresorerie"
          :defaultValue="groupement.codePostaleTresorerie"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::codePostaleTresorerie"
          placeholder="92000"
          :required="true"
          rule="isZipCode"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />

        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="communeTresorerie"
          :defaultValue="groupement.communeTresorerie"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::townTresorerie"
          placeholder="PARIS"
          :required="true"
          :requiredMessage="`Merci de renseigner la commune`"
          :uppercase="true"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="codeCodique"
          :defaultValue="groupement.codeCodique"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::codeCodiqueTreso"
          :maxlength="6"
          :required="true"
          :requiredMessage="`Merci de renseigner le code codique de 6 chiffres`"
        />
      </a-row>
    </mcma-card>
  </a-form>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import Axios from "@/util/Axios"
import { pick } from "@/util/Helpers"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import Constants from "@/util/Constants"

export default {
  name: "GroupementsEdit",
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      msgTypeFacturation: "",
      msgNomPfByTypeFacturation: "",
      msgNotaBnEntiteExecutant: "",
      ibanUploadedFile: null,
      checkIban: this.groupement.ibanPieceUploaded, // obligé pour la duplication du field bouton
      disabledOptions: false,
    }
  },
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaSpinBox,
  },
  props: {
    groupement: Object,
  },
  computed: {
    ...mapState(["membre", "operation"]),
    msgTypeFacturation2() {
      console.log(this.form)
      const a = this.form.getFieldValue("typeFacturation")
      console.log("> a : %O", a)
      if (a === "FACTURE_GROUPEE") {
        return "Tous les sites de ce périmètre de facturation seront facturés sur une facture groupée, le nom de périmètre personalisée ci-dessous apparaitra sur la facture."
      } else {
        return "Chacun de vos sites sera facturé séparément sur une facture individuelle."
      }
    },
    ibanIsRequired() {
      return (moyenPaiement) => {
        return moyenPaiement === "PRELEVEMENT" || moyenPaiement === "PRELEVEMENT_TRESORERIE"
      }
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    moment,
    updateGroupementEditing(groupement) {
      console.log("updateGroupementEditing")
      this.$root.$emit("ROOT_updateGroupementEditing", groupement)
    },
    updateMsgTypeFacturation(factureGrouppe) {
      if (factureGrouppe) {
        this.msgTypeFacturation =
          "Tous les sites de ce périmètre de facturation seront facturés sur une facture groupée, le nom de périmètre personalisée ci-dessous apparaitra sur la facture."
        this.msgNomPfByTypeFacturation = "apparaitra sur votre facture groupée"
      } else {
        this.msgTypeFacturation =
          "Tous les sites rattachés à ce lot de facturation seront facturés de manière individuelle et partageront les mêmes attributs de facturation à renseigner dans cette page."
        this.msgNomPfByTypeFacturation = "n'apparaitra pas sur vos factures individuelles"
      }
    },
    isTypeFacturationFactureGroupee() {
      // console.log('> event : %O', event)
      const a = this.form.getFieldValue("typeFacturation")
      // console.log('> a : %O', a)
      this.updateMsgTypeFacturation(a === "FACTURE_GROUPEE")
      console.log("> a : %O", a === "FACTURE_GROUPEE")
      return a === "FACTURE_GROUPEE"
    },
    onSepaUpload(check, file) {
      this.form.setFieldsValue({
        sepaCheck: check,
        sepaFile: file,
      })
    },
    moyenPayementChange(value) {
      if (
        value === "PRELEVEMENT_BANQUE_DE_FRANCE" ||
        value === "PRELEVEMENT" ||
        value === "VIREMENT_SANS_MANDATEMENT" ||
        value === "PRELEVEMENT_TRESORERIE"
      ) {
        if(value === "PRELEVEMENT" || value === "PRELEVEMENT_TRESORERIE"){
          this.form.setFieldsValue({
            iban: "",
          })
        }
        this.form.setFieldsValue({
          codeIdentificationIndividuelle: this.form.getFieldValue("siret"),
        })
        this.msgNotaBnEntiteExecutant =
          "N.B : Bien indiquer les coordonnées de la trésorerie en charge du paiement lors d'un virement sans mandatement ou d'un prélèvement trésorerie"
      } else {
        this.msgNotaBnEntiteExecutant = ""
      }
    },
    isIbanTresorerie(value) {
      const iban = this.form.getFieldValue("iban")
      if (iban.substring(0, 2) === "FR" && iban.substring(4, 9) === "30001") {
        this.form.setFieldsValue({ moyenPaiement: "PRELEVEMENT_TRESORERIE" })
        
      }else{
        this.form.setFieldsValue({ moyenPaiement: "PRELEVEMENT" })
      }
      this.disabledOptions = true
      if(iban === ""){
        this.disabledOptions = false
      }
    },
    showTresorerie(moyenPaiement) {
      return moyenPaiement === "PRELEVEMENT_TRESORERIE" || moyenPaiement === "VIREMENT_SANS_MANDATEMENT"
    },
    initialize() {
      let editGroupement = pick(this.groupement, [
        "typeFacturation",
        "nom",
        "budget",
        "designationTresorerie",
        "adresseFacturationTresorerie",
        "codePostaleTresorerie",
        "communeTresorerie",
        "codeCodique",
        "moyenPaiement",
        "rythmeFacturation",
        "delaiPaiement",
        "iban",
      ])
      editGroupement = this.$mergeObject(this.form.getFieldsValue(), editGroupement)
      this.isTypeFacturationFactureGroupee()
      this.$nextTick(() => {
        this.form.setFieldsValue(editGroupement)
      })

      if (this.groupement == null) {
        let initEntiteFacture = pick(
          {
            raisonSocialePayeur: this.membre.businessName,
            siretPayeur: this.membre.siretPayeur,
            nomVoie: this.membre.nomVoie,
            numeroVoie: this.membre.numeroVoie,
            complementAdresse: this.membre.complementAdresse,
            codePostale: this.membre.postalCode,
            commune: this.membre.city,
          },
          ["raisonSocialePayeur", "siretPayeur", "nomVoie", "numeroVoie", "complementAdresse", "codePostale", "commune"]
        )

        initEntiteFacture = this.$mergeObject(this.form.getFieldsValue(), initEntiteFacture)
        this.$nextTick(() => {
          this.form.setFieldsValue(initEntiteFacture)
        })
      }
    },
    save() {
      this.$emit("save")
    },
    processFile(event) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const fileIban = event.target.files[0]

      if (fileIban.size > sizeMax) {
        this.$message.warning(`Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`)
      } else if (fileIban.type !== "application/pdf") {
        this.$message.error(`Le fichier n'est pas au format PDF`)
      } else {
        this.ibanUploadedFile = fileIban
        this.checkIban = true
      }
    },
    addIban() {
      if (this.ibanIsRequired && this.checkIban && this.ibanUploadedFile) {
        const formData = new FormData()
        formData.append("file", this.ibanUploadedFile)
        Axios(
          "post",
          "/file/operation/" + this.operation.id + "/membre/" + this.membre.id + "/perimetrefacturation/piece",
          formData,
          null,
          {
            "Content-Type": "multipart/form-data",
          }
        ).catch((error) => {
          console.error(error)
          this.$notification.error({
            message: `Erreur lors du téléversement de l'iban`,
            description: `Une erreur s'est produite lors du téléversement de la pièce, veuillez contacter un administrateur.`,
          })
        })
      }
    },
    resetIbanUpload() {
      if (this.checkIban !== null || this.ibanUploadedFile !== null) {
        this.checkIban = null
        this.ibanUploadedFile = null
        const input = this.$refs.documentUploaded
        if (input && input.type) {
          input.type = "text"
          input.type = "file"
        }
      }
    },
    submit(interlocuteurId, interlocuteurFieldsValue) {
      console.log(
        `%c prepare saving Groupement`,
        "background:purple ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
      )
      this.form.validateFields((errors, fieldsValue) => {
        console.log(
          `%c fieldsValue`,
          "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          fieldsValue
        )
        this.wasSubmit = true
        if (errors) {
          this.$notification.error({
            message: "Vous avez des erreurs",
            description: `Veuillez remplir tous les champs comprenant des erreurs`,
          })
          this.$scrollToTop()
        } else {
          if (!interlocuteurId) {
            this.$notification.error({
              message: "Aucun interlocuteur n'existe",
              description: `Vous devez préalablement selectionner ou créer un interlocuteur payeur pour ce périmètre de facturation.`,
            })
          } else if(!this.groupement.ibanPieceUploaded && (!this.checkIban || !this.ibanUploadedFile) && this.ibanIsRequired(this.form.getFieldValue('moyenPaiement'))) {
            this.$notification.warning({
              message: "Iban obligatoire",
              description: `Vous devez impérativement importer un fichier IBAN en lien avec votre moyen de paiement.`,
            })
          } else {
            console.log("have interlocuteur selected")
            console.log("fieldsValue :%O", fieldsValue)
            console.log("interlocuteurFieldsValue : %O", interlocuteurFieldsValue)
            const groupement = {
              membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
              // Type de Facturation
              nom: fieldsValue.nom,
              typeFacturation: fieldsValue.typeFacturation,
              // Entité
              budget: fieldsValue.budget,
              raisonSocialPayeur: fieldsValue.raisonSocialePayeur,
              numeroVoie: fieldsValue.numeroVoie,
              nomVoie: fieldsValue.nomVoie,
              complementAdresse: fieldsValue.complementAdresse,
              codePostal: fieldsValue.codePostale,
              boitePostale: fieldsValue.boitePostale,
              commune: fieldsValue.commune,
              siretPayeur: fieldsValue.siretPayeur,
              // Moyen de paiment
              moyenPaiement: fieldsValue.moyenPaiement,
              delaiPaiement: fieldsValue.delaiPaiement,
              rythmeFacturation: fieldsValue.rythmeFacturation,
              titulaireIban: fieldsValue.titulaireIban,
              iban: fieldsValue.iban,
              ibanPieceUploaded: this.checkIban,
              // Tresorerie
              designationTresorerie: fieldsValue.designationTresorerie,
              adresseFacturationTresorerie: fieldsValue.adresseFacturationTresorerie,
              codePostaleTresorerie: fieldsValue.codePostaleTresorerie,
              communeTresorerie: fieldsValue.communeTresorerie,
              codeCodique: fieldsValue.codeCodique,
              // Interlocuteur
              interlocuteurPayeurId: interlocuteurId,
              interlocuteurPayeurNom: interlocuteurFieldsValue.interlocuteurPayeurNom,
              interlocuteurPayeurPrenom: interlocuteurFieldsValue.interlocuteurPayeurPrenom,
              telephonePayeur: interlocuteurFieldsValue.telephonePayeur,
              mobilePayeur: interlocuteurFieldsValue.mobilePayeur,
              emailPayeur: interlocuteurFieldsValue.emailPayeur,
              // Chorus
              siret: interlocuteurFieldsValue.siret,
              codeIdentificationIndividuelle: interlocuteurFieldsValue.codeIdentificationIndividuelle,
              codeSiretExecutant: interlocuteurFieldsValue.codeSiretExecutant,
              engagementJuridique: interlocuteurFieldsValue.engagementJuridique,
              codeServiceExecutant: interlocuteurFieldsValue.codeServiceExecutant,
              numConventionTripartite: interlocuteurFieldsValue.numConventionTripartite,
              chorusConfirmation: interlocuteurFieldsValue.chorusConfirmation,
              chorusCodeServiceExecutant: interlocuteurFieldsValue.chorusCodeServiceExecutant,
              chorusCodeEngagementJuridique: interlocuteurFieldsValue.chorusCodeEngagementJuridique,
              chorusNomServiceExecutant: interlocuteurFieldsValue.chorusNomServiceExecutant,
              chorusNomStructureExecutant: interlocuteurFieldsValue.chorusNomStructureExecutant,
              refContrat: interlocuteurFieldsValue.refContrat,
              refMarche: interlocuteurFieldsValue.refMarche,
              dispoEdi: interlocuteurFieldsValue.dispoEdi,
              validChorus: interlocuteurFieldsValue.validChorus,
              // Option
              papier: interlocuteurFieldsValue.papier,
              optionExcel: interlocuteurFieldsValue.optionExcel,
              dematXml: interlocuteurFieldsValue.dematXml,
              factureElectr: interlocuteurFieldsValue.factureElectr,
              emailEnvoi: interlocuteurFieldsValue.emailEnvoi,
              //data Facture
              dataFacture: interlocuteurFieldsValue.dataFacture,
              // Etat
              state: Constants.STATUS.COMPLETED.value,
              operationId: this.operation.id,
            }
            console.log(">> Modif/Add groupement : %O", groupement)
            console.log(">> this.groupement.id : %O", this.groupement.id)
            if (!groupement.nom) {
              this.$notification.error({
                message: "Erreur lors de l'enregistement du Groupement",
                description: "Désolé nous n'avons pas pu réccurpérer les valeurs saisies dans le formulaire...",
              })
            } else {
              this.$emit("loading", true)
              let request = null
              if (this.$route.params.action !== "duplique" && this.groupement.id) {
                request = Axios("patch", "api/groupements/" + this.groupement.id, groupement)
                console.log(">> Patch groupement : %O", groupement)
              } else {
                request = Axios("post", "api/groupements", groupement)
                console.log(">> Post groupement : %O", groupement)
              }
              request
                .then(() => {
                  if (this.$route.params.action !== "duplique") {
                    this.$notification.success({
                      message: "Périmètre sauvegardé",
                      description: "Le périmètre de facturation a bien été sauvegardé.",
                    })
                  } else {
                    this.$notification.success({
                      message: "Périmètre dupliqué",
                      description: "Le périmètre de facturation a bien été dupliqué.",
                    })
                  }
                  if(this.ibanIsRequired(this.form.getFieldValue('moyenPaiement'))){
                    alert("Vous avez renseigné l'IBAN pour le prélèvement automatique. Vous recevrez prochainement un mandat SEPA, merci de veiller à le retourner signé aux coordonnées qui vous seront indiquées. Ce mandat signé est indispensable pour finaliser la mise en place du prélèvement automatique.")
                  }
                  const typeFacturation = this.form.getFieldValue("typeFacturation")
                  console.log(typeFacturation)
                  if (typeFacturation === "FACTURE_GROUPEE") {
                    alert(
                      "Vous avez défini une facture groupée. Vous allez maintenant pouvoir choisir les sites rattachés à cette facture groupée à l’aide du bouton « Attacher des sites »"
                    )
                  } else {
                    alert(
                      "Vous avez défini un lot de factures individuelles. Vous allez maintenant pouvoir choisir les sites qui constituent ce lot à l’aide du bouton « Attacher des sites »"
                    )
                  }
                  if (this.sepaCheck && this.sepaFile) {
                    const formData = new FormData()
                    formData.append("file", this.sepaFile)
                    formData.append("membreId", this.membre.id)
                    formData.append("groupement", groupement.nom)
                    Axios("post", "/file/sepa/membre", formData, null, {
                      "Content-Type": "multipart/form-data",
                    })
                      .then((reponse) => {
                        this.$notification.success({
                          message: "SEPA signé !",
                          description: "Le SEPA à bien été signé.",
                        })
                      })
                      .catch((error) => {
                        console.error(error)
                        this.$notification.error({
                          message: "Erreur SEPA",
                          description: "Erreur lors de la signature du SEPA.",
                        })
                      })
                  } else if (!this.sepaCheck && this.groupement.sepa) {
                    Axios("delete", "file/sepa?membreId=" + this.membre.id + "&groupement=" + this.groupement.nom)
                      .then((reponse) => {
                        this.$notification.success({
                          message: "SEPA supprimé !",
                          description: "Le SEPA à bien été supprimé.",
                        })
                      })
                      .catch((error) => {
                        console.error(error)
                        this.$notification.error({
                          message: "Erreur SEPA !",
                          description: "Erreur lors de la suppression du SEPA.",
                        })
                      })
                  }
                  this.addIban()
                  this.$emit("loading", false)
                  this.$router.push({
                    name: "groupements",
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.$notification.error({
                    message: "Erreur serveur",
                    description: "Une erreur server est survenue, veuillez contacter un administrateur.",
                  })
                })
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.row-inline {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.upload-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 45vw;
  margin: 20px 0;
  padding-right: 10px;
  border-radius: 4px;
  background: rgba($grey, 0.1);
  border: 1px solid rgba($grey, 0.3);
  transition: all 0.3s ease;
  .file-name {
    color: $grey;
  }
  &.has-file {
    background: hsla(var(--success-color-h), var(--success-color-s), var(--success-color-l), 0.1);
    .file-name {
      color: var(--text-color, $text-color);
    }
  }
}
</style>
